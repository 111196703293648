import Heading from "components/Typography/Header";
import Paragraph from "components/Typography/Paragraph";
import Button from "components/Button";
import { ReactComponent as Tick } from "images/icons/tick.svg";
import Financial1 from "images/icons/Financial1.png";
import Financial2 from "images/icons/Financial2.png";
import Financial3 from "images/icons/Financial3.png";
import { Link } from "react-router-dom";

const Financial = () => {
    return (
        <section className="grid grid-cols-12 mt-16 md:mt-[240px] gap-[30px]">
            <div className="md:col-start-1 md:col-end-7 col-start-1 col-end-13">
                <div className="space-y-[30px]">
                    <Heading level={5} className="text-secondary">SkyPay Ayrıcalıklarından Sende Yararlan</Heading>
                    <Heading level={2} className="text-gray-600">SkyPay Her Zaman Seninle!</Heading>
                    <Paragraph className="text-black">SkyPay, kullanıcılarının memnuniyetine önem vererek; daha hızlı, güvenli ve kolay bir şekilde işlemlerini gerçekleştirebilmeleri için profesyonel olarak geliştirilmiş bir platformdur.</Paragraph>
                </div>
               <Link to="/register"><Button className="mt-10">Hemen Üye Ol</Button></Link>
            </div>
            <div className="md:col-start-7 md:col-end-13 col-start-1 col-end-13 grid grid-cols-12 gap-[30px]">
                <div className="md:col-start-1 md:col-end-7 col-start-1 col-end-13 partner-shadow rounded-[20px] hover:border hover:border-gray-300 transition-all px-5 py-[30px]">
                    <div className="w-20 h-20 bg-primary rounded-full flex items-center justify-center">
                        <img className="rounded-full" src={Financial1} width={70} height={70} />
                    </div>
                    <Heading level={5} className="text-gray-600 mt-10 mb-5">Kullanıcı Dostu</Heading>
                    <div className="flex space-x-[10px]">
                        <Tick />
                        <Paragraph className="flex-1">Kullanım Kolaylığı ve Hızlı Erişim</Paragraph>
                    </div>
                </div>
                <div className="md:col-start-7 md:col-end-13 col-start-1 col-end-13 partner-shadow rounded-[20px] hover:border hover:border-gray-300 transition-all px-5 py-[30px]">
                    <div className="w-20 h-20 bg-primary rounded-full flex items-center justify-center">
                        <img className="rounded-full" src={Financial2} width={70} height={70} />
                    </div>
                    <Heading level={5} className="text-gray-600 mt-10 mb-5">Gelişmiş Altyapı</Heading>
                    <div className="flex space-x-[10px]">
                        <Tick />
                        <Paragraph className="flex-1">İşlemler Sorunsuz, Kesintisiz ve Hızlı</Paragraph>
                    </div>
                </div>
                <div className="md:col-start-1 md:col-end-7 col-start-1 col-end-13 partner-shadow rounded-[20px] hover:border hover:border-gray-300 transition-all px-5 py-[30px]">
                    <div className="w-20 h-20 bg-primary rounded-full flex items-center justify-center">
                        <img className="rounded-full" src={Financial3 } width={70} height={70} />
                    </div>
                    <Heading level={5} className="text-gray-600 mt-10 mb-5">Güvenli Kullanım</Heading>
                    <div className="flex space-x-[10px]">
                        <Tick />
                        <Paragraph className="flex-1">%100 Kullanıcı Güvenliği</Paragraph>
                    </div>
                </div>
                <div className="md:col-start-7 md:col-end-13 col-start-1 col-end-13 partner-shadow rounded-[20px] hover:border hover:border-gray-300 transition-all px-5 py-[30px]">
                    <div className="w-20 h-20 bg-primary rounded-full flex items-center justify-center">
                        <img className="rounded-full" src="/icons/Financial4.png" width={70} height={70} />
                    </div>
                    <Heading level={5} className="text-gray-600 mt-10 mb-5">7/24 Destek</Heading>
                    <div className="flex space-x-[10px]">
                        <Tick />
                        <Paragraph className="flex-1">Profesyonel Müşteri Destek Ekibi</Paragraph>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Financial;