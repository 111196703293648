import instance from "api/instance";

const login = async (data) => {
	const response = await instance.post("/customer/auth/login", data);
	if (response.status === 201 || response.status === 200) {
		if(response.status === 200){
			localStorage.setItem('token', response.data.access_token);
			return response.data;
		}
		return response.data;
	}

	return Promise.reject(response.data);
};

const register = async (data) => {
	const response = await instance.post("/admin/auth/login", data);
	if (response.status === 200) {
		localStorage.setItem('token', response.data.access_token);
		return response.data;
	}

	return Promise.reject(response.data);
};

const logout = () => {
	localStorage.removeItem('token');
};

const customerService = {
	login,
    register,
    logout
};

export default customerService;