import instance from "api/instance";

const create = async (data) => {
  const response = await instance.post("/admin/create-otp", data);
  if (response.status === 201) {
    return response.data;
  }

  return Promise.reject(response.data);
};





const createOtpService = {
    create,
};

export default createOtpService;
