import { useState } from "react";
import { motion } from 'framer-motion';
import { Link } from "react-router-dom";
import Logo from "components/Logo";
import { AiOutlineMenu, AiOutlineClose } from "react-icons/ai";


const Navbar = () => {

  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
    if (!isOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }
  }

  const menuVariants = {
    open: { x: 0, transition: { type: 'spring', stiffness: 100 } },
    closed: { x: '100%', transition: { type: 'spring', stiffness: 100 } },
  };

  return (
    <>
      <header className="hidden md:grid green-container fluid border-b border-[#D8DCE7]">
        <div className="flex justify-between items-center">
          <div className="flex items-center gap-8">
            <Logo to="/" />
            <div className="space-x-4 text-sm font-semibold">
              <Link to="/login/customer" className="text-gray-300">Bireysel Giriş</Link>
              <Link to="/login/reseller" className="text-secondary">Kurumsal Giriş</Link>

            </div>
          </div>
          <nav className="space-x-[30px] text-white-100 font-semibold text-lg">
            <Link className="text-primary" to="/">Anasayfa</Link>
            <Link to="/banka">Banka Hesaplarımız</Link>
            <Link to="/iletisim">İletişim</Link>
            <Link to="/bayilik-basvurusu">Bayilik Başvurusu</Link>
            <a href="#sss">SSS</a>
          </nav>
        </div>
      </header>
      <header className="grid md:hidden green-container fluid border-b border-[#D8DCE7]">
        <div className="flex justify-between items-center">
          <div className="flex items-center gap-8">
            <Logo isMobile to="/" />
            <div className="space-x-4 text-sm font-semibold">
              <Link to="/login/customer" className="text-gray-300">Bireysel Giriş</Link>
              <Link to="/login/reseller" className="text-secondary">Kurumsal Giriş</Link>
            </div>
          </div>
          <div className="flex items-center gap-4">
            <button onClick={toggleMenu} className="text-2xl text-gray-300">
              {isOpen ? <AiOutlineClose /> : <AiOutlineMenu />}
            </button>
          </div>
          <motion.nav
            className="fixed flex flex-col space-y-4 top-28 right-0 w-4/5 h-full bg-black shadow-md z-10 p-4"
            initial="closed"
            animate={isOpen ? 'open' : 'closed'}
            variants={menuVariants}
          >
            <Link to="/" className="text-xl font-semibold text-primary">Anasayfa</Link>
            <Link to="/banka" className="text-xl font-semibold text-gray-700">Banka Hesaplarımız</Link>
            <Link to="/iletisim" className="text-xl font-semibold text-gray-700">İletişim</Link>
            <Link to="/bayilik-basvurusu" className="text-xl font-semibold text-gray-700">Bayilik Başvurusu</Link>
            <a href="#sss" className="text-xl font-semibold text-gray-700">SSS</a>
          </motion.nav>
        </div>
      </header>

    </>
  )
}

export default Navbar;
