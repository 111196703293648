import React, { useEffect, useState } from "react";
import { RxSketchLogo } from "react-icons/rx";
import { AiOutlineDollarCircle } from "react-icons/ai";
import { GiDiamondTrophy } from "react-icons/gi";

import { getTotalSales } from "redux/subreseller/total-sales/totalSalesSlice";
import { profile } from "redux/subreseller/profile/profileSlice";
import { useSelector, useDispatch } from "react-redux";

const TopPanel = () => {
  const dispatch = useDispatch();

  const [startDateTotalSales, setStartDateTotalSales] = useState(() => {
    const date = new Date();
    date.setUTCDate(1);
    return date.toISOString().slice(0, 10);
  });

  const [endDateTotalSales, setEndDateTotalSales] = useState(() => {
    const date = new Date();
    date.setUTCMonth(date.getUTCMonth() + 1);
    date.setUTCDate(1);
    return date.toISOString().slice(0, 10);
  });

  useEffect(() => {
    dispatch(profile());
  }, [dispatch]);

  useEffect(() => {
    const utcMinusThreeHours = -3 * 60 * 60 * 1000;
    const startDateTime = (date) => {
      return new Date(`${date}T00:00:00.000Z`).getTime() + utcMinusThreeHours;
    };
    const endDateTime = (date) => {
      return new Date(`${date}T23:59:00.000Z`).getTime() + utcMinusThreeHours;
    };

    dispatch(
      getTotalSales({
        start: new Date(startDateTime(startDateTotalSales)).toISOString(),
        end: new Date(endDateTime(endDateTotalSales)).toISOString(),
      })
    );
  }, [startDateTotalSales, endDateTotalSales, dispatch]);

  const { data } = useSelector((state) => state.subresellerTotalSales);
  const { profile: profileData } = useSelector(
    (state) => state.subresellerProfile
  );

  return (
    <div>
      <div className="flex gap-4 px-4 items-center">
        <input
          type="date"
          value={startDateTotalSales}
          onChange={(e) => setStartDateTotalSales(e.target.value)}
          className="rounded-lg px-3 py-2 mx-2"
        />
        <span className="text-white-100 text-xl">-</span>
        <input
          type="date"
          value={endDateTotalSales}
          onChange={(e) => setEndDateTotalSales(e.target.value)}
          className="rounded-lg px-3 py-2 mx-2"
        />
      </div>
      <div className="grid lg:grid-cols-3 gap-4 p-4">
        <div className="lg:col-span-1 col-span-1 bg-gray-900 flex justify-between w-full p-4 rounded-lg">
          <div className="flex items-center gap-4 rounded-lg p-6">
            <span className="rounded-full bg-primary p-3 text-white-100">
              <GiDiamondTrophy size={30} />
            </span>

            <div>
              <p className="text-2xl font-medium text-white-100">
                {data?.total_diamond_sales || 0}
              </p>

              <p className="text-base text-white-100">Toplam Elmas Satışı</p>
            </div>
          </div>
        </div>
        <div className="lg:col-span-1 col-span-1 bg-gray-900 flex justify-between w-full p-4 rounded-lg">
          <div className="flex items-center gap-4 rounded-lg p-6">
            <span className="rounded-full bg-primary p-3 text-white-100">
              <AiOutlineDollarCircle size={30} />
            </span>

            <div>
              <p className="text-2xl font-medium text-white-100">
                {profileData.pbalance_try || 0}₺
              </p>
              <p className="text-base text-white-100">Kasa</p>
            </div>
          </div>
        </div>
        <div className="lg:col-span-1 col-span-1 bg-gray-900 flex justify-between w-full p-4 rounded-lg">
          <div className="flex items-center gap-4 rounded-lg p-6">
            <span className="rounded-full bg-primary p-3 text-white-100">
              <RxSketchLogo size={30} />
            </span>

            <div>
              <p className="text-2xl font-medium text-white-100">
                {profileData?.calculator_diamond_price || 0}
              </p>

              <p className="text-base text-white-100">
                1₺ karşılığında sattığınız elmas miktarı
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TopPanel;
