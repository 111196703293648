import React from "react";
import { useSelector, useDispatch } from "react-redux";
import OrderIndicator from "components/OrderIndicator";

const Settings = () => {
  const { profile } =
    useSelector((state) => state.customerProfile);

  return (
    <div className="shadow-md rounded px-8 pt-6 pb-8 mb-4">
      <h2 className="text-2xl font-semibold text-white-100 mb-4 flex items-center gap-4">
        Profilim{" "}
      </h2>
      <div className="grid md:grid-cols-2 grid-cols-1 gap-4">
        <div className="bg-gray-900 p-4 space-y-4 rounded shadow">
          <h2 className="text-xl font-semibold text-white-100">Kişisel Bilgilerim</h2>
          <p className="text-white-100">
            <span className="font-semibold">ID:</span>
            <span className="ml-2">{profile.id}</span>
          </p>
          <p className="text-white-100">
            <span className="font-semibold">Ad Soyad:</span>
            <span className="ml-2">{profile.first_name} {profile.last_name}</span>
          </p>
          <p className="text-white-100">
            <span className="font-semibold">Telefon Numarası:</span>
            <span className="ml-2">{profile.phone}</span>
          </p>
          <p className="text-white-100">
            <span className="font-semibold">Mail:</span>
            <span className="ml-2">{profile.mail || "Bilgi Yok"}</span>
          </p>
        </div>
        <div className="bg-gray-900 p-4 space-y-4 rounded shadow">
          <h2 className="text-xl font-semibold text-white-100">Doğrulama</h2>
          <p className="text-white-100">
            <span className="font-semibold">Telefon Onayı:</span>
            <span className="ml-2">{profile.phone_verified === true ? <OrderIndicator color={"green"}>Onaylı</OrderIndicator> : <OrderIndicator color={"red"}>Henüz Onaylanmamış</OrderIndicator>}</span>
          </p>
          <p className="text-white-100">
            <span className="font-semibold">E-Mail Onayı:</span>
            <span className="ml-2">{profile.email_verified === true ? <OrderIndicator color={"green"}>Onaylı</OrderIndicator> : <OrderIndicator color={"red"}>Henüz Onaylanmamış</OrderIndicator>}</span>
          </p>
        </div>
      </div>

    </div>
  );
};

export default Settings;
