import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { detailCustomers} from "redux/admin/users/usersSlice";
import { orderDetail as getOrder, resetOrders } from "redux/admin/orders/ordersSlice";
import UserDetail from "components/Panel/Admin/UserDetail";


const UserDetailContainer = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const { customer:data } = useSelector(
    (state) => state.adminUsers
  );
  const { orderDetail } = useSelector(
    (state) => state.adminOrders
  );


  useEffect(() => {
    dispatch(detailCustomers({ search: id }));
    dispatch(getOrder({ customer_id: id }));
  }, [dispatch, id]);

  return <UserDetail data={data} orderData={orderDetail} />;
};

export default UserDetailContainer;
