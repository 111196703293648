import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { RxSketchLogo } from "react-icons/rx";
import OrderIndicator from "components/OrderIndicator";
import { useDispatch, useSelector } from "react-redux";
import { orders } from "redux/customer/orders/ordersSlice";

const RecentOrders = () => {
  const dispatch = useDispatch();
  const { data } = useSelector((state) => state.customerOrders.orders);

  useEffect(() => {
    dispatch(orders());
  }, [dispatch]);

  return (
    <div className="w-full col-span-1 relative lg:h-[70vh]  h-[50vh] m-auto p-4  rounded-lg bg-gray-900 overflow-y-auto">
      <h1 className="text-white-100 font-medium flex flex-col md:flex-row items-center gap-4 text-base md:text-2xl">
        Geçmiş Satın Alımlar
        <Link to="/customer/orders"><span className="text-base bg-secondary text-black hover:bg-primary hover:text-white-100 hover:transition-all px-2 rounded-md">Tümünü Gör</span></Link>
      </h1>
      <ul>
        {data?.map((data, index) => (
          <li
            key={index}
          >
           <Link to={`/customer/orders/detail/${data.id}`}  className="bg-black rounded-lg my-3 p-2 flex items-center cursor-pointer">
           <div className="bg-primary rounded-lg p-3">
              <RxSketchLogo className="text-white-100" />
            </div>
            <div className="pl-4">
              <p className="text-white-100 font-bold">
                {data.amount_diamonds} Elmas
              </p>
              <p className="text-gray-300 text-sm">
                Kullanıcı ID : {data.bigo_username}
              </p>
            </div>
            <p className="lg:flex md:hidden absolute right-6 text-sm text-white-100 font-semibold">
              {data.status === "completed" ? (
                <OrderIndicator color="green">Aktarım Yapıldı.</OrderIndicator>
              ) : data.status === "pending_transfer" ? (
                <OrderIndicator color="yellow">
                  Aktarım Bekliyor.
                </OrderIndicator>
              ) : data.status === "failed" ? (
                <OrderIndicator color="red">Aktarım Reddedildi.</OrderIndicator>
              ) : (
                <OrderIndicator color="red">No Data</OrderIndicator>
              )}
            </p>
           </Link>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default RecentOrders;
