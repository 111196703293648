import instance from "api/instance";

const login = async (data) => {
	const response = await instance.post("/admin/auth/login", data);
	if (response.status === 200) {
		localStorage.setItem('token', response.data.access_token);
		localStorage.setItem('refresh_token', response.data.refresh_token);
		return response.data;
	}

	return Promise.reject(response.data);
};

const refreshToken = async (data) => {
	const response = await instance.post("/admin/auth/refresh-token", data);
	if (response.status === 200) {
		localStorage.setItem('token', response.data.access_token);
		localStorage.setItem('refresh_token', response.data.refresh_token);
		return response.data;
	}

	return Promise.reject(response.data);
};


const logout = () => {
	localStorage.removeItem('token');
	localStorage.removeItem('refresh_token');
};

const authService = {
	login,
    logout,
	refreshToken
};

export default authService;