import React, { useState, useEffect } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import Heading from "components/Typography/Header";
import Button from "components/Button";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { createPayments, reset } from "redux/admin/payments/paymentsSlice";
import { getBankAccounts } from "redux/admin/bank-accounts/bankAccountsSlice";
import { useUUID } from "hooks/useUUID";

const CreatePaymentContainer = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [trigger, setTrigger] = useState(0);
  const uuid = useUUID(trigger);
  const now = new Date();
  const {
    isSuccessCreatePayments,
    isLoadingCreatePayments,
    isErrorCreatePayments,
    message,
  } = useSelector((state) => state.adminPayments);
  const { data: bankAccounts } = useSelector(
    (state) => state.adminBankAccounts
  );

  const createPaymentForm = Yup.object().shape({
    first_name: Yup.string().required("Ad gereklidir."),
    last_name: Yup.string().required("Soyad gereklidir."),
    amount_try: Yup.number().required("Tutar gereklidir."),
    bank_account_id: Yup.string().required("Banka hesabı gereklidir."),
    reference1: Yup.string().required("Referans 1 gereklidir."),
    reference2: Yup.string(),
    reference3: Yup.string(),
    reference4: Yup.string(),
    tckn: Yup.string().required("TCKN gereklidir."),
  });

  useEffect(() => {
    dispatch(getBankAccounts());
  }, []);

  useEffect(() => {
    if (isErrorCreatePayments) {
      if (message.first_name) {
        toast.error(message.first_name);
      } else {
        toast.error(message);
      }
    }
    if (isSuccessCreatePayments) {
      toast.success("Ödeme başarıyla oluşturuldu.");
      setTimeout(() => {
        navigate("/admin/payments");
      }, 1500);
    }
    return () => {
      dispatch(reset());
    };
  }, [isErrorCreatePayments, isSuccessCreatePayments, message, dispatch]);

  const handleSubmit = async (values) => {
    await dispatch(
      createPayments({
        full_name: values.first_name + values.last_name,
        amount_try: values.amount_try,
        bank_account_id: values.bank_account_id,
        received_at: now.toISOString(),
        reference1: values.reference1,
        reference2: values.reference2,
        reference3: values.reference3,
        reference4: values.reference4,
        tckn: values.tckn,
        nonce: uuid,
      })
    );

    setTrigger((prev) => prev + 1);
  };

  return (
    <div className="p-4">
      <Heading className="text-white-100" level={5}>
        Ödeme Oluştur
      </Heading>
      <div className="p-4 bg-gray-900 my-4 rounded-xl min-h-screen">
        <div className="p-4 bg-black md:h-screen">
          <Formik
            initialValues={{
              first_name: "",
              last_name: "",
              amount_try: "",
              bank_account_id: "",
              reference1: "",
              reference2: "",
              reference3: "",
              reference4: "",
              tckn: "",
            }}
            validationSchema={createPaymentForm}
            onSubmit={handleSubmit}
          >
            {({ values, errors, touched }) => (
              <Form className="space-y-4">
                <div className="flex gap-4">
                  <div className="w-full">
                    <span className="text-white-100 font-medium">Ad</span>
                    <Field
                      name="first_name"
                      type="text"
                      className="w-full font-medium bg-white-100 rounded-lg appearance-none focus:outline-none  p-2 mt-2"
                    />
                    <ErrorMessage
                      name="first_name"
                      component="div"
                      className="text-red-500"
                    />
                  </div>
                  <div className="w-full">
                    <span className="text-white-100 font-medium">Soyad</span>
                    <Field
                      name="last_name"
                      type="text"
                      className="w-full font-medium bg-white-100 rounded-lg appearance-none focus:outline-none  p-2 mt-2"
                    />
                    <ErrorMessage
                      name="last_name"
                      component="div"
                      className="text-red-500"
                    />
                  </div>
                </div>
                <div className="">
                  <span className="text-white-100 font-medium">
                    Ödeme Tutarı (₺)
                  </span>
                  <Field
                    name="amount_try"
                    type="number"
                    className="w-full font-medium bg-white-100 rounded-lg appearance-none focus:outline-none  p-2 mt-2"
                  />
                  <ErrorMessage
                    name="amount_try"
                    component="div"
                    className="text-red-500"
                  />
                </div>
                <div className="">
                  <span className="text-white-100 font-medium">Referans1 (EFT SORGU NO (GARANTİ HAVALE İÇİN . DAN SONRAKİ KISIM; VAKIF HAVALE İÇİN DEKONT İŞLEM NO))</span>
                  <Field
                    name="reference1"
                    type="text"
                    className="w-full font-medium bg-white-100 rounded-lg appearance-none focus:outline-none  p-2 mt-2"
                  />
                  <ErrorMessage
                    name="reference1"
                    component="div"
                    className="text-red-500"
                  />
                </div>
                <div className="">
                  <span className="text-white-100 font-medium">Referans2</span>
                  <Field
                    name="reference2"
                    type="text"
                    className="w-full font-medium bg-white-100 rounded-lg appearance-none focus:outline-none  p-2 mt-2"
                  />
                  <ErrorMessage
                    name="reference2"
                    component="div"
                    className="text-red-500"
                  />
                </div>
                <div className="">
                  <span className="text-white-100 font-medium">Referans3</span>
                  <Field
                    name="reference3"
                    type="text"
                    className="w-full font-medium bg-white-100 rounded-lg appearance-none focus:outline-none  p-2 mt-2"
                  />
                  <ErrorMessage
                    name="reference3"
                    component="div"
                    className="text-red-500"
                  />
                </div>
                <div className="">
                  <span className="text-white-100 font-medium">Referans4</span>
                  <Field
                    name="reference4"
                    type="text"
                    className="w-full font-medium bg-white-100 rounded-lg appearance-none focus:outline-none  p-2 mt-2"
                  />
                  <ErrorMessage
                    name="reference4"
                    component="div"
                    className="text-red-500"
                  />
                </div>
                <div className="">
                  <span className="text-white-100 font-medium">TCKN</span>
                  <Field
                    name="tckn"
                    type="number"
                    className="w-full font-medium bg-white-100 rounded-lg appearance-none focus:outline-none  p-2 mt-2"
                  />
                  <ErrorMessage
                    name="tckn"
                    component="div"
                    className="text-red-500"
                  />
                </div>
                <div className="">
                  <span className="text-white-100 font-medium">
                    Ödemenin Yapılacağı Banka
                  </span>
                  <Field
                    name="bank_account_id"
                    as="select"
                    className="w-full font-medium bg-white-100 rounded-lg appearance-none focus:outline-none  p-2 mt-2"
                  >
                    <option value="">Seçiniz</option>
                    {bankAccounts &&
                      bankAccounts.map((bankAccount) => (
                        <option key={bankAccount.id} value={bankAccount.id}>
                          {bankAccount.name}
                        </option>
                      ))}
                  </Field>
                  <ErrorMessage
                    name="bank_account_id"
                    component="div"
                    className="text-red-500"
                  />
                </div>
                <Button
                  type="submit"
                  size="small"
                  loading={isLoadingCreatePayments}
                  className="bg-primary"
                >
                  Ödeme Yap
                </Button>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
};

export default CreatePaymentContainer;
