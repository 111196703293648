import instance from "api/instance";


const register = async (data) => {
	const response = await instance.post("/customer/auth/register", data);
	if (response.status === 201 || response.status === 200) {
	
		return response.data;
	}

	return Promise.reject(response.data);
};

const logout = () => {
	localStorage.removeItem('token');
};

const registerService = {
    register,
    logout
};

export default registerService;