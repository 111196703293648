import { useState, useCallback } from 'react';
import { toast } from "react-toastify";
const useCopyToClipboard = () => {
  const [isCopied, setIsCopied] = useState(false);
 
  const copy = useCallback((text) => {
    if (navigator.clipboard) {
      navigator.clipboard.writeText(text)
        .then(() => {
          setIsCopied(true);
          setTimeout(() => setIsCopied(false), 3000);
          
        })
        .catch(err => console.error('Something went wrong', err));
    } else {
      let textarea = document.createElement("textarea");
      textarea.value = text;
      textarea.style.position = 'fixed'; //avoid scrolling to bottom
      document.body.appendChild(textarea);
      textarea.focus();
      textarea.select();

      try {
        let successful = document.execCommand('copy');
        successful ? setIsCopied(true) : console.error('Failed to copy text');
      
        setTimeout(() => setIsCopied(false), 3000);
      } catch (err) {
        console.error('Failed to copy text', err);
      } finally {
        document.body.removeChild(textarea);
      }
     
    }
  }, []);

  return [isCopied, copy];
};

export default useCopyToClipboard;
