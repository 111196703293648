import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import {
  detailSubreseller,
  updateSubreseller,
  reset
} from "redux/reseller/subresellers/subresellersSlice";

import moment from "moment";
import "moment/locale/tr";

moment.locale("tr");

const OrderDetailContainer = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const [isActive, setIsActive] = useState(false);
  const [calculatorDiamondPrice, setCalculatorDiamondPrice] = useState("");
  const [pbalanceTry, setPbalanceTry] = useState("");
  const [paymentKey, setPaymentKey] = useState(null);

  const {
    subresellerDetail: data,
    isUpdateLoading,
    isUpdateSubresellerSuccess,
    isUpdateSubresellerError,
  } = useSelector((state) => state.resellerSubresellers);

  useEffect(() => {
    dispatch(detailSubreseller({ search: id }));
  }, [dispatch, id]);

  useEffect(() => {
    setIsActive(data?.is_active || false);
    setCalculatorDiamondPrice(data?.calculator_diamond_price || 0);
    setPbalanceTry(data?.pbalance_try || 0);
    setPaymentKey(data?.payment_key || "Tanımlanmamış");
  }, [data]);

  useEffect(() => {
    if (isUpdateSubresellerSuccess) {
      toast.success("Alt Bayi Güncellendi")
      dispatch(detailSubreseller({ search: id }));
    }
    if (isUpdateSubresellerError) {
      toast.error("Alt Bayi Güncellenirken Hata Oluştu")
    }
    return () => dispatch(reset())
  }, [
    isUpdateSubresellerSuccess,
    isUpdateSubresellerError,
    dispatch,
  ]);

  const handleChangeActive = () => {
    setIsActive(!isActive);
    dispatch(
      updateSubreseller({
        subreseller_id: data?.id,
        is_active: !isActive,
      })
    )
      .then((response) => {
        console.log("Güncelleme başarılı :>>" + response);
      })
      .catch((error) => {
        setIsActive(isActive); // rollback
        toast.error("Kullanıcı güncellenirken bir hata oluştu.");
      });
  };

  const handleChangeSettings = () => {

    if (pbalanceTry === "") {
      toast.error("Bakiye Boş Bırakılamaz!")
      return;
    }

    if (pbalanceTry == data?.pbalance_try) {
      dispatch(
        updateSubreseller(
          {
            subreseller_id: data?.id,
            calculator_diamond_price: calculatorDiamondPrice,
            pbalance_try: pbalanceTry,
            //payment_key:paymentKey
          }
        )
      )
    } else {
      dispatch(
        updateSubreseller(
          {
            subreseller_id: data?.id,
            calculator_diamond_price: calculatorDiamondPrice,
            pbalance_try_old: data?.pbalance_try,
            pbalance_try_new: pbalanceTry,
            //payment_key:paymentKey
          }
        )
      )
    }
  }

  return (
    <div className="shadow-md rounded px-8 pt-6 pb-8 mb-4">
      <h2 className="text-2xl font-semibold text-white-100 mb-4 flex items-center gap-4">
        Alt Bayi Detay #{data?.id} |{" "}
      </h2>
      <hr className="mb-4" />
      <div className="grid grid-cols-1 md:grid-cols-2 mb-4 gap-4">
        <div className="bg-gray-900 p-4 rounded-lg shadow space-y-2">
          <h3 className="text-xl font-semibold text-white-100 mb-4 flex items-center gap-4">
            Kişisel Bilgiler{" "}
          </h3>
          <p className="text-white-100 space-x-1 flex items-center gap-2">
            <span className="font-semibold">Aktiflik Durumu:</span>{" "}
            <label className="relative inline-flex items-center cursor-pointer">
              <input
                type="checkbox"
                checked={isActive}
                value=""
                onChange={handleChangeActive}
                className="sr-only peer"
              />
              <div className="w-11 h-6 bg-white-100 peer-focus:ring-4 peer-focus:outline-none rounded-full peer  peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white-100 after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-primary"></div>
            </label>
          </p>
          <p className="text-white-100 space-x-1">
            <span className="font-semibold">Bayi Adı:</span> {data?.name}
          </p>
          <p className="text-white-100 space-x-1">
            <span className="font-semibold">Telefon Numarası:</span>{" "}
            {data?.phone}
          </p>
          <p className="text-white-100 space-x-1">
            <span className="font-semibold">Oluşturulma Tarihi:</span>{" "}
            {moment(data?.created_at).format("Do MMMM YYYY, H:mm:ss")}
          </p>
        </div>
        <div className="bg-gray-900 p-4 rounded-lg shadow space-y-2">
          <h3 className="text-xl font-semibold text-white-100 mb-4">
            Genel Ayarlar
          </h3>
          <p className="text-white-100">
            <span className="font-semibold">Bakiye (₺):</span>
            <input
              type="text"
              value={pbalanceTry}
              onChange={e => setPbalanceTry(e.target.value)}
              className="ml-2 rounded-md text-black focus:outline-none px-1"
            />
          </p>
          <p className="text-white-100">
            <span className="font-semibold">1 Elmas Satış Ücreti (₺):</span>
            <input
              type="text"

              value={calculatorDiamondPrice}
              onChange={e => setCalculatorDiamondPrice(e.target.value)}
              className="ml-2 rounded-md text-black focus:outline-none px-1"
            />
          </p>
          <p className="text-white-100">
            <span className="font-semibold">Alt Bayi Key:</span>
            <input
              disabled="true"
              type="text"
              value={paymentKey}
              onChange={e => setPaymentKey(e.target.value)}
              className="ml-2 rounded-md text-black focus:outline-none px-1"
            />
          </p>
          <button
            disabled={isUpdateLoading}
            onClick={handleChangeSettings}
            className="font-semibold text-white-100 bg-secondary rounded px-4 py-1"
          >
            {<span>{isUpdateLoading ? "Kaydediliyor..." : "Kaydet"}</span>}
          </button>
        </div>
      </div>
    </div>
  );
};

export default OrderDetailContainer;
