import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import authService from './authService';
import jwt_decode from "jwt-decode";

//Login Thunk Action Creator
export const login = createAsyncThunk('admin/auth/login', async (user, {dispatch,rejectWithValue}) => {
	try {
		const response = await authService.login(user);
		dispatch(setRole(jwt_decode(response.access_token).role));
		
		return response;
	} catch (error) {
		const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
		return rejectWithValue(message);
	}
});

export const logout = createAsyncThunk('admin/auth/logout', async () => {
	authService.logout();
});

const user = localStorage.getItem('token');


const initialState = {
	user: user ? user : null,
	role: user ? jwt_decode(user).role : null,
	isError: false,
	isLoading: false,
	isSuccess: false,
	isAuth: localStorage.getItem('token') === user ? true : false,
	message: ''
};

export const authSlice = createSlice({
	name: 'adminAuth',
	initialState,
	reducers: {
		reset: (state) => {
			state.isError = false;
			state.isSuccess = false;
			state.isSuccessLogout = false;
			state.isLoading = false;
			state.message = '';
		},
		setRole: (state, action) => {
			state.role = action.payload;
			
		}
	},
	extraReducers: (builder) => {
		builder
			.addCase(login.pending, (state) => {
				state.isLoading = true;
			})
			.addCase(login.fulfilled, (state, action) => {
				state.isLoading = false;
				state.isSuccess = true;
				state.user = action.payload;
				state.message = action.payload.message;
			})
			.addCase(login.rejected, (state, action) => {
				state.isLoading = false;
				state.isError = true;
				state.message = action.payload;
				state.user = null;
			})
			.addCase(logout.pending, (state) => {
				state.isLoading = true;
			})
			.addCase(logout.fulfilled, (state) => {
				state.isLoading = false;
				state.isSuccessLogout = true;
				state.isSuccess = false;
				state.user = null;
				state.message = 'Başarılı bir şekilde çıkış yaptınız. Anasayfaya yönlendiriliyorsunuz.';
			})
			.addCase(logout.rejected, (state) => {
				state.isLoading = false;
				state.isError = true;
				state.user = null;
			});
	}
});

export const { reset,setRole } = authSlice.actions;

export default authSlice.reducer;