import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import pricingService from './pricingService';

export const createPrice = createAsyncThunk('reseller/pricing/create', async (data, {rejectWithValue}) => {
	try {
		const response = await pricingService.create(data);
		return response;
	} catch (error) {
		const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
		return rejectWithValue(message);
	}
});

export const updatePrice = createAsyncThunk('reseller/pricing/update', async (data, {rejectWithValue}) => {
	try {
		const response = await pricingService.update(data);
		return response;
	} catch (error) {
		const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
		return rejectWithValue(message);
	}
});

export const deletePrice = createAsyncThunk('reseller/pricing/delete', async (data, {rejectWithValue}) => {
	try {
		const response = await pricingService.remove(data);
		return response;
	} catch (error) {
		const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
		return rejectWithValue(message);
	}
});

const initialState = {
    isCreateSuccess: false,
    isUpdateSuccess: false,
    isDeleteSuccess: false,
    isCreateError: false,
    isUpdateError: false,
    isDeleteError: false,
    isCreateLoading: false,
    isUpdateLoading: false,
    isDeleteLoading: false,
    createMessage: null,
    updateMessage: null,
    deleteMessage: null,
};

export const pricingSlice = createSlice({
	name: 'resellerPricing',
	initialState,
	reducers: {
		reset: (state) => {
			state.isCreateSuccess = false;
            state.isUpdateSuccess = false;
            state.isDeleteSuccess = false;
            state.isCreateError = false;
            state.isUpdateError = false;
            state.isDeleteError = false;
            state.isCreateLoading = false;
            state.isUpdateLoading = false;
            state.isDeleteLoading = false;
            state.createMessage = null;
            state.updateMessage = null;
            state.deleteMessage = null;
		},
		
	},
	extraReducers: (builder) => {
		builder
			.addCase(createPrice.pending, (state) => {
                state.isCreateLoading = true;
            })
            .addCase(createPrice.fulfilled, (state, action) => {
                state.isCreateLoading = false;
                state.isCreateSuccess = true;
                state.createMessage = action.payload.message;
            })
            .addCase(createPrice.rejected, (state, action) => {
                state.isCreateLoading = false;
                state.isCreateError = true;
                state.createMessage = action.payload;
            })
            .addCase(updatePrice.pending, (state) => {
                state.isUpdateLoading = true;
            })
            .addCase(updatePrice.fulfilled, (state, action) => {
                state.isUpdateLoading = false;
                state.isUpdateSuccess = true;
                state.updateMessage = action.payload.message;
            })
            .addCase(updatePrice.rejected, (state, action) => {
                state.isUpdateLoading = false;
                state.isUpdateError = true;
                state.updateMessage = action.payload;
            })
            .addCase(deletePrice.pending, (state) => {
                state.isDeleteLoading = true;
            })
            .addCase(deletePrice.fulfilled, (state, action) => {
                state.isDeleteLoading = false;
                state.isDeleteSuccess = true;
                state.deleteMessage = action.payload.message;
            })
            .addCase(deletePrice.rejected, (state, action) => {
                state.isDeleteLoading = false;
                state.isDeleteError = true;
                state.deleteMessage = action.payload;
            })
            
	}
});

export const { reset } = pricingSlice.actions;

export default pricingSlice.reducer;