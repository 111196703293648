import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { BiLoaderCircle } from "react-icons/bi";

import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import {
  orderCreate,
  receiptRequired as ReceptRequired,
  reset,
} from "redux/customer/orders/ordersSlice";

const OrderCreate = ({ amount }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(ReceptRequired());
  }, []);
  const {
    receiptRequired,
    isCreateSuccess,
    isCreateError,
    isCreateLoading,
    message,
  } = useSelector((state) => state.customerOrders);

  const createOrderForm = Yup.object().shape({
    bigoUsername: Yup.string().required(" gereklidir."),
    paymentAmount: Yup.number().required("Ödeme tutarı gereklidir."),
    sorguNo: Yup.number().required("Sorgu No gereklidir.")
  });

  useEffect(() => {
    if (isCreateError) {
      toast.error(message);
      if(message.payment_amount){
        toast.error(message.payment_amount);
      }
    }
    if (isCreateSuccess) {
      toast.success("Sipariş başarıyla oluşturuldu.");
      setTimeout(() => {
        navigate("/customer");
      }, 1500);
    }
    return () => {
      dispatch(reset());
    };
  }, [isCreateError, isCreateSuccess, message, dispatch]);

  const handleFileUpload = async (file) => {
    if (!file) return null;

    return new Promise((resolve) => {
      const reader = new FileReader();

      reader.onloadend = () => {
        const base64String = reader.result
          .replace("data:", "")
          .replace(/^.+,/, "");

        const fileInfo = {
          mimeType: file.type,
          base64: base64String,
        };

        resolve(fileInfo);
      };

      reader.readAsDataURL(file);
    });
  };
  const handleSubmit = async (values) => {
    const uploadedFileInfo = await handleFileUpload(values.receipt_file);

    if (uploadedFileInfo && receiptRequired === false) {
      dispatch(
        orderCreate({
          bigo_username: values.bigoUsername,
          payment_amount: values.paymentAmount,
          receipt_file: uploadedFileInfo.base64 || null,
          receipt_mimetype: uploadedFileInfo.mimeType || null,
        })
      );
    } else {
      dispatch(
        orderCreate({
          bigo_username: values.bigoUsername,
          payment_amount: values.paymentAmount,
          sorgu_no: values.sorguNo
        })
      );
    }
  };

  return (
    <div className="">
      <div className="p-4 bg-gray-900 rounded-md md:min-h-screen">
        <div className="flex gap-4 items-center my-4">
          <span className="text-2xl bg-primary text-white-100  flex items-center justify-center rounded-full w-10 h-10">
            3
          </span>
          <span className="text-white-100 font-semibold text-lg">Satın Al</span>
        </div>
        <div className="grid grid-cols-1">
          <div className="p-4  md:h-screen">
            <Formik
              initialValues={{
                bigoUsername: "",
                paymentAmount: amount.toFixed(2),
                sorguNo: "",
                receipt_file: "" || null,
              }}
              validationSchema={createOrderForm}
              onSubmit={handleSubmit}
            >
              {({ errors, touched, setFieldValue }) => (
                <Form className="space-y-4">
                  <div className="">
                    <span className="text-white-100 font-medium">
                      Kullanıcı ID
                    </span>
                    <Field
                      name="bigoUsername"
                      type="text"
                      className="w-full font-medium bg-white-100 rounded-lg appearance-none focus:outline-none  p-2 mt-2"
                    />
                    <ErrorMessage
                      name="bigoUsername"
                      component="div"
                      className="text-red-500"
                    />
                  </div>
                  <div className="">
                    <span className="text-white-100 font-medium">
                      EFT/Havale Sorgu No
                    </span>
                    <Field
                      name="sorguNo"
                      type="number"
                      className="w-full font-medium bg-white-100 rounded-lg appearance-none focus:outline-none  p-2 mt-2"
                    />
                    <ErrorMessage
                      name="sorguNo"
                      component="div"
                      className="text-red-500"
                    />
                  </div>
                  <div className="">
                    <span className="text-white-100 font-medium">
                      Ödeme Tutarı (TL)
                    </span>
                    <Field
                      name="paymentAmount"
                      type="number"
                      className="w-full font-medium bg-white-100 rounded-lg appearance-none focus:outline-none  p-2 mt-2"
                    />
                    <ErrorMessage
                      name="paymentAmount"
                      component="div"
                      className="text-red-500"
                    />
                  </div>
                  {receiptRequired && (
                    <div className="">
                      <span className="text-white-100 font-medium">
                        Ödeme Dekont
                      </span>
                      <Field name="receipt_file">
                        {({ field }) => (
                          <input
                            id="receipt_file"
                            name="receipt_file"
                            type="file"
                            className="w-full font-medium bg-white-100 rounded-lg appearance-none focus:outline-none  p-2 mt-2"
                            onChange={(e) => {
                              setFieldValue(
                                "receipt_file",
                                e.currentTarget.files[0]
                              );
                            }}
                          />
                        )}
                      </Field>

                      <ErrorMessage
                        name="receipt_file"
                        component="div"
                        className="text-red-500"
                      />
                    </div>
                  )}

                  <button
                    className="bg-secondary disabled:bg-blue-400 text-white-100 font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                    type="submit"
                  >
                    {isCreateLoading ? (
                      <BiLoaderCircle className="animate-spin w-full text-center" />
                    ) : (
                      "Satın Al"
                    )}
                  </button>
                </Form>
              )}
            </Formik>
            <div className="mt-8">
              <h2 className="text-primary text-lg font-semibold ">
                Satın Alma İşleminden Önce Okuyunuz!
              </h2>
              <ul className="text-white-100 space-y-2 mt-4 list-decimal pl-4">
                <li>
                  Satın alma işlemi yaparken nızı doğru
                  girdiğinizden emin olun.
                </li>
                <li>
                  Satın alma işleminden önce ilgili hesap numaralarımıza para
                  gönderimini yapmanız gerekmektedir.
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrderCreate;
