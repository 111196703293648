import React, { useState, useEffect } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { createBankAccount, reset } from "redux/admin/bank-accounts/bankAccountsSlice";
import * as Yup from "yup";
import { toast } from "react-toastify";
import Heading from "components/Typography/Header";
import Button from "components/Button";


const BankAccountsCreateContainer = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isCreateLoading, isCreateError, isCreateSuccess, message } = useSelector(state => state.adminBankAccounts);

  const createBankAccountForm = Yup.object().shape({
    name: Yup.string().required("Ad gereklidir."),
    iban: Yup.string().required("IBAN gereklidir."),
    code: Yup.string().required("Banka Kodu gereklidir."),
    holder_name: Yup.string().required("Hesap sahibi gereklidir."),
  })

  const handleSubmit = async (values) => {
    dispatch(
      createBankAccount({
        name: values.name,
        iban: values.iban,
        code: values.code,
        holder_name: values.holder_name
      })
    );
  }

  useEffect(() => {
    if (isCreateError) {
      toast.error("Hata oluştu.");
    }
    if (isCreateSuccess) {
      toast.success(message.message);
      setTimeout(() => {
        navigate("/admin/bank-accounts");
      }, 1500);
    }
    return () => {
      dispatch(reset());
    }
  }, [isCreateSuccess, isCreateError, dispatch])

  return (
    <div className="p-4">
      <Heading className="text-white-100" level={5}>
        Banka Hesabı Ekle
      </Heading>
      <div className="p-4 bg-gray-900 my-4 rounded-xl min-h-screen">
        <div className="p-4 bg-black md:h-screen">
          <Formik
            initialValues={{
              name: "",
              iban: "",
              code: "",
              holder_name: "",
            }}
            validationSchema={createBankAccountForm}
            onSubmit={handleSubmit}
          >
            {({ values, errors, touched }) =>
              <Form className="space-y-4">

                <div className="w-full">
                  <span className="text-white-100 font-medium">
                    Banka Adı
                  </span>
                  <Field
                    name="name"
                    type="text"
                    className="w-full font-medium bg-white-100 rounded-lg appearance-none focus:outline-none  p-2 mt-2"
                  />
                  <ErrorMessage
                    name="name"
                    component="div"
                    className="text-red-500"
                  />
                </div>
                <div className="">
                  <span className="text-white-100 font-medium">
                    Hesap Sahibi
                  </span>
                  <Field
                    name="holder_name"
                    type="text"
                    className="w-full font-medium bg-white-100 rounded-lg appearance-none focus:outline-none  p-2 mt-2"
                  />
                  <ErrorMessage
                    name="holder_name"
                    component="div"
                    className="text-red-500"
                  />
                </div>
                <div className="w-full">
                  <span className="text-white-100 font-medium">
                    IBAN
                  </span>
                  <Field
                    name="iban"
                    type="text"
                    className="w-full font-medium bg-white-100 rounded-lg appearance-none focus:outline-none  p-2 mt-2"
                  />
                  <ErrorMessage
                    name="iban"
                    component="div"
                    className="text-red-500"
                  />
                </div>
                <div className="">
                  <span className="text-white-100 font-medium">
                    Banka Kodu
                  </span>
                  <Field
                    name="code"
                    type="text"
                    className="w-full font-medium bg-white-100 rounded-lg appearance-none focus:outline-none  p-2 mt-2"
                  />
                  <div>
                    <span className="text-red-500 font-semibold">!!!</span>
                    <span className="text-white-100 font-medium">Banka kodu, BANKA-00 formatında olmalıdır. Banka adının tamamen büyük harf ve türkçe karakter olmamasına dikkat edin. 00, ilgili ibanın son 2 hanesi olacak şekilde girilmeli. </span>
                    <span className="text-red-500 font-semibold">!!!</span><br />
                    <span className="text-primary text-sm">Örnek1: GARANTI-78</span><br />
                    <span className="text-primary text-sm">Örnek2: YAPIKREDI-49</span>
                  </div>
                  <ErrorMessage
                    name="code"
                    component="div"
                    className="text-red-500"
                  />
                </div>

                <Button
                  type="submit"
                  size="small"
                  loading={isCreateLoading}
                  className="bg-primary"
                >
                  Ekle
                </Button>
              </Form>
            }
          </Formik>
        </div>
      </div>
    </div>
  )
}

export default BankAccountsCreateContainer;
