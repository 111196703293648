import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { createOtpCode, reset } from "redux/admin/createOtp/createOtpSlice";
import { toast } from "react-toastify";
import Heading from "components/Typography/Header";
import { BiLoaderCircle } from "react-icons/bi";
const CreateOtpPage = () => {
  const dispatch = useDispatch();
  const {
    data,
    isCreateOtpLoading,
    isCreateOtpError,
    isCreateOtpSuccess,
    message,
  } = useSelector((state) => state.adminCreateOtp);

  const [phone, setPhone] = useState("");
  const [reason, setReason] = useState("");
  const [validityMin, setValidityMin] = useState("");
  const [userType, setUserType] = useState("");

  useEffect(() => {
    if (isCreateOtpError) {
      toast.error(JSON.stringify(message));
      
    }

    return () => {
      dispatch(reset());
    };
  }, [dispatch,isCreateOtpError]);

  const handleCreateOtp = () => {
    if(phone === ""){
        toast.error("Telefon Numarası Boş Bırakılamaz")
        return
    }
    if(validityMin === ""){
        toast.error("Geçerlilik Süresi Boş Bırakılamaz")
        return
    }
    if(reason === "" || reason === 0){
        toast.error("Sebep Boş Bırakılamaz")
        return
    }
    if(userType === "" || userType === 0 ){
        toast.error("Kullanıcı Tipi Boş Bırakılamaz")
        return
    }
    dispatch(
      createOtpCode({
        phone: phone,
        reason: reason,
        validity_minutes: validityMin,
        user_type: userType,
      })
    );
  };

  return (
    <div className="p-4">
      <Heading className="text-white-100" level={5}>
        OTP Oluştur
      </Heading>
      <div className="p-4 bg-gray-900 my-4 rounded-xl min-h-screen">
        <div className="space-y-4">
          {isCreateOtpSuccess && (
            <div className="bg-green-200 text-green-800 inline-block p-4 rounded-md">
              <h2>{data?.message}</h2>
            </div>
          )}
          <div className="space-y-2">
            <h2 className="text-white-100">Telefon Numarası</h2>
            <input
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
              className="rounded-md pl-2 focus:outline-none"
              type="text"
            />
          </div>
          <div className="space-y-2">
            <h2 className="text-white-100">Sebep</h2>
            <select
              onChange={(e) => setReason(e.target.value)}
              className="rounded-md pl-2 focus:outline-none"
              name=""
              id=""
            >
              <option value="0">Seçiniz</option>
              <option value="register">Kayıt Olma</option>
              <option value="login">Giriş Yapma</option>
            </select>
          </div>
          <div className="space-y-2">
            <h2 className="text-white-100">Geçerlilik Süresi (Dakika)</h2>
            <input
              value={validityMin}
              onChange={(e) => setValidityMin(e.target.value)}
              className="rounded-md pl-2 focus:outline-none"
              type="text"
            />
          </div>
          <div className="space-y-2">
            <h2 className="text-white-100">Kullanıcı Tipi</h2>
            <select
              onChange={(e) => setUserType(e.target.value)}
              className="rounded-md pl-2 focus:outline-none"
              name=""
              id=""
            >
              <option value="0">Seçiniz</option>
              <option value="reseller">Bayi</option>
              <option value="subreseller">Alt Bayi</option>
              <option value="customer">Kullanıcı</option>
            </select>
          </div>
          <button
            onClick={() => handleCreateOtp()}
            disabled={isCreateOtpLoading}
            className="bg-secondary rounded-md px-4 py-1 text-sm text-white-100 font-medium inline-block"
          >
            {isCreateOtpLoading ? (
              <BiLoaderCircle className="animate-spin w-full text-center" />
            ) : (
              "Oluştur"
            )}
          </button>
        </div>
      </div>
    </div>
  );
};
export default CreateOtpPage;
