import React from 'react'
import {Link} from 'react-router-dom'
import OrderIndicator from 'components/OrderIndicator'
import moment from 'moment'
import 'moment/locale/tr'  

moment.locale("tr");


const Subresellers = ({data}) => {
 
  return (
    <div className="overflow-x-auto p-4">
      <table className="min-w-full divide-y-2 divide-gray-200 text-sm">
        <thead className="">
          <tr>
            <th className="whitespace-nowrap text-left px-4 py-2 font-medium text-white-100">
              Alt Bayi Adı
            </th>

            <th className="whitespace-nowrap text-left px-4 py-2 font-medium text-white-100">
              Kazanç (₺)
            </th>
         
            <th className="whitespace-nowrap text-left px-4 py-2 font-medium text-white-100">
              Durum
            </th>
            <th className="whitespace-nowrap text-left px-4 py-2 font-medium text-white-100">
              İşlem
            </th>
          </tr>
        </thead>

        <tbody className="divide-y divide-gray-200">
          
          {data?.map((data) => (
            <tr key={data?.id}>
              <td className="whitespace-nowrap px-4 py-2 font-medium text-white-100">
                {data?.name}
              </td>
           
              <td className="whitespace-nowrap px-4 py-2 text-white-100">
                {data?.pbalance_try} ₺
              </td>
              
            
              <td className="whitespace-nowrap px-4 py-2 text-white-100">
                {data.is_active === false ? (
                  <OrderIndicator color="yellow">Pasif</OrderIndicator>
                ) : data?.is_active === true ? (
                  <OrderIndicator color="green">Aktif</OrderIndicator>
                ) : (
                  <OrderIndicator color="red">No Data</OrderIndicator>
                )}
              </td>
              <td className="whitespace-nowrap px-4 py-2">
                <Link
                  to={`/reseller/subresellers/detail/${data.id}`}
                  className="inline-block rounded bg-indigo-600 px-4 py-2 text-xs font-medium text-white-100 hover:bg-indigo-700"
                >
                  Detaylı Gör
                </Link>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default Subresellers;