import React from 'react'
import Header from 'components/Panel/Admin/Header'
import TopCards from 'components/Panel/Admin/TopCards'
import BarChart from 'components/Panel/Admin/BarChart'
import RecentOrders from 'components/Panel/Admin/RecentOrders'

const PanelContainer = () => {
    return (
        <>
            <Header />
            <TopCards />
            <div className='p-4 grid md:grid-cols-3 grid-cols-1 gap-4'>
                <BarChart />
                <RecentOrders />
            </div>
        </>
    )
}

export default PanelContainer

