import React from 'react'
import AdminLoginContainer from 'containers/panel/admin/login-container'
import { Helmet } from 'react-helmet'

const AdminLoginPage = () => {
  return (
    <>
    <Helmet>
      <title>Admin Giriş | SkyPay</title>
    </Helmet>
    <AdminLoginContainer/>
    </>
  )
}

export default AdminLoginPage