import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import registerService from "./registerService";
import jwt_decode from "jwt-decode";

export const sendOtp = createAsyncThunk("customer/auth/sendOtp", async (data, { rejectWithValue }) => {
  try {
    const response = await registerService.register(data);
    return response;
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return rejectWithValue(message);
  }
});

export const loginWithOtp = createAsyncThunk(
  "customer/auth/loginWithOtp",
  async (data, { dispatch, rejectWithValue }) => {
    try {
      const response = await registerService.register(data);
      return response;
    } catch (error) {
      const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
      return rejectWithValue(message);
    }
  }
);

export const logout = createAsyncThunk('customer/auth/logout', async () => {
  registerService.logout();
});




const initialState = {

  isError: false,
  isLoading: false,
  isSuccess: false,
  isSuccessLoginWithOtp: false,
  isErrorLoginWithOtp: false,
  isLoadingLoginWithOtp: false,
  message: '',
  isSendOTP: false,
};

const authSlice = createSlice({
  name: "customerRegister",
  initialState,
  reducers: {
    reset: (state) => {
      state.isError = false;
      state.isSuccess = false;
      state.isLoading = false;
      state.isSuccessLoginWithOtp = false;
      state.isErrorLoginWithOtp = false;
      state.isLoadingLoginWithOtp = false;
      state.message = '';
    },

  },
  extraReducers: (builder) => {
    builder
      .addCase(sendOtp.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(sendOtp.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isSendOTP = true;
        state.message = action.payload.message;
      })
      .addCase(sendOtp.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(loginWithOtp.pending, (state) => {
        state.isLoadingLoginWithOtp = true;
      })
      .addCase(loginWithOtp.fulfilled, (state, action) => {
        state.isLoadingLoginWithOtp = false;
        state.isLoggedIn = true;
        state.isSuccessLoginWithOtp = true;
        state.isSendOTP = false;
        state.message = action.payload.message;
      })
      .addCase(loginWithOtp.rejected, (state, action) => {
        state.isLoadingLoginWithOtp = false;
        state.isLoggedIn = false;
        state.isSuccessLoginWithOtp = false;
        state.isErrorLoginWithOtp = true;
      });
  },
});
export const { reset, setRole } = authSlice.actions;
export default authSlice.reducer;
