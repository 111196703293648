import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import Heading from "components/Typography/Header";
import Button from "components/Button";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { useCustomSettings } from 'hooks/useCustomSettings'
import { createTransfer, reset } from "redux/reseller/diamond-transfer/diamondTransferSlice";
import { profile } from "redux/reseller/profile/profileSlice";
import { useUUID } from "hooks/useUUID";

const keyTextMapping = {
  base_diamond_price_usd: "1 Adet Elmas Fiyatı ($)",
  usdtry_exchange_rate: "1 Dolar",
}

const DiamondTransferCreateContainer = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [trigger, setTrigger] = useState(0);
  const { profile: profileData } = useSelector(state => state.resellerProfile)
  const { data } = useSelector(state => state.resellerSettings);
  const uuid = useUUID(trigger);
  const {
    isCreateTransferSuccess,
    isCreateTransferError,
    isCreateTransferLoading,
    message,
  } = useSelector((state) => state.resellerDiamondTransfer);

  const diamondPrice = useCustomSettings(data, keyTextMapping, 'base_diamond_price_usd');
  const exchangeRate = useCustomSettings(data, keyTextMapping, 'usdtry_exchange_rate');
  
  const createDiamondTransferForm = Yup.object().shape({
    amount_try: Yup.number().required("Tutar Zorunludur"),
  });

  useEffect(() => {
    dispatch(profile());
  }, [dispatch]);

  useEffect(() => {
    if (isCreateTransferError) {
      if (message.amount_try) {
        toast.error(message.amount_try)
      }
      toast.error(message);
    }
    if (isCreateTransferSuccess) {
      toast.success("Talebiniz Alınmıştır.");
      navigate("/reseller/diamond-transfers");
    }
    return () => {
      dispatch(reset());
    };
  }, [isCreateTransferError, isCreateTransferSuccess, message, dispatch]);

  const handleSubmit = async (values) => {
    await dispatch(
      createTransfer({
        amount_try: values.amount_try,
        nonce: uuid,
      })
    );
    setTrigger((prev) => prev + 1);
  };

  return (
    <div className="p-4 ">
      <Heading className="text-2xl font-bold text-white-100 mb-4" level={5}>
        Elmas Transfer Talebi
      </Heading>
      <div className="p-4 bg-gray-900 my-4 rounded-xl  shadow-lg">
        <div className="grid grid-cols-1  gap-4">
          <div className="p-4 bg-black shadow-lg rounded-lg min-h-screen">
            <Formik
              initialValues={{
                amount_try: "",
              }}
              validationSchema={createDiamondTransferForm}
              onSubmit={handleSubmit}
            >
              {({ errors, touched, setFieldValue, values }) => {
                return (
                  <Form className="space-y-6 max-w-[350px]">
                    <div className="w-full">
                      <div className="text-white-100 font-medium mb-4">Hesabınızda Bulunan Bakiye: {profileData.balance_try}₺</div>
                      <label className="block text-white-100 font-medium mb-2">Tutar (₺)</label>
                      <Field
                        name="amount_try"
                        type="text"
                        className="w-full font-medium bg-white-100 rounded-lg appearance-none focus:outline-none focus:ring-2 focus:ring-primary p-2"
                      />
                      <ErrorMessage
                        name="amount_try"
                        component="div"
                        className="text-red-500"
                      />
                      <div className="flex flex-col space-y-4 mt-4">
                        <span className="block mt-2 text-primary font-medium">Dolar Kuru: {exchangeRate.value} ₺</span>
                        <span className="block mt-2 text-primary font-medium">Komisyon: %{profileData.commission_rate}</span>
                        <span className="block mt-2 text-primary font-medium">Hesap Bakiyenizin Dolar Karşılığı: ${(profileData.balance_try / exchangeRate.value).toFixed(2)}</span>
                        <span className="block mt-2 text-primary font-medium">Çekim Talebinin Dolar Karşılığı: ${(values.amount_try / exchangeRate.value).toFixed(2)}</span>
                        <span className="block mt-2 text-primary font-medium">Komisyon Kesilmiş Tutarın Dolar Karşılığı: ${((values.amount_try - ((values.amount_try * profileData.commission_rate) / 100))/exchangeRate.value).toFixed(2)}</span>
                        <span className="block mt-2 text-primary font-medium">Hesabınıza Yatırılacak Net Elmas: {(values.amount_try / (diamondPrice.value * exchangeRate.value / (1 - profileData.commission_rate / 100))).toFixed(0)}</span>

                      </div>
                    </div>

                    <Button
                      type="submit"
                      size="small"
                      loading={isCreateTransferLoading}
                      className="bg-primary hover:bg-primary-dark text-white-100 rounded-lg w-full py-2 mt-4"
                    >
                      Talep Oluştur
                    </Button>
                  </Form>
                );
              }}
            </Formik>
          </div>
        </div>
      </div>
    </div>


  );
};

export default DiamondTransferCreateContainer;
