import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import Heading from "components/Typography/Header";
import Button from "components/Button";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import {
  orderCreate,
  reset,
  orderFinalize
} from "redux/subreseller/orders/ordersSlice";
import DiamondCalculator from "components/DiamondCalculator";

const OrderCreateContainer = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const {

    isCreateSuccess,
    isCreateError,
    isCreateLoading,
    message,
    orderSummary,
  } = useSelector((state) => state.subresellerOrders);

  const { calculator_diamond_price } = useSelector((state) => state.subresellerProfile.profile);

  const createOrderForm = Yup.object().shape({
    paymentAmount: Yup.number().required("Ödeme tutarı gereklidir."),
    sorguNo: Yup.string().required("EFT/Havale sorgu no gereklidir."),
  });

  useEffect(() => {
    if (isCreateError) {
      toast.error(message);
    }
    if (isCreateSuccess) {
      toast.success("Dekont Eklenmiştir.");


      dispatch(orderFinalize({
        order_id: orderSummary.id,
        status: "completed",
        message: null,
        transfer_reference: null
      }));


    }
    return () => {
      dispatch(reset());
    };
  }, [isCreateError, isCreateSuccess, message, dispatch]);

  const handleSubmit = async (values) => {

    dispatch(
      orderCreate({
        payment_amount: values.paymentAmount,
        sorgu_no: values.sorguNo,
        diamonds_amount: parseFloat((values.paymentAmount / calculator_diamond_price).toFixed(3)),
      })
    );

  };


  return (
    <div className="p-4 ">
      <Heading className="text-white-100" level={5}>
        Dekont Ekle
      </Heading>
      <div className="p-4 bg-gray-900 my-4 rounded-xl min-h-screen">
        <div className="grid grid-cols-1 md:grid-cols-1 gap-4">
          <div className="p-4 bg-black md:h-screen">
            <Formik
              initialValues={{
                paymentAmount: "",
                sorguNo: "",
              }}
              validationSchema={createOrderForm}
              onSubmit={handleSubmit}
            >
              {({ errors, touched, setFieldValue, values }) => {

                return (
                  (
                    <Form className="space-y-4">


                      <div className="">
                        <span className="text-white-100 font-medium">
                          Ödeme Tutarı (TL)
                        </span>
                        <Field
                          name="paymentAmount"
                          type="number"
                          className="w-full font-medium bg-white-100 rounded-lg appearance-none focus:outline-none  p-2 mt-2"
                        />
                        <ErrorMessage
                          name="paymentAmount"
                          component="div"
                          className="text-red-500"
                        />
                      </div>

                      <div className="">
                        <span className="text-white-100 font-medium">
                         Ad Soyad
                        </span>
                        <Field
                          name="sorguNo"
                          type="text"
                          className="w-full font-medium bg-white-100 rounded-lg appearance-none focus:outline-none  p-2 mt-2"
                        />

                        <ErrorMessage
                          name="sorguNo"
                          component="div"
                          className="text-red-500"
                        />
                      </div>


                      <Button
                        type="submit"
                        size="small"
                        loading={isCreateLoading}
                        className="bg-primary"
                      >
                        Dekont Ekle
                      </Button>
                    </Form>
                  )
                )
              }}
            </Formik>

          </div>
         

        </div>
      </div>
    </div>
  );
};

export default OrderCreateContainer;
