import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import totalSales from './totalSalesService';

export const getTotalSales = createAsyncThunk('subreseller/totalSales', async (data, {rejectWithValue}) => {
	try {
		const response = await totalSales.sales(data);
		return response;
	} catch (error) {
		const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
		return rejectWithValue(message);
	}
});

const initialState = {
	data:[],
    isLoading: false,
    isError: false,
    isSuccess: false,
    message: null,
};

export const totalSalesSlice = createSlice({
	name: 'subresellerTotalSales',
	initialState,
	reducers: {
		reset: (state) => {
			state.isError = false;
			state.isSuccess = false;
			state.isLoading = false;
			
		},
		
	},
	extraReducers: (builder) => {
		builder
			.addCase(getTotalSales.pending, (state) => {
				state.isLoading = true;
			})
			.addCase(getTotalSales.fulfilled, (state, action) => {
				state.isLoading = false;
				state.isSuccess = true;
				state.data = action.payload;
			})
			.addCase(getTotalSales.rejected, (state, action) => {
				state.isLoading = false;
				state.isError = true;
                state.isSuccess = false;
				state.message = action.payload;
				
			})
	}
});

export const { reset } = totalSalesSlice.actions;

export default totalSalesSlice.reducer;
