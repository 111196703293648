import React, { useState } from 'react'
import styles from './FAQ.module.css'
import Heading from 'components/Typography/Header'
import { ReactComponent as Plus } from 'images/icons/plus.svg'
import { ReactComponent as Minus } from 'images/icons/minus.svg'

const FAQItem = ({ question, answer }) => {
    const [isOpen, setIsOpen] = useState(false);

    const handleClick = () => {
        setIsOpen(!isOpen);
    };

    return (
        <div className={styles.faq}>
            <div className='partner-shadow p-[30px] rounded-xl flex justify-between'>
                <div>
                    <div className={styles.question} onClick={handleClick}>
                        <Heading level={5} className="text-gray-600">{question}</Heading>
                    </div>
                    <div className={`${styles.answer} ${isOpen ? styles.open : styles.closed}`}>
                        <hr className='my-[30px]' />
                        <div className='text-white-100'>
                            {answer}
                        </div>
                    </div>
                </div>
                <div className={`p-3 w-10 h-10 rounded-lg partner-shadow ${isOpen ? 'bg-primary' : ''} flex items-center justify-center`}>
                    {isOpen ? <Minus /> : <Plus />}
                </div>
            </div>
        </div>
    );
}

export default FAQItem