import heroImg from 'images/heroImg.svg'
import Button from 'components/Button'
import Header from 'components/Typography/Header'
import Paragraph from 'components/Typography/Paragraph'
import {Link} from 'react-router-dom'
const Hero = () => {
    return (
        <section className='md:h-[900px] mt-4 md:mt-0 grid grid-cols-12 gap-[30px] items-center'>
            <img
                className='absolute right-0 hidden md:block'
                src={heroImg}
                alt='hero image'
                width={773}
                height={747}
            />
            <div className='relative z-99 md:col-start-1 md:col-end-8 col-start-1 col-end-13'>
                <Header level={4} className="text-secondary text-center md:text-left">Hızlı, Güvenli ve Kolay</Header>
                <Header level={1} className="text-gray-600 text-center md:text-left">SkyPay</Header>
                <Paragraph size="large" className="text-center md:text-left">SkyPay ile başarıya ulaşmanızı destekleyen ürünler ve hizmetlerle oyun dünyasında hızla ilerleyin.</Paragraph>
                <div className='space-x-[20px] mt-10 flex justify-center md:block'>
                  <Link to="/register">  <Button>Üye Ol</Button></Link>
                   <Link to="/login/customer"> <Button variant="secondary">Giriş Yap</Button></Link>
                </div>
            </div>
        </section>
    )
}

export default Hero