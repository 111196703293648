import {createSlice, createAsyncThunk} from '@reduxjs/toolkit';
import usersService from './usersService';


export const getCustomers = createAsyncThunk('admin/users/customers', async (params, {rejectWithValue}) => {
    try {
        const response = await usersService.customers(params);
        return response;
    } catch (error) {
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
        return rejectWithValue(message);
    }
});

export const updateCustomers = createAsyncThunk('admin/users/customers/update', async (data, {rejectWithValue}) => {
    try {
        const response = await usersService.updateCustomer(data);
        return response;
    } catch (error) {
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
        return rejectWithValue(message);
    }
});

export const detailCustomers = createAsyncThunk('admin/user/customers/details', async ({search}, {getState,dispatch,rejectWithValue}) => {
    try {
        
        const state = getState();
        let customers = state.adminUsers.customers.data;
    
        if (!customers || customers.length === 0) {
          const response = await dispatch(getCustomers({ page: 1, per_page: 30, show_inactive: true }));
          customers = response.payload.data;
        }
    
        const customer = customers.find((customer) => customer.id === search);
    
        if (!customer) {
          throw new Error(`customer not found for id: ${search}`);
        }
    
        return customer;
      } catch (error) {
        const message = error.message || error.toString();
        return rejectWithValue(message);
      }
});

export const getResellers = createAsyncThunk('admin/users/resellers', async (params, {rejectWithValue}) => {
    try {
        const response = await usersService.resellers(params);
        return response;
    } catch (error) {
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
        return rejectWithValue(message);
    }
});

export const updateReseller = createAsyncThunk('admin/users/resellers/update', async (data, {rejectWithValue}) => {
    try {
        const response = await usersService.updateReseller(data);
        return response;
    } catch (error) {
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
        return rejectWithValue(message);
    }
});

export const createReseller = createAsyncThunk('admin/users/resellers/create', async (data, {rejectWithValue}) => {
    try {
        const response = await usersService.createReseller(data);
        return response;
    } catch (error) {
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
        return rejectWithValue(message);
    }
});

export const detailReseller = createAsyncThunk('admin/user/resellers/details', async ({search}, {getState,dispatch,rejectWithValue}) => {
    try {
        
        const state = getState();
        let resellers = state.adminUsers.resellers.data;
    
        if (!resellers || resellers.length === 0) {
          const response = await dispatch(getResellers({ page: 1, per_page: 30, show_inactive: true }));
          resellers = response.payload.data;
        }
    
        const reseller = resellers.find((customer) => customer.id === search);
    
        if (!reseller) {
          throw new Error(`reseller not found for id: ${search}`);
        }
    
        return reseller;
      } catch (error) {
        const message = error.message || error.toString();
        return rejectWithValue(message);
      }
});

export const getSubresellers = createAsyncThunk('admin/users/subresellers', async (params, {rejectWithValue}) => {
    try {
        const response = await usersService.subresellers(params);
        return response;
    } catch (error) {
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
        return rejectWithValue(message);
    }
});

export const updateSubreseller = createAsyncThunk('admin/users/subresellers/update', async (data, {rejectWithValue}) => {
    try {
        const response = await usersService.updateSubreseller(data);
        return response;
    } catch (error) {
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
        return rejectWithValue(message);
    }
});

export const createSubreseller = createAsyncThunk('admin/users/subresellers/create', async (data, {rejectWithValue}) => {
    try {
        const response = await usersService.createSubreseller(data);
        return response;
    } catch (error) {
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
        return rejectWithValue(message);
    }
});


export const detailSubreseller = createAsyncThunk('admin/user/subresellers/details', async ({search}, {getState,dispatch,rejectWithValue}) => {
    try {
        
        const state = getState();
        let subresellers = state.adminUsers.subresellers.data;
    
        if (!subresellers || subresellers.length === 0) {
          const response = await dispatch(getSubresellers({ page: 1, per_page: 30, show_inactive: true }));
          subresellers = response.payload.data;
        }
    
        const subreseller = subresellers.find((customer) => customer.id === search);
    
        if (!subreseller) {
          throw new Error(`subreseller not found for id: ${search}`);
        }
    
        return subreseller;
      } catch (error) {
        const message = error.message || error.toString();
        return rejectWithValue(message);
      }
});



const initialState = {
    customers: [],
    resellers: [],
    subresellers: [],
    customer: {},
    reseller: {},
    subreseller: {},
    isSuccessDetailReseller: false,
    isErrorDetailReseller: false,
    isLoadingDetailReseller: false,
    isSuccessUpdateReseller: false,
    isErrorUpdateReseller: false,
    isLoadingUpdateReseller: false,
    isSuccessDetailSubreseller: false,
    isErrorDetailSubreseller: false,
    isLoadingDetailSubreseller: false,
    isSuccessUpdateSubreseller: false,
    isErrorUpdateSubreseller: false,
    isLoadingUpdateSubreseller: false,
    isSuccessUpdateCustomer: false,
    isErrorUpdateCustomer: false,
    isLoadingUpdateCustomer: false,
    isSuccessDetailCustomer: false,
    isErrorDetailCustomer: false,
    isLoadingDetailCustomer: false,
    messageUpdateCustomer: '',
    isLoadingCustomers: false,
    isLoadingResellers: false,
    isLoadingSubresellers: false,
    isErrorCustomers: false,
    isErrorResellers: false,
    isErrorSubresellers: false,
    isSuccessCustomers: false,
    isSuccessResellers: false,
    isSuccessSubresellers: false,
    messageDetailCustomer: '',
    messageCustomers:'',
    messageResellers:'',
    messageSubresellers:'',
    isLoadingCreateReseller: false,
    isErrorCreateReseller: false,
    isSuccessCreateReseller: false,
    isLoadingCreateSubreseller: false,
    isErrorCreateSubreseller: false,
    isSuccessCreateSubreseller: false,
};

export const usersSlice = createSlice({
    name: 'adminUsers',
    initialState,
    reducers: {
        reset: (state) => {
            state.isLoadingCustomers = false;
            state.isLoadingResellers = false;
            state.isLoadingSubresellers = false;
            state.isErrorCustomers = false;
            state.isErrorResellers = false;
            state.isErrorSubresellers = false;
            state.isSuccessCustomers = false;
            state.isSuccessResellers = false;
            state.isSuccessSubresellers = false;
            state.isSuccessUpdateCustomer = false;
            state.isErrorUpdateCustomer = false;
            state.isLoadingUpdateCustomer = false;
            state.isSuccessDetailCustomer = false;
            state.isErrorDetailCustomer = false;
            state.isLoadingDetailCustomer = false;
            state.isSuccessDetailReseller = false;
            state.isErrorDetailReseller = false;
            state.isLoadingDetailReseller = false;
            state.isSuccessUpdateReseller = false;
            state.isErrorUpdateReseller = false;
            state.isLoadingUpdateReseller = false;
            state.isSuccessDetailSubreseller = false;
            state.isErrorDetailSubreseller = false;
            state.isLoadingDetailSubreseller = false;
            state.isSuccessUpdateSubreseller = false;
            state.isErrorUpdateSubreseller = false;
            state.isLoadingUpdateSubreseller = false;
            state.isSuccessCreateReseller = false;
            state.isErrorCreateReseller = false;
            state.isLoadingCreateReseller = false;
            state.isSuccessCreateSubreseller = false;
            state.isErrorCreateSubreseller = false;
            state.isLoadingCreateSubreseller = false;

            state.messageUpdateCustomer = '';
            state.messageCustomers = '';
            state.messageResellers = '';
            state.messageSubresellers = '';
            state.messageDetailCustomer = '';
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getCustomers.pending, (state) => {
                state.isLoadingCustomers = true;
            })
            .addCase(getCustomers.fulfilled, (state, {payload}) => {
                state.isLoadingCustomers = false;
                state.isSuccessCustomers = true;
                state.customers = payload;
            })
            .addCase(getCustomers.rejected, (state, {payload}) => {
                state.isLoadingCustomers = false;
                state.isErrorCustomers = true;
                state.messageCustomers = payload;
            })
            .addCase(updateCustomers.pending, (state) => {
                state.isLoadingUpdateCustomer = true;
            })
            .addCase(updateCustomers.fulfilled, (state, {payload}) => {
                state.isLoadingUpdateCustomer = false;
                state.isSuccessUpdateCustomer = true;
                state.messageUpdateCustomer = payload;
            })
            .addCase(updateCustomers.rejected, (state, {payload}) => {
                state.isLoadingUpdateCustomer = false;
                state.isErrorUpdateCustomer = true;
                state.messageUpdateCustomer = payload;
            })
            .addCase(detailCustomers.pending, (state) => {
                state.isLoadingDetailCustomer = true;
            })
            .addCase(detailCustomers.fulfilled, (state, {payload}) => {
                state.isLoadingDetailCustomer = false;
                state.isSuccessDetailCustomer = true;
                state.customer = payload;
            })
            .addCase(detailCustomers.rejected, (state, {payload}) => {
                state.isLoadingDetailCustomer = false;
                state.isErrorDetailCustomer = true;
                state.messageDetailCustomer = payload;
            })
            builder
            .addCase(getResellers.pending, (state) => {
                state.isLoadingResellers = true;
            })
            .addCase(getResellers.fulfilled, (state, {payload}) => {
                state.isLoadingResellers = false;
                state.isSuccessResellers = true;
                state.resellers = payload;
            })
            .addCase(getResellers.rejected, (state, {payload}) => {
                state.isLoadingResellers = false;
                state.isErrorResellers = true;
                state.messageResellers = payload;
            })
            .addCase(updateReseller.pending, (state) => {
                state.isLoadingUpdateReseller = true;
            })
            .addCase(updateReseller.fulfilled, (state, {payload}) => {
                state.isLoadingUpdateReseller = false;
                state.isSuccessUpdateReseller = true;
                state.messageResellers = payload;
            })
            .addCase(updateReseller.rejected, (state, {payload}) => {
                state.isLoadingUpdateReseller = false;
                state.isErrorUpdateReseller = true;
                state.messageResellers = payload;
            })
            .addCase(detailReseller.pending, (state) => {
                state.isLoadingDetailReseller = true;
            })
            .addCase(detailReseller.fulfilled, (state, {payload}) => {
                state.isLoadingDetailReseller = false;
                state.isSuccessDetailReseller = true;
                state.reseller = payload;
            })
            .addCase(detailReseller.rejected, (state, {payload}) => {
                state.isLoadingDetailReseller = false;
                state.isErrorDetailReseller = true;
                state.messageResellers = payload;
            })
            .addCase(createReseller.pending, (state) => {
                state.isLoadingCreateReseller = true;
            })
            .addCase(createReseller.fulfilled, (state, {payload}) => {
                state.isLoadingCreateReseller = false;
                state.isSuccessCreateReseller = true;
                state.messageResellers = payload;
            })
            .addCase(createReseller.rejected, (state, {payload}) => {
                state.isLoadingCreateReseller = false;
                state.isErrorCreateReseller = true;
                state.messageResellers = payload;
            })
            builder
            .addCase(getSubresellers.pending, (state) => {
                state.isLoadingSubresellers = true;
            })
            .addCase(getSubresellers.fulfilled, (state, {payload}) => {
                state.isLoadingSubresellers = false;
                state.isSuccessSubresellers = true;
                state.subresellers = payload;
            })
            .addCase(getSubresellers.rejected, (state, {payload}) => {
                state.isLoadingSubresellers = false;
                state.isErrorSubresellers = true;
                state.messageSubresellers = payload;
            })
            .addCase(updateSubreseller.pending, (state) => {
                state.isLoadingUpdateSubreseller = true;
            })
            .addCase(updateSubreseller.fulfilled, (state, {payload}) => {
                state.isLoadingUpdateSubreseller = false;
                state.isSuccessUpdateSubreseller = true;
                state.messageSubresellers = payload;
            })
            .addCase(updateSubreseller.rejected, (state, {payload}) => {
                state.isLoadingUpdateSubreseller = false;
                state.isErrorUpdateSubreseller = true;
                state.messageSubresellers = payload;
            })
            .addCase(detailSubreseller.pending, (state) => {
                state.isLoadingDetailSubreseller = true;
            })
            .addCase(detailSubreseller.fulfilled, (state, {payload}) => {
                state.isLoadingDetailSubreseller = false;
                state.isSuccessDetailSubreseller = true;
                state.subreseller = payload;
            })
            .addCase(detailSubreseller.rejected, (state, {payload}) => {
                state.isLoadingDetailSubreseller = false;
                state.isErrorDetailSubreseller = true;
                state.messageSubresellers = payload;
            })
            .addCase(createSubreseller.pending, (state) => {
                state.isLoadingCreateSubreseller = true;
            })
            .addCase(createSubreseller.fulfilled, (state, {payload}) => {
                state.isLoadingCreateSubreseller = false;
                state.isSuccessCreateSubreseller = true;
                state.messageSubresellers = payload;
            })
            .addCase(createSubreseller.rejected, (state, {payload}) => {
                state.isLoadingCreateSubreseller = false;
                state.isErrorCreateSubreseller = true;
                state.messageSubresellers = payload;
            });
       
    }
})

export const {reset} = usersSlice.actions;
export default usersSlice.reducer;