import Icons from 'components/Icons'
import Feature1Img from 'images/Feature1Img.svg'
import { ReactComponent as Bell } from 'images/icons/bell.svg'
import { ReactComponent as Tick } from 'images/icons/tick.svg'
import Heading from 'components/Typography/Header'
import Paragraph from 'components/Typography/Paragraph'

const Feature1 = () => {
    return (
        <section className='grid grid-cols-12 mt-10 md:mt-0'>
            <div className='md:col-start-1 md:col-end-7 col-start-1 col-end-13 space-y-[30px]'>
                <Icons> <Bell /> </Icons>
                <Heading level={5} className="text-secondary">Akıllı Çözüm</Heading>
                <Heading level={2} className="text-gray-600">Anlık Bildirimler</Heading>
                <Paragraph className="text-black">SkyPay, kullanıcılarına hesaplarında gerçekleşen hareketleri ve diğer işlemleri anında takip etme imkanı sunarak, işlemlerini daha kolay ve hızlı yönetmelerine yardımcı olur.</Paragraph>
                <div className='space-y-[20px]'>
                    <div className='flex gap-[10px] items-center'><Tick /> <Paragraph>Hızlı İşlem Takibi</Paragraph></div>
                    <div className='flex gap-[10px] items-center'><Tick /> <Paragraph>Kolay Kullanım Deneyimi</Paragraph></div>
                </div>
            </div>
            <div className='md:col-start-8 md:col-end-13 col-start-1 mt-8 md:mt-0 col-end-13 flex items-center'>
                <img
                    src={Feature1Img}
                    alt="Picture of the Feature1Img"
                    width={500}
                    height={500}
                />
            </div>
        </section>
    )
}

export default Feature1