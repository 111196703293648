import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

const Heading = ({ level, children, className, ...props }) => {
    const Tag = `h${level}`;

    const classes = classNames(

        {
            'text-[75px] leading-[98.8px] font-bold': level === 1,
            'text-[57px] leading-[74.1px] font-bold': level === 2,
            'text-[43px] leading-[55.9px] font-bold': level === 3,
            'text-[32px] leading-[41.6px] font-semibold': level === 4,
            'text-[24px] leading-[31.2px] font-semibold': level === 5,
            'text-[18px] leading-[23.4px] font-semibold': level === 6,
        },
        className
    );

    return (
        <Tag className={`${classes}`} {...props}>
            {children}
        </Tag>
    );
};



export default Heading;