import React, { useState, useEffect } from 'react';
import { AiOutlineLeft, AiOutlineRight } from 'react-icons/ai'

function PaginationComponent({ currentPage, totalPages, onPageChange }) {
  const [maxVisiblePages, setMaxVisiblePages] = useState(3);

  useEffect(() => {
    const updateMaxVisiblePages = () => {
      if (window.innerWidth >= 1024) {
        setMaxVisiblePages(5);
      } else if (window.innerWidth >= 768) {
        setMaxVisiblePages(4);
      } else {
        setMaxVisiblePages(3);
      }
    };

    updateMaxVisiblePages();
    window.addEventListener('resize', updateMaxVisiblePages);

    return () => {
      window.removeEventListener('resize', updateMaxVisiblePages);
    };
  }, []);

  const handlePageChange = (newPage) => {
    if (newPage < 1 || newPage > totalPages) return;
    console.log(newPage);
    onPageChange(newPage);
  };



  const generatePageNumbers = () => {
    const pageNumbers = [];
    const startPage = Math.max(1, currentPage - Math.floor(maxVisiblePages / 2));
    const endPage = Math.min(totalPages, startPage + maxVisiblePages - 1);

    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(i);
    }

    return pageNumbers;
  };

  const pageNumbers = generatePageNumbers();

  return (
    <div className="flex justify-center items-center max-h-8 mt-4">
      <button

        className="mx-2 bg-primary h-8 secondary text-white-100 font-bold py-2 px-4 rounded "
        onClick={() => handlePageChange(1)}
        disabled={currentPage <= 1}
      >
        <AiOutlineLeft />
      </button>
      {pageNumbers.map((number) => (
        <button
          key={number}
          className={`mx-1 h-8 w-8 py-2 px-4 flex items-center justify-center rounded sm:py-1 sm:px-2 ${currentPage === number
            ? ' border text-white-100'
            : 'bg-primary text-white-100 border border-primary'
            }`}
          onClick={() => handlePageChange(number)}
        >
          {number}
        </button>
      ))}
      {totalPages > maxVisiblePages && (
        <>
          <span className="mx-2  text-white-100">...</span>
          <button
            className="bg-white-100 h-8 text-white-100  border border-primary py-2 px-4 rounded sm:py-1 sm:px-2"
            onClick={() => handlePageChange(totalPages)}
          >
            {totalPages}
          </button>
        </>
      )}
      <button

        className="mx-2 bg-primary h-8  secondary text-white-100 font-bold py-2 px-4 rounded"
        onClick={() => handlePageChange(currentPage + 1)}
        disabled={currentPage >= totalPages}
      >
        <AiOutlineRight />
      </button>
    </div>
  );
}

export default PaginationComponent;
