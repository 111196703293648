import instance from "../../../api/instance";

const orders = async (params) => {
	const response = await instance.get("/admin/orders/list",{
		params:params
	});
	if (response.status === 200) {
		return response.data;
	}
	
	return Promise.reject(response.data);
};

const ordersDetail = async (params) => {
	const response = await instance.get("/admin/orders/list",{
		params:params
	});
	if (response.status === 200) {
		return response.data;
	}
	
	return Promise.reject(response.data);
};

const finalizeOrder = async (data) => {
	const response = await instance.post("/admin/orders/finalize",data);

	if (response.status === 20) {
		return response.data;
	}
	
	return Promise.reject(response.data);
};


const ordersService = {
	orders,
	ordersDetail,
	finalizeOrder
};

export default ordersService;