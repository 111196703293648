import { configureStore } from '@reduxjs/toolkit';


//Admin Reducer
import AdminAuthReducer from './admin/auth/authSlice';
import AdminProfileReducer from './admin/profile/profileSlice'
import AdminOrdersReducer from './admin/orders/ordersSlice'
import AdminUsersReducer from './admin/users/usersSlice'
import AdminPaymentsReducer from './admin/payments/paymentsSlice'
import AdminBankAccountsReducer from './admin/bank-accounts/bankAccountsSlice'
import AdminSettingsReducer from './admin/settings/settingsSlice'
import AdminPayoutsReducer from './admin/payout/payoutSlice'
import AdminDiamondTransferReducer from './admin/diamond-transfer/diamondTransferSlice'
import AdminStatsReducer from './admin/stats/statsSlice'
import AdminDownloadFileReducer from './admin/downloadFile/downloadFileSlice'
import AdminCreateOtpReducer from './admin/createOtp/createOtpSlice';

//Customer Reducer
import CustomerAuthReducer from './customer/auth/customerSlice';
import CustomerProfileReducer from './customer/profile/profileSlice'
import CustomerBankAccountsReducer from './customer/bank-accounts/bankAccountsSlice'
import CustomerSettingsReducer from './customer/settings/settingsSlice'
import CustomerOrdersReducer from './customer/orders/ordersSlice'
import CustomerRegisterReducer from './customer/register/registerSlice'

//Reseller Reducer
import ResellerAuthReducer from './reseller/auth/customerSlice';
import ResellerOrdersReducer from './reseller/orders/ordersSlice'
import ResellerProfileReducer from './reseller/profile/profileSlice'
import ResellerSettingsReducer from './reseller/settings/settingsSlice'
import ResellerSubresellersReducer from './reseller/subresellers/subresellersSlice'
import ResellerPayoutsReducer from './reseller/payout/payoutSlice'
import ResellerPricingReducer from './reseller/pricing/pricingSlice'
import ResellerDiamondTransferReducer from './reseller/diamond-transfer/diamondTransferSlice'
import ResellerTotalSalesReducer from './reseller/total-sales/totalSalesSlice'
import ResellerRetailsReducer from './reseller/retails/retailsSlice'
import ResellerRetailsCheckReducer from './reseller/retails/retailsCheckSlice'

//Subreseller Reducer
import SubresellerAuthReducer from './subreseller/auth/customerSlice';
import SubresellerOrdersReducer from './subreseller/orders/ordersSlice'
import SubresellerProfileReducer from './subreseller/profile/profileSlice'
import SubresellerSettingsReducer from './subreseller/settings/settingsSlice'
import SubresellerTotalSalesReducer from './subreseller/total-sales/totalSalesSlice'



export const store = configureStore({
  reducer: {
    adminCreateOtp:AdminCreateOtpReducer,
    adminAuth: AdminAuthReducer,
    adminProfile: AdminProfileReducer,
    adminOrders: AdminOrdersReducer,
    adminUsers: AdminUsersReducer,
    adminPayments: AdminPaymentsReducer,
    adminBankAccounts: AdminBankAccountsReducer,
    adminSettings: AdminSettingsReducer,
    adminPayouts: AdminPayoutsReducer,
    adminDiamondTransfer: AdminDiamondTransferReducer,
    adminStats: AdminStatsReducer,
    adminDownloadFile:AdminDownloadFileReducer,

    customerAuth: CustomerAuthReducer,
    customerRegister: CustomerRegisterReducer,
    customerProfile: CustomerProfileReducer,
    customerBankAccounts: CustomerBankAccountsReducer,
    customerSettings: CustomerSettingsReducer,
    customerOrders: CustomerOrdersReducer,

    resellerAuth: ResellerAuthReducer,
    resellerOrders: ResellerOrdersReducer,
    resellerProfile: ResellerProfileReducer,
    resellerSettings: ResellerSettingsReducer,
    resellerSubresellers: ResellerSubresellersReducer,
    resellerPayouts: ResellerPayoutsReducer,
    resellerPricing: ResellerPricingReducer,
    resellerDiamondTransfer: ResellerDiamondTransferReducer,
    resellerTotalSales: ResellerTotalSalesReducer,
    resellerRetails:  ResellerRetailsReducer,
    resellerRetailsCheck: ResellerRetailsCheckReducer,

    subresellerAuth: SubresellerAuthReducer,
    subresellerOrders: SubresellerOrdersReducer,
    subresellerProfile: SubresellerProfileReducer,
    subresellerSettings: SubresellerSettingsReducer,
    subresellerTotalSales: SubresellerTotalSalesReducer

  },
});
