import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import Users from "components/Panel/Admin/Users";
import Pagination from "components/Pagination";

import { getCustomers } from "redux/admin/users/usersSlice";

const UsersContainer = () => {
  const dispatch = useDispatch();
  const { data } = useSelector(state => state.adminUsers.customers);
  const { isLoadingCustomers } = useSelector(state => state.adminUsers);
  const { total, pages, page, per_page } = useSelector(state => state.adminUsers.customers);
  useEffect(() => {
    dispatch(getCustomers({ page: 1, per_page: 15 }))
  }, [dispatch])

  const handlePageChange = (newPage) => {
    dispatch(getCustomers({ page: newPage, per_page: 15 }))
  };



  return (
    <div className="bg-black min-h-screen">
      <div className="p-4 flex items-center justify-between">
        <h2 className="text-xl font-semibold text-white-100">Müşteriler</h2>
      </div>
      {
        isLoadingCustomers ? <div className="flex justify-center items-center h-96"><div className="loader"></div></div> : <Users data={data} />
      }
      <Pagination currentPage={page} totalPages={pages} onPageChange={handlePageChange} />
    </div>
  );
};

export default UsersContainer;
