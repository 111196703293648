import React from 'react'
import OrderDetailContainer from 'containers/panel/customer/order-detail-container'


const OrderDetailPage = () => {
  return (
    <OrderDetailContainer/>
  )
}

export default OrderDetailPage