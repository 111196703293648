import React from 'react';
import { motion } from 'framer-motion';


const Popup = ({ children, isOpen, onClose, className }) => {
  if (!isOpen) {
    return null;
  }

  const backdropVariants = {
    open: { opacity: 1 },
    closed: { opacity: 0 }
  };

  const modalVariants = {
    open: { y: 0, opacity: 1 },
    closed: { y: '-100vh', opacity: 0 }
  };

  return (
    <motion.div
      className={`fixed top-0 left-0 right-0 bottom-0 bg-black bg-opacity-70 flex items-center justify-center z-[999] ${className}`}
      initial="closed"
      animate="open"
      exit="closed"
      variants={backdropVariants}
      onClick={onClose}
    >
      <motion.div
        className='rounded-xl p-8 max-w-[80%] relative bg-gray-900 border border-primary popup-shadow'
        initial="closed"
        animate="open"
        exit="closed"
        variants={modalVariants}
        onClick={(e) => e.stopPropagation()}

      >
        {children}
      </motion.div>
    </motion.div>
  );
};

export default Popup;
