import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import diamondTransferService from './diamondTransferService.js';


export const getTransfers = createAsyncThunk('admin/diamond-transfer/list', async (params, { rejectWithValue }) => {
  try {
    const response = await diamondTransferService.get(params);
    return response;
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return rejectWithValue(message);
  }
});

export const finalizeTransfer = createAsyncThunk('admin/diamond-transfer/finalize', async (data, { rejectWithValue }) => {
  try {
    const response = await diamondTransferService.finalize(data);
    return response;
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return rejectWithValue(message);
  }
});



const initialState = {
  transferList: [],
  isLoading: false,
  isError: false,
  isSuccess: false,
  isFinalizeTransferSuccess: false,
  isFinalizeTransferLoading: false,
  isFinalizeTransferError: false,
  message: ''
};

export const diamondTransferSlice = createSlice({
  name: 'adminDiamondTransfer',
  initialState,
  reducers: {
    reset: (state) => {
      state.isError = false;
      state.isSuccess = false;
      state.isLoading = false;
      state.message = '';
      state.isFinalizeTransferError = false;
      state.isFinalizeTransferLoading = false;
      state.isFinalizeTransferSuccess = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getTransfers.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getTransfers.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.transferList = action.payload;
      })
      .addCase(getTransfers.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      });
    builder
      .addCase(finalizeTransfer.pending, (state) => {
        state.isFinalizeTransferLoading = true;
      })
      .addCase(finalizeTransfer.fulfilled, (state, action) => {
        state.isFinalizeTransferLoading = false;
        state.isFinalizeTransferSuccess = true;

      })
      .addCase(finalizeTransfer.rejected, (state, action) => {
        state.isFinalizeTransferLoading = false;
        state.isFinalizeTransferError = true;
        state.isFinalizeTransferSuccess = false;

      });

  }
})

export const { reset } = diamondTransferSlice.actions;
export default diamondTransferSlice.reducer;
