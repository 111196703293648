import React from 'react'
import DiamondTransfersContainer from 'containers/panel/admin/diamond-transfers-container'

const DiamondTransfersPage = () => {
  return (
    <DiamondTransfersContainer />
  )
}

export default DiamondTransfersPage
