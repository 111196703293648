import instance from "api/instance";

const profile = async () => {
	const response = await instance.get("/admin/profile");
	if (response.status === 200) {
		return response.data;
	}
	
	return Promise.reject(response.data);
};

const editProfile = async (data) => {
	const response = await instance.patch("/admin/profile",data);
	if (response.status === 200) {
		return response.data;
	}


	return Promise.reject(response.data);
};



const profileService = {
	profile,
	editProfile
};

export default profileService;