import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import settingsService from './settingsService';

export const settings = createAsyncThunk('customer/settings', async (_, {rejectWithValue}) => {
	try {
		const response = await settingsService.settings();
		return response;
	} catch (error) {
		const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
		return rejectWithValue(message);
	}
});

const initialState = {
	data:[],
    isLoading: false,
    isError: false,
    isSuccess: false,
    message: null,
};

export const settingsSlice = createSlice({
	name: 'customerSettings',
	initialState,
	reducers: {
		reset: (state) => {
			state.isError = false;
			state.isSuccess = false;
			state.isLoading = false;
			
		},
		
	},
	extraReducers: (builder) => {
		builder
			.addCase(settings.pending, (state) => {
				state.isLoading = true;
			})
			.addCase(settings.fulfilled, (state, action) => {
				state.isLoading = false;
				state.isSuccess = true;
				state.data = action.payload;
			})
			.addCase(settings.rejected, (state, action) => {
				state.isLoading = false;
				state.isError = true;
                state.isSuccess = false;
				state.message = action.payload;
				
			})
	}
});

export const { reset } = settingsSlice.actions;

export default settingsSlice.reducer;