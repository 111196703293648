'use client'
import React, { useState, useEffect } from 'react'
import { Bar } from 'react-chartjs-2'
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, Colors } from 'chart.js'

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, Colors);
ChartJS.defaults.color = "#eaecef";

const BarChart = () => {
    const [chartData, setChartData] = useState({
        datasets: [],
    })

    const [chartOptions, setChartOptions] = useState({})

    useEffect(() => {
        setChartData({
            labels: ['Pazartesi', 'Salı', 'Çarşamba', 'Perşembe', 'Cuma', 'Cumartesi', 'Pazar'],
            datasets: [{
                label: 'Satışlar',
                data: [9000, 12000, 5000, 2000, 4000, 4500, 500],
                backgroundColor: 'rgba(26, 190, 111, 1)',
            }],
            legend: {
                labels: {
                    fontSize: 14,
                }
            },
        })
        setChartOptions({
            plugins: {
                legend: {
                    position: 'top',
                },
                title: {
                    display: true,
                    text: 'Haftalık Satış Grafiği',
                },

            },
            maintainAspectRatio: false,
            responsive: true,
        })
    }, [])

    return (
        <>
            <div className='w-full md:col-span-2 relative lg:h-[70vh] h-[50vh] m-auto border-gray-600 rounded-lg bg-gray-900 p-4'>
              {/*
                  <Bar data={chartData} options={chartOptions} />
              */}
            </div>
        </>
    )
}

export default BarChart