import React, { useEffect } from "react";
import Subresellers from "components/Panel/Reseller/Subresellers";
import Button from "components/Button";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getSubresellers } from "redux/reseller/subresellers/subresellersSlice";
import Pagination from "components/Pagination";

const SubresellersContainer = () => {
  const dispatch = useDispatch();
  const { data } = useSelector(
    (state) => state.resellerSubresellers.subresellers
  );
  const { total, pages, page, per_page } = useSelector(
    (state) => state.resellerSubresellers.subresellers
  );
  useEffect(() => {
    dispatch(getSubresellers({ page: 1, per_page: 15, show_inactive: true }));
  }, [dispatch]);

  const handlePageChange = (newPage) => {
    dispatch(
      getSubresellers({ page: newPage, per_page: 15, show_inactive: true })
    );
  };
  return (
    <div className="bg-black min-h-screen">
      <div className="p-4 flex justify-between">
        <h2 className="text-xl font-semibold text-white-100">Alt Bayiler</h2>
        <Link to={"/reseller/subresellers/create"}>
          <Button size="small">Alt Bayi Ekle</Button>
        </Link>
      </div>
      <Subresellers data={data} />
      <Pagination
        currentPage={page}
        totalPages={pages}
        onPageChange={handlePageChange}
      />
    </div>
  );
};

export default SubresellersContainer;
