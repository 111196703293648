import React from 'react'
import Header from 'components/Panel/Customer/Header'
import TopCards from 'components/Panel/Customer/TopCards'
import BankAccounts from 'components/Panel/Customer/BankAccounts'
import RecentOrders from 'components/Panel/Customer/RecentOrders'


const PanelContainer = () => {
    return (
        <>
            <Header />
            <TopCards />
            <div className='p-4 grid  grid-cols-1 gap-4'>
                <RecentOrders />
            </div>
        </>
    )
}

export default PanelContainer

