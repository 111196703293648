import instance from "api/instance";

const profile = async () => {
	const response = await instance.get("/reseller/profile");
	if (response.status === 200) {
		return response.data;
	}
	
	return Promise.reject(response.data);
};


const update = async (data) => {
	const response = await instance.patch("/reseller/profile",data);
	if (response.status === 200) {
		return response.data;
	}
	
	return Promise.reject(response.data);
};



const profileService = {
	profile,
	update
};

export default profileService;