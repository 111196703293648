import React, { useState, useEffect } from 'react';

const CircularCountdown = () => {
  const [seconds, setSeconds] = useState(180);
  const [strokeDashoffset, setStrokeDashoffset] = useState(0);

  useEffect(() => {
    if (seconds <= 0) {
      return;
    }

    const intervalId = setInterval(() => {
      setSeconds((prevSeconds) => prevSeconds - 1);
      setStrokeDashoffset((seconds / 180) * 565.48);
    }, 1000);

    return () => clearInterval(intervalId);
  }, [seconds]);

  return (
    <div className="relative w-11 h-11">
      <svg className="w-full h-full" viewBox="0 0 100 100">
        <circle
          className="text-gray-300 stroke-current"
          strokeWidth="5"
          fill="none"
          cx="50"
          cy="50"
          r="45"
        />
        <circle
          className="text-primary stroke-current"
          strokeWidth="7"
          fill="none"
          strokeLinecap="round"
          cx="50"
          cy="50"
          r="45"
          strokeDasharray="565.48"
          strokeDashoffset={strokeDashoffset}
          transform="rotate(-90 50 50)"
        />
      </svg>
      <div className="absolute inset-0 flex items-center justify-center">
        <p className="text-xs text-white-100">
          {Math.floor(seconds / 60)}:{seconds % 60 < 10 ? '0' : ''}{seconds % 60}
        </p>
      </div>
    </div>
  );
};

export default CircularCountdown;
