import SubresellerDetailContainer from 'containers/panel/admin/subresellers-detail-container'
import React from 'react'


const SubresellerDetailPage = () => {
  return (
    <SubresellerDetailContainer/>
  )
}

export default SubresellerDetailPage