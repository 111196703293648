import instance from "api/instance";

const get = async (params) => {
	const response = await instance.get("/reseller/diamond-transfer-requests/list",{
		params:params
	});
	if (response.status === 200) {
		return response.data;
	}
	
	return Promise.reject(response.data);
};

const create = async (data) => {
	const response = await instance.post("/reseller/diamond-transfer-requests/new",data);

	if (response.status === 201) {
		return response.data;
	}
	
	return Promise.reject(response.data);
};

const diamondTransferService = {
	get,
    create,
};

export default diamondTransferService;