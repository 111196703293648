import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  editProfile,
  profile as profileDispatch,
  reset,
} from "redux/admin/profile/profileSlice";
import { settings, update } from "redux/admin/settings/settingsSlice";
import { toast } from "react-toastify";
import Popup from "components/Popup";

const Settings = () => {
  const dispatch = useDispatch();
  const { profile, isEditSuccess, isEditLoading, isEditError, message } =
    useSelector((state) => state.adminProfile);
  const {
    settingsData,
    isEditLoading: isSettingEditLoading,
    isEditError: isSettingEditError,
    isEditSuccess: isSettingEditSuccess,
    messageEdit,
  } = useSelector((state) => state.adminSettings);

  const [displayName, setDisplayName] = useState();
  const [password, setPassword] = useState();
  const [oldPassword, setOldPassword] = useState();
  const [changeStatus, setChangeStatus] = useState(false);

  const [settingsState, setSettingsState] = useState({});

  useEffect(() => {
    dispatch(settings());
  }, []);

  useEffect(() => {
    let newSettingsState = {};
    settingsData.forEach((item) => {
      newSettingsState[item.key] = {
        value: item.value,
        display_name: item?.display_name,
      };
    });
    setSettingsState(newSettingsState);
  }, [settingsData]);

  useEffect(() => {
    if (isSettingEditError) {
      toast.error(messageEdit);
    }

    if (isSettingEditSuccess) {
      toast.success(messageEdit.message);
    }

    return () => {
      dispatch(reset());
    };
  }, [
    isSettingEditSuccess,

    isSettingEditError,
    messageEdit,
  ]);

  const handleChangeSettings = (e) => {
    setSettingsState({
      ...settingsState,
      [e.target.name]: {
        ...settingsState[e.target.name],
        value: e.target.value,
      },
    });
  };

  const updateSettings = async (e) => {
    e.preventDefault();
    const settings = {};
    for (const key in settingsState) {
        let value = settingsState[key].value;
        if (typeof value === 'string') {
            value = value.replace(/,/g, '.');
        }
        settings[key] = value;
    }
    console.log(settings);
    await dispatch(update({ settings }));
};


  const editProfileHandler = async (e) => {
    e.preventDefault();
    await dispatch(
      editProfile({
        display_name: displayName,
        password,
        old_password: oldPassword,
      })
    );
  };

  useEffect(() => {
    if (isEditError) {
      if (message.password) {
        toast.error(message.password);
      }
      toast.error(message);
      dispatch(reset());
    }

    if (isEditSuccess) {
      toast.success(message);
      dispatch(profileDispatch());
      dispatch(reset());
    }
  }, [isEditSuccess, isEditLoading, isEditError, message, dispatch]);

  useEffect(() => {
    setDisplayName(profile?.display_name);
  }, [dispatch]);

  const changeStatusHandler = () => {
    setChangeStatus((prev) => !prev);
  };

  return (
    <div className="shadow-md rounded px-8 pt-6 pb-8 mb-4">
      <div className="grid gap-4">
        <div className="bg-gray-900 p-4 space-y-4 rounded shadow">
          <h2 className="text-2xl font-semibold text-white-100 mb-4 flex items-center gap-4">
            Kullanıcı Ayarları{" "}
            <button
              onClick={changeStatusHandler}
              className="text-base bg-secondary rounded px-4 py-1"
            >
              Düzenle
            </button>
          </h2>
          <p className="text-white-100">
            <span className="font-semibold">ID:</span>
            <span className="ml-2">{profile.id}</span>
          </p>
          <p className="text-white-100">
            <span className="font-semibold">Kullanıcı Adı:</span>
            <span className="ml-2">{profile.username}</span>
          </p>
          <p className="text-white-100">
            <span className="font-semibold">Görünür Ad:</span>
            <span className="ml-2">{profile?.display_name}</span>
          </p>
        </div>
      </div>
      <div className="bg-gray-900 mt-4 p-4 space-y-4 rounded shadow">
        <h2 className="text-2xl font-semibold text-white-100 mb-4 flex items-center gap-4">
          Genel Ayarlar{" "}
        </h2>
        {settingsState &&
          Object.keys(settingsState).map((key) => (
            <p key={key} className="text-white-100">
              <span className="font-semibold">
                {settingsState[key]?.display_name}:
              </span>
              <input
                type="text"
                disabled={settingsState[key]?.display_name === "1 elmas USD fiyatı" ? true : false}
                name={key}
                value={settingsState[key].value}
                onChange={handleChangeSettings}
                className="ml-2 rounded-md text-black focus:outline-none px-1"
              />
            </p>
          ))}
        <button
          disabled={isSettingEditLoading}
          onClick={updateSettings}
          className="font-semibold text-white-100 bg-secondary rounded px-4 py-1"
        >
          {
            <span>
              {isSettingEditLoading ? "Kaydediliyor..." : "Kaydet"}
            </span>
          }
        </button>
      </div>
      <Popup isOpen={changeStatus} onClose={changeStatusHandler}>
        <div className="bg-gray-900 p-4 space-y-4 rounded shadow flex flex-col justify-center">
          <h2 className="text-white-100 font-semibold text-center text-2xl">
            Bilgilerimi Güncelle
          </h2>

          <div>
            <h3 className="text-xl font-semibold text-white-100 mb-2">
              Görünür Ad
            </h3>
            <input
              type="text"
              value={displayName}
              onChange={(e) => setDisplayName(e.target.value)}
              className="bg-gray-800 w-full rounded px-4 py-2 text-white-100"
            />
          </div>
          <div>
            <h3 className="text-xl font-semibold text-white-100 mb-2">Şifre</h3>
            <input
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              className="bg-gray-800 w-full rounded px-4 py-2 text-white-100"
            />
          </div>
          <div>
            <h3 className="text-xl font-semibold text-white-100 mb-2">
              Eski Şifre
            </h3>
            <input
              type="password"
              value={oldPassword}
              onChange={(e) => setOldPassword(e.target.value)}
              className="bg-gray-800 w-full rounded px-4 py-2 text-white-100"
            />
          </div>
          <button
            onClick={editProfileHandler}
            className="bg-secondary rounded px-4 py-2 text-white-100"
          >
            Kaydet
          </button>
        </div>
      </Popup>
    </div>
  );
};

export default Settings;
