import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";
import { FaInfoCircle } from "react-icons/fa";
import { useCustomSettings } from 'hooks/useCustomSettings'
import { useSelector, useDispatch } from 'react-redux'
import { settings } from "redux/customer/settings/settingsSlice"

const keyTextMapping = {
  customer_diamond_price: "1 Adet Elmas Fiyatı",
  customer_minimum_diamond_amount: "Minimum Satın Alabileceğiniz Elmas Sayısı",
  customer_maximum_diamond_amount: "Maximum Satın Alabileceğiniz Elmas Sayısı",
}

const DiamondCalculator = ({ amount, setAmount, next }) => {

  const [diamondAmount, setDiamondAmount] = useState(0);
  const [priceAmount, setPriceAmount] = useState(0);

  const dispatch = useDispatch();
  console.log(typeof priceAmount);

  useEffect(() => {
    dispatch(settings());
  }, [dispatch])

  const { data } = useSelector(state => state.customerSettings);
  const diamondPrice = useCustomSettings(data, keyTextMapping, 'customer_diamond_price');

  const handleDiamondChange = (e) => {
    const value = parseFloat(e.target.value);
    setDiamondAmount(value);

    if (!isNaN(value) && value > 0) {
      setPriceAmount(value * (diamondPrice.value || 0));
      setAmount(value * (diamondPrice.value || 0));
    } else {
      setPriceAmount(0);
    }
  };

  const handlePriceChange = (e) => {
    const value = parseFloat(e.target.value);
    setPriceAmount(value);

    if (!isNaN(value) && value > 0) {
      setDiamondAmount(Math.round(value / (diamondPrice.value || 0)));
      setAmount(value);
    } else {
      setDiamondAmount(0);
    }
  };


  return (
    <div className="w-full ">

      <motion.div
        className="bg-white bg-gray-900 shadow-lg rounded-lg p-4 md:min-h-screen"
        initial={{ opacity: 0, y: -50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
      >
        <div className="flex gap-4 items-center my-4">
          <span className="text-2xl bg-primary text-white-100  flex items-center justify-center rounded-full w-10 h-10">
            1
          </span>
          <span className="text-white-100 font-semibold text-lg">Elmas Hesapla</span>
        </div>
        <span className="text-gray-200 text-sm ">
          Satın almak istediğiniz elmas miktarını ya da yatırmak istediğiniz tutarı giriniz.
        </span>
        <div className="my-4">
          <label
            className="block text-white-100 text-sm font-bold mb-2"
            htmlFor="diamondAmount"
          >
            Elmas Sayısı
            <FaInfoCircle className="ml-1 inline" size={12} />
          </label>
          <input
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            id="diamondAmount"
            type="number"
            step="1"
            value={diamondAmount}
            onChange={handleDiamondChange}
          />
        </div>
        <div className="mb-4">
          <label
            className="block text-gray-100 text-sm font-bold mb-2"
            htmlFor="priceAmount"
          >
            Tutar (TL)
            <FaInfoCircle className="ml-1 inline" size={12} />
          </label>
          <input
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            id="priceAmount"
            type="number"
            step="0.01"
            value={priceAmount.toFixed(2)}
            onChange={handlePriceChange}
          />
        </div>
        <div className="flex items-center justify-between">

          <button
            className="bg-secondary disabled:bg-blue-400 text-white-100 font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
            type="submit"
            onClick={next}
            disabled={!diamondAmount || !priceAmount}
          >
            Devam Et
          </button>

          <p
            className="text
 text-primary text-sm"
          >
            Toplam Elmas: {diamondAmount || "-"}
            <br />
            Toplam Tutar: {priceAmount ? `${priceAmount.toFixed(2)} TL` : "-"}
          </p>
        </div>
      </motion.div>
    </div>
  );
};

export default DiamondCalculator;
