import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import paymentsService from './paymentsService';


export const getPayments = createAsyncThunk('admin/payments/list', async (params, { rejectWithValue }) => {
  try {
    const response = await paymentsService.payments(params);
    return response;
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return rejectWithValue(message);
  }
});

export const createPayments = createAsyncThunk('admin/payments/create', async (data, { rejectWithValue }) => {
  try {
    const response = await paymentsService.createPayments(data);
    return response;
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return rejectWithValue(message);
  }

});

export const detailPayment = createAsyncThunk('admin/payment/details', async ({ search }, { getState, dispatch, rejectWithValue }) => {
  try {

    const state = getState();
    let payments = state.adminPayments.payments.data;

    if (!payments || payments.length === 0) {
      const response = await dispatch(getPayments({ page: 1, per_page: 100 }));
      payments = response.payload.data;
    }

    const payment = payments.find((customer) => customer.id === search);

    if (!payment) {
      throw new Error(`payment not found for id: ${search}`);
    }

    return payment;
  } catch (error) {
    const message = error.message || error.toString();
    return rejectWithValue(message);
  }
});




const initialState = {
  payments: [],
  payment: {},
  isLoadingPaymentDetail: false,
  isSuccessPaymentDetail: false,
  isErrorPaymentDetail: false,
  isLoadingPayments: false,
  isSuccessPayments: false,
  isErrorPayments: false,
  isLoadingCreatePayments: false,
  isSuccessCreatePayments: false,
  isErrorCreatePayments: false,
  message: '',
};

export const paymentsSlice = createSlice({
  name: 'adminPayments',
  initialState,
  reducers: {
    reset: (state) => {
      state.isLoadingPaymentDetail = false;
      state.isSuccessPaymentDetail = false;
      state.isErrorPaymentDetail = false;
      state.isErrorPayments = false;
      state.isSuccessPayments = false;
      state.isLoadingPayments = false;
      state.isErrorCreatePayments = false;
      state.isSuccessCreatePayments = false;
      state.isLoadingCreatePayments = false;
      state.message = '';
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getPayments.pending, (state) => {
        state.isLoadingPayments = true;
      })
      .addCase(getPayments.fulfilled, (state, action) => {
        state.isLoadingPayments = false;
        state.isSuccessPayments = true;
        state.payments = action.payload;
      })
      .addCase(getPayments.rejected, (state, action) => {
        state.isLoadingPayments = false;
        state.isErrorPayments = true;
        state.message = action.payload;
      });
    builder
      .addCase(createPayments.pending, (state) => {
        state.isLoadingCreatePayments = true;
      })
      .addCase(createPayments.fulfilled, (state, action) => {
        state.isLoadingCreatePayments = false;
        state.isSuccessCreatePayments = true;
        //state.payments = action.payload;
      })
      .addCase(createPayments.rejected, (state, action) => {
        state.isLoadingCreatePayments = false;
        state.isErrorCreatePayments = true;
        state.message = action.payload;
      });

    builder
      .addCase(detailPayment.pending, (state) => {
        state.isLoadingPaymentDetail = true;
      })
      .addCase(detailPayment.fulfilled, (state, action) => {
        state.isLoadingPaymentDetail = false;
        state.isSuccessPaymentDetail = true;
        state.payment = action.payload;
      })
      .addCase(detailPayment.rejected, (state, action) => {
        state.isLoadingPaymentDetail = false;
        state.isErrorPaymentDetail = true;
        state.message = action.payload;
      });

  }
})

export const { reset } = paymentsSlice.actions;
export default paymentsSlice.reducer;
