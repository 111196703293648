import React, { useEffect } from "react";
import OrderIndicator from "components/OrderIndicator";
import { useDispatch, useSelector } from "react-redux";
import { finalizePayouts, reset } from "redux/admin/payout/payoutSlice";
import { MdOutlineDone, MdClear } from "react-icons/md"
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { toast } from "react-toastify";
import "moment/locale/tr";

moment.locale("tr");

const Payouts = ({ data }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {
    isFinalizePayoutSuccess,
    isFinalizePayoutError,
    isFinalizePayoutLoading,
  } = useSelector((state) => state.adminPayouts);
  const handleFinalize = (id, status) => {
    dispatch(finalizePayouts({ payout_id: id, status: status }));
  };

  useEffect(() => {
    if (isFinalizePayoutSuccess) {
      toast.success("Talep Onaylandı");
      setTimeout(() => {
        navigate(0)
      }, 1000)
    }
    if (isFinalizePayoutError) {
      toast.error("Bir Hata Oluştu");
      setTimeout(() => {
        navigate(0)
      }, 1000)
    }
    return () => {
      dispatch(reset());
    };
  }, [dispatch, isFinalizePayoutSuccess, isFinalizePayoutError]);

  return (
    <div className="overflow-x-auto p-4">
      <table className="min-w-full divide-y-2 divide-gray-200 text-sm">
        <thead className="">
          <tr>
            <th className="whitespace-nowrap text-left px-4 py-2 font-medium text-white-100">
              Tutar
            </th>
            <th className="whitespace-nowrap text-left px-4 py-2 font-medium text-white-100">
              Komisyon
            </th>
            <th className="whitespace-nowrap text-left px-4 py-2 font-medium text-white-100">
              Bayi Adı
            </th>
            <th className="whitespace-nowrap text-left px-4 py-2 font-medium text-white-100">
              Ad Soyad
            </th>
            <th className="whitespace-nowrap text-left px-4 py-2 font-medium text-white-100">
              İban
            </th>
            <th className="whitespace-nowrap text-left px-4 py-2 font-medium text-white-100">
              İşlem Tarihi
            </th>
            <th className="whitespace-nowrap text-left px-4 py-2 font-medium text-white-100">
              İşlem Durumu
            </th>
            <th className="whitespace-nowrap text-left px-4 py-2 font-medium text-white-100">
              İşlem
            </th>
          </tr>
        </thead>

        <tbody className="divide-y divide-gray-200">
          {data?.map((data) => (
            <tr key={data.id}>
              <td className="whitespace-nowrap px-4 py-2 font-medium text-white-100">
                {data.amount_try}₺
              </td>
              <td className="whitespace-nowrap px-4 py-2 font-medium text-white-100">
                {data.fee_amount}₺
              </td>
              <td className="whitespace-nowrap px-4 py-2 text-white-100">
                {data.requestor.name}
              </td>
              <td className="whitespace-nowrap px-4 py-2 text-white-100">
                {data.holder_name}
              </td>
              <td className="whitespace-nowrap px-4 py-2 text-white-100">
                {data.iban}
              </td>
              <td className="whitespace-nowrap px-4 py-2 text-white-100">
                {moment(data.created_at).format("LLL")}
              </td>
              <td className="whitespace-nowrap px-4 py-2 text-white-100">
                {data.status === "completed" ? (
                  <OrderIndicator color="green">
                    Aktarım Yapıldı.
                  </OrderIndicator>
                ) : data.status === "pending_transfer" ? (
                  <OrderIndicator color="yellow">
                    Aktarım Bekliyor.
                  </OrderIndicator>
                ) : data.status === "mico_processing" ? (
                  <OrderIndicator color="yellow">
                    Mico yükleniyor..
                  </OrderIndicator>
                ) : data.status === "failed" ? (
                  <OrderIndicator color="red">
                    Aktarım Reddedildi.
                  </OrderIndicator>
                ) : (
                  <OrderIndicator color="red">No Data</OrderIndicator>
                )}
              </td>
              {data.status === "pending_transfer" && (
                <td className="whitespace-nowrap px-4 py-2 text-white-100">
                  <div className="flex gap-2">
                    <span
                      onClick={() => handleFinalize(data.id, "completed")}
                      className="bg-primary text-white rounded-md p-2 flex items-center text-center font-semibold cursor-pointer"
                    >
                      <MdOutlineDone />
                    </span>
                    <span
                      onClick={() => handleFinalize(data.id, "failed")}
                      className="bg-red-500 text-white rounded-md p-2 items-center text-center font-semibold cursor-pointer"
                    >
                      <MdClear />
                    </span>
                  </div>
                </td>
              )}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default Payouts;
