import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import bankAccountsService from './bankAccountsService';

export const getBankAccounts = createAsyncThunk('admin/bankAccounts/list', async (_, { rejectWithValue }) => {
  try {
    const response = await bankAccountsService.accounts();
    return response;
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return rejectWithValue(message);
  }
});
export const createBankAccount = createAsyncThunk('admin/bankAccounts/create', async (data, { rejectWithValue }) => {
  try {
    const response = await bankAccountsService.create(data);
    return response;
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return rejectWithValue(message);
  }
});
export const updateBankAccount = createAsyncThunk('admin/bankAccounts/update', async (data, { rejectWithValue }) => {
  try {
    const response = await bankAccountsService.update(data);
    return response;
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return rejectWithValue(message);
  }
});

export const detailBankAccount = createAsyncThunk('admin/bankAccounts/detail', async ({ search }, { getState, dispatch, rejectWithValue }) => {
  try {
    const state = getState();
    let bankAccounts = state.adminBankAccounts.data;

    if (!bankAccounts || bankAccounts.length === 0) {
      const response = await dispatch(getBankAccounts());
      bankAccounts = response.payload;
    }

    const bankAccount = bankAccounts.find((bankAccount) => bankAccount.id === search);



    if (!bankAccount) {
      throw new Error(`bankAccount not found for id: ${search}`);
    }

    return bankAccount;

  } catch (error) {
    const message = error.message || error.toString();
    return rejectWithValue(message);
  }
});


const initialState = {
  data: [],
  detail: {},
  isDetailLoading: false,
  isDetailSuccess: false,
  isDetailError: false,
  isLoading: false,
  isError: false,
  isSuccess: false,
  isCreateLoading: false,
  isCreateError: false,
  isCreateSuccess: false,
  isUpdateLoading: false,
  isUpdateError: false,
  isUpdateSuccess: false,
  message: null,
};

export const bankAccountsSlice = createSlice({
  name: 'adminBankAccounts',
  initialState,
  reducers: {
    reset: (state) => {
      state.isDetailLoading = false;
      state.isDetailSuccess = false;
      state.isDetailError = false;
      state.isError = false;
      state.isSuccess = false;
      state.isLoading = false;
      state.isCreateError = false;
      state.isCreateSuccess = false;
      state.isCreateLoading = false;
      state.isUpdateError = false;
      state.isUpdateSuccess = false;
      state.isUpdateLoading = false;
      state.message = null;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getBankAccounts.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getBankAccounts.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.data = action.payload;
      })
      .addCase(getBankAccounts.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.payload;

      })
    builder
      .addCase(createBankAccount.pending, (state) => {
        state.isCreateLoading = true;
      })
      .addCase(createBankAccount.fulfilled, (state, action) => {
        state.isCreateLoading = false;
        state.isCreateSuccess = true;
        state.message = action.payload;
      })
      .addCase(createBankAccount.rejected, (state, action) => {
        state.isCreateLoading = false;
        state.isCreateError = true;
        state.isCreateSuccess = false;
        state.message = action.payload;

      })
    builder
      .addCase(updateBankAccount.pending, (state) => {
        state.isUpdateLoading = true;
      })
      .addCase(updateBankAccount.fulfilled, (state, action) => {
        state.isUpdateLoading = false;
        state.isUpdateSuccess = true;
        state.data = action.payload;
      })
      .addCase(updateBankAccount.rejected, (state, action) => {
        state.isUpdateLoading = false;
        state.isUpdateError = true;
        state.isUpdateSuccess = false;
        state.message = action.payload;

      })
    builder
      .addCase(detailBankAccount.pending, (state) => {
        state.isDetailLoading = true;
      })
      .addCase(detailBankAccount.fulfilled, (state, action) => {
        state.isDetailLoading = false;
        state.isDetailSuccess = true;
        state.detail = action.payload;
      })
      .addCase(detailBankAccount.rejected, (state, action) => {
        state.isDetailLoading = false;
        state.isDetailError = true;
        state.isDetailSuccess = false;
        state.message = action.payload;

      })
  }
});

export const { reset } = bankAccountsSlice.actions;

export default bankAccountsSlice.reducer;
