import instance from "../../../api/instance";

const payments = async (params) => {
	const response = await instance.get("/admin/payments",{
		params:params
	});
	if (response.status === 200) {
		return response.data;
	}
	
	return Promise.reject(response.data);
};

const createPayments = async (data) => {
	const response = await instance.post("/admin/payments",data);
	if (response.status === 201) {
		return response.data;
	}
	
	return Promise.reject(response.data);
};


const paymentsService = {
	payments,
	createPayments
};

export default paymentsService;