import React from 'react'
import { Link, Outlet } from 'react-router-dom'
import AuthLogo from 'images/authLogo.svg'
import { useSelector, useDispatch } from "react-redux";
import { Navigate } from "react-router-dom";

const AuthLayout = () => {
  const { user, role } = useSelector((state) => state.adminAuth);



  return (
    <div className="h-full min-h-screen bg-black">
      <div className="w-[94%] max-w-[1110px] h-full min-h-screen flex flex-col md:flex-row py-6  mx-auto">
        <div className="flex justify-center items-center md:w-1/3">
          <img className='w-1/2 md:w-auto' src={AuthLogo} alt="Logo" />
        </div>
        <Outlet />
      </div>
    </div>
  )
}

export default AuthLayout
