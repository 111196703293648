import React from 'react'
import { Helmet } from 'react-helmet'
import CustomerLoginContainer from 'containers/panel/customer/login-container'
const CustomerLoginPage = () => {
  return (
    <>
     <Helmet>
      <title>Bireysel Giriş | SkyPay</title>
    </Helmet>
    <CustomerLoginContainer/>
    </>
  )
}

export default CustomerLoginPage