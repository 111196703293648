import instance from "api/instance";

const get = async (params) => {
  const response = await instance.get("/admin/diamond-transfer-requests/list", {
    params: params
  });
  if (response.status === 200) {
    return response.data;
  }

  return Promise.reject(response.data);
};

const finalize = async (data) => {
  const response = await instance.post("/admin/diamond-transfer-requests/finalize", data);

  if (response.status === 200) {
    return response.data;
  }

  return Promise.reject(response.data);
};

const diamondTransferService = {
  get,

  finalize
};

export default diamondTransferService;
