import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import DiamondTransfers from "components/Panel/Admin/DiamondTransfers";
import { useDispatch, useSelector } from "react-redux";
import { getTransfers } from "redux/admin/diamond-transfer/diamondTransferSlice";
import Pagination from "components/Pagination";
import Button from "components/Button";

const DiamondTransfersContainer = () => {
  const dispatch = useDispatch();
  const { data } = useSelector((state) => state.adminDiamondTransfer.transferList);
  const { total, pages, page, per_page } = useSelector(
    (state) => state.adminDiamondTransfer.transferList
  );
  useEffect(() => {
    dispatch(getTransfers({ page: 1, per_page: 15 }));
  }, [dispatch]);

  const handlePageChange = (newPage) => {
    dispatch(getTransfers({ page: newPage, per_page: 15 }));
  };

  return (
    <div className="bg-black min-h-screen">
      <div className="p-4 flex justify-between">
        <h2 className="text-xl font-semibold text-white-100">
          Elmas Transfer Taleplerim
        </h2>

      </div>
      <DiamondTransfers data={data} />
      <Pagination
        currentPage={page}
        totalPages={pages}
        onPageChange={handlePageChange}
      />
    </div>
  );
};

export default DiamondTransfersContainer;
