import instance from "api/instance";

const sales = async (data) => {
	const response = await instance.get("/subreseller/profile/total-sales",{
        params:data
    });
	if (response.status === 200) {
		return response.data;
	}
	
	return Promise.reject(response.data);
};



const settingsService = {
	sales,
};

export default settingsService;
