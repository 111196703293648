import instance from "api/instance";

const orders = async (params) => {
	const response = await instance.get("/subreseller/orders/list",{
		params:params
	});
	if (response.status === 200) {
		return response.data;
	}
	
	return Promise.reject(response.data);
};

const ordersDetail = async (params) => {
	const response = await instance.get("/subreseller/orders/list",{
		params:params
	});
	if (response.status === 200) {
		return response.data;
	}
	
	return Promise.reject(response.data);
};

const createOrder = async (data) => {
	const response = await instance.post("/subreseller/orders/new",data);

	if (response.status === 201) {
		return response.data;
	}
	
	return Promise.reject(response.data);
};

const finalizeOrder = async (data) => {
	const response = await instance.post("/subreseller/orders/finalize",data);

	if (response.status === 20) {
		return response.data;
	}
	
	return Promise.reject(response.data);
};






const ordersService = {
	orders,
	ordersDetail,
    createOrder,
	finalizeOrder
};

export default ordersService;