import instance from "../../../api/instance";

const customers = async (params) => {
	const response = await instance.get("/admin/customers",{
		params:params
	});
	if (response.status === 200) {
		return response.data;
	}
	
	return Promise.reject(response.data);
};

const updateCustomer = async (data) => {
	const response = await instance.patch("/admin/customers",data);
	if (response.status === 200) {
		return response.data;
	}
	
	return Promise.reject(response.data);
};

const resellers = async (params) => {
	const response = await instance.get("/admin/resellers",{
		params:params
	});
	if (response.status === 200) {
		return response.data;
	}
	
	return Promise.reject(response.data);
};

const updateReseller = async (data) => {
	const response = await instance.patch("/admin/resellers",data);
	if (response.status === 200) {
		return response.data;
	}
	
	return Promise.reject(response.data);
};

const createReseller = async (data) => {
	const response = await instance.post("/admin/resellers",data);
	if (response.status === 201) {
		return response.data;
	}
	
	return Promise.reject(response.data);
};

const subresellers = async (params) => {
	const response = await instance.get("/admin/subresellers",{
		params:params
	});
	if (response.status === 200) {
		return response.data;
	}
	
	return Promise.reject(response.data);
};

const updateSubreseller = async (data) => {
	const response = await instance.patch("/admin/subresellers",data);
	if (response.status === 200) {
		return response.data;
	}
	
	return Promise.reject(response.data);
};

const createSubreseller = async (data) => {
	const response = await instance.post("/admin/subresellers",data);
	if (response.status === 201) {
		return response.data;
	}
	
	return Promise.reject(response.data);
};





const usersService = {
	customers,
	updateCustomer,
	resellers,
    subresellers,
	updateReseller,
	updateSubreseller,
	createSubreseller,
	createReseller
};

export default usersService;