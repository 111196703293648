import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
const Header = () => {
  const { profile } = useSelector((state) => state.subresellerProfile);

  return (
    <header>
      <div className="mx-auto px-4 sm:px-6 sm:py-8 lg:px-8">
        <div className="sm:flex sm:items-center sm:justify-between">
          <div className="text-center sm:text-left">
            <h1 className="text-2xl font-bold text-white-100 sm:text-3xl">
              Hoşgeldin, {profile?.name}
            </h1>
          </div>

          <div className="mt-4 flex flex-col gap-4 sm:mt-0 sm:flex-row sm:items-center">
            <Link

              className="md:hidden block rounded-lg bg-primary px-5 py-3 text-xl font-medium text-white-100 transition hover:bg-indigo-700 focus:outline-none focus:ring"
              to="/subreseller/orders"
            >
              Dekontları Gör
            </Link>
            <Link

              className="block rounded-lg bg-secondary px-5 py-3 text-xl font-medium text-white-100 transition hover:bg-indigo-700 focus:outline-none focus:ring"
              to="/subreseller/orders/create"
            >
              Dekont Gir
            </Link>

          </div>
        </div>
      </div>

    </header>
  );
};

export default Header;
