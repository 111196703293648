import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import settingsService from './settingsService';

export const settings = createAsyncThunk('admin/settings/list', async (_, {rejectWithValue}) => {
	try {
		const response = await settingsService.settings();
		
		return response;
	} catch (error) {
		const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
		return rejectWithValue(message);
	}
});

export const update = createAsyncThunk('admin/settings/update', async (data, {rejectWithValue}) => {
	try {
		const response = await settingsService.update(data);
		return response;
	} catch (error) {
		const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
		return rejectWithValue(message);
	}
});

const initialState = {
	settingsData:[],
    isLoading: false,
    isError: false,
    isSuccess: false,
    message: null,
    isEditLoading:false,
    isEditError:false,
    isEditSuccess:false,
	messageEdit:null,
};

export const settingsSlice = createSlice({
	name: 'adminSettings',
	initialState,
	reducers: {
		reset: (state) => {
			state.isError = false;
			state.isSuccess = false;
			state.isLoading = false;
            state.isEditLoading = false;
            state.isEditError = false;
            state.isEditSuccess = false;
			state.messageEdit = null;
			
		},
		
	},
	extraReducers: (builder) => {
		builder
			.addCase(settings.pending, (state) => {
				state.isLoading = true;
			})
			.addCase(settings.fulfilled, (state, action) => {
				state.isLoading = false;
				state.isSuccess = true;
				state.settingsData = action.payload;
			})
			.addCase(settings.rejected, (state, action) => {
				state.isLoading = false;
				state.isError = true;
                state.isSuccess = false;
				state.message = action.payload;
				
			});

        builder
			.addCase(update.pending, (state) => {
				state.isEditLoading = true;
			})
			.addCase(update.fulfilled, (state, action) => {
				state.isEditLoading = false;
				state.isEditSuccess = true;
				state.messageEdit = action.payload;
			})
			.addCase(update.rejected, (state, action) => {
				state.isEditLoading = false;
				state.isEditError = true;
                state.isEditSuccess = false;
				state.messageEdit = action.payload;
				
			})
	}
});

export const { reset } = settingsSlice.actions;

export default settingsSlice.reducer;