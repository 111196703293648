import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { detailReseller } from "redux/admin/users/usersSlice";
import ResellersDetail from "components/Panel/Admin/ResellerDetail";

const ResellerDetailContainer = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const { reseller: data } = useSelector(
    (state) => state.adminUsers
  );

  useEffect(() => {
    dispatch(detailReseller({ search: id }));
  }, [dispatch, id]);



  return <ResellersDetail data={data} />;
};

export default ResellerDetailContainer;
