import React, {useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import Heading from "components/Typography/Header";
import Button from "components/Button";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import {
  createSubreseller,
  reset,

} from "redux/reseller/subresellers/subresellersSlice";


const SubresellersCreateContainer = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const {
   
    isCreateSubresellerSuccess,
    isCreateSubresellerError,
    isCreateSubresellerLoading,
    message,

  } = useSelector((state) => state.resellerSubresellers);

  const createSubresellersForm = Yup.object().shape({
    phone: Yup.string().required("Telefon numarası gereklidir."),
    name: Yup.string().required("Ad gereklidir."),
  });

  useEffect(() => {
    if (isCreateSubresellerError) {
      toast.error(message);
    }
    if (isCreateSubresellerSuccess) {
      toast.success("Alt bayi başarıyla oluşturuldu.");
      setTimeout(() => {
        navigate("/reseller/subresellers");
      }, 1500);
     
    }
    return () => {
      dispatch(reset());
    };
  }, [isCreateSubresellerSuccess, isCreateSubresellerError, message, dispatch]);


  const handleSubmit = async (values) => {
 
      dispatch(
        createSubreseller({
          phone: values.phone,
          name: values.name,

        })
      );
   
  };

  return (
    <div className="p-4 ">
      <Heading className="text-white-100" level={5}>
        Alt Bayi Ekle
      </Heading>
      <div className="p-4 bg-gray-900 my-4 rounded-xl min-h-screen">
        <div className="gap-4">
          <div className="p-4 bg-black md:h-screen">
            <Formik
              initialValues={{
                phone: "",
                name: "",

              }}
              validationSchema={createSubresellersForm}
              onSubmit={handleSubmit}
            >
              {({ errors, touched, setFieldValue,values }) => {
                return (
                  (
                    <Form className="space-y-4 max-w-[300px]">
                      <div className="">
                        <span className="text-white-100 font-medium">
                          Bayi Adı
                        </span>
                        <Field
                          name="name"
                          type="text"
                          className="w-full font-medium bg-white-100 rounded-lg appearance-none focus:outline-none  p-2 mt-2"
                        />
                        <ErrorMessage
                          name="name"
                          component="div"
                          className="text-red-500"
                        />
                      </div>
                      <div className="">
                        <span className="text-white-100 font-medium">
                         Telefon Numarası
                        </span>
                        <Field
                          name="phone"
                          type="text"
                          className="w-full font-medium bg-white-100 rounded-lg appearance-none focus:outline-none  p-2 mt-2"
                        />
                        <ErrorMessage
                          name="phone"
                          component="div"
                          className="text-red-500"
                        />
                      </div>
                      <Button
                        type="submit"
                        size="small"
                        loading={isCreateSubresellerLoading}
                        className="bg-primary"
                      >
                        Alt Bayi Oluştur
                      </Button>
                    </Form>
                  )
                )
              }}
            </Formik>
           
          </div>
      
        </div>
      </div>
    </div>
  );
};

export default SubresellersCreateContainer;
