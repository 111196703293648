import React from "react";
import TotalDiamondPayoutsContainer from "containers/panel/admin/total-diamondPayouts-container";

const TotalDiamondPayoutsPage = () => {
  return (
    <TotalDiamondPayoutsContainer />
  )
}

export default TotalDiamondPayoutsPage
