import { useMemo } from 'react';

export function useCustomSettings(dataArray, keyTextMapping, targetKey) {
  const result = useMemo(() => {
    const dataObject = dataArray.reduce((obj, item) => {
      if (item.key === targetKey) {
        obj.value = item.value;
        obj.text = keyTextMapping[item.key] || "";
      }
      return obj;
    }, {});

    return dataObject;
  }, [dataArray, keyTextMapping, targetKey]);

  return result;
}