import instance from "api/instance";

const settings = async () => {
	const response = await instance.get("/admin/settings");
	if (response.status === 200) {
		return response.data;
	}
	
	return Promise.reject(response.data);
};

const update = async (data) => {
	const response = await instance.patch("/admin/settings",data);
	if (response.status === 200) {
		return response.data;
	}
	
	return Promise.reject(response.data);
};





const settingsService = {
	settings,
    update
};

export default settingsService;