import React from 'react'
import TotalSalesContainer from 'containers/panel/admin/total-sales-container'

const TotalSales = () => {
  return (
    <TotalSalesContainer />
  )
}

export default TotalSales
