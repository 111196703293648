import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import customerService from "./customerService";
import jwt_decode from "jwt-decode";

export const sendOtp = createAsyncThunk("reseller/auth/sendOtp", async (data, { rejectWithValue }) => {
  try {
    const response = await customerService.login(data);
    return response;
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return rejectWithValue(message);
  }
});

export const loginWithOtp = createAsyncThunk(
  "reseller/auth/loginWithOtp",
  async (data, { dispatch, rejectWithValue }) => {
    try {
      const response = await customerService.login(data);
      dispatch(setRole(jwt_decode(response.access_token).role));
      return response;
    } catch (error) {
      const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
      return rejectWithValue(message);
    }
  }
);

export const logout = createAsyncThunk('reseller/auth/logout', async () => {
  customerService.logout();
});

const user = localStorage.getItem('token');


const initialState = {
  user: user ? user : null,
  role: user ? jwt_decode(user).role : null,
  isError: false,
  isLoading: false,
  isSuccess: false,
  isAuth: localStorage.getItem('token') === user ? true : false,
  isSendOTP: false,
  message: ''
};

const authSlice = createSlice({
  name: "resellerAuth",
  initialState,
  reducers: {
    reset: (state) => {
      state.isError = false;
      state.isSuccess = false;
      state.isSuccessLogout = false;
      state.isLoading = false;
      state.message = '';
    },
    setRole: (state, action) => {
      state.role = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(sendOtp.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(sendOtp.fulfilled, (state, action) => {
        state.isLoading = false;
        state.message = action.payload.message;
        state.isSendOTP = true;
      })
      .addCase(sendOtp.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.isSendOTP = state.message === "Kısa süre içinde zaten bir doğrulama kodu gönderildi, lütfen daha sonra tekrar deneyin" ? true : false;
      })
      .addCase(loginWithOtp.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(loginWithOtp.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isLoggedIn = true;
        state.isSuccess = true;
        state.isSendOTP = false;
        state.user = action.payload;
        state.message = action.payload.message;
      })
      .addCase(loginWithOtp.rejected, (state, action) => {
        state.isLoading = false;
        state.isLoggedIn = false;
        state.isSuccess = false;
        state.isError = true;
      });
  },
});
export const { reset, setRole } = authSlice.actions;
export default authSlice.reducer;
