import BankAccountsContainer from "containers/panel/admin/bank-accounts-container";


const BankAccountsPage = () => {
  return (
    <BankAccountsContainer />
  )
}

export default BankAccountsPage;
