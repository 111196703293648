import instance from "api/instance";

const profile = async () => {
	const response = await instance.get("/customer/profile");
	if (response.status === 200) {
		return response.data;
	}
	
	return Promise.reject(response.data);
};



const profileService = {
	profile,
};

export default profileService;