import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import Heading from "components/Typography/Header";
import Button from "components/Button";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { createPayouts, reset } from "redux/reseller/payout/payoutSlice";
import { profile } from "redux/reseller/profile/profileSlice";
import { useUUID } from "hooks/useUUID";

const CreatePayoutsContainer = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [trigger, setTrigger] = useState(0);
  const uuid = useUUID(trigger);
  const {
    isCreatePayoutSuccess,
    isCreatePayoutError,
    isCreatePayoutLoading,
    message,
  } = useSelector((state) => state.resellerPayouts);

  const { profile: profileData } = useSelector(state => state.resellerProfile)

  const createPayoutForm = Yup.object().shape({
    iban: Yup.string().required("IBAN Zorunludur"),
    holder_name: Yup.string().required("Hesap Sahibi Zorunludur"),
    amount_try: Yup.number().required("Tutar Zorunludur"),
  });

  useEffect(() => {
    dispatch(profile());
  }, [dispatch]);

  useEffect(() => {
    if (isCreatePayoutError) {
      if (message.amount_try) {
        toast.error(message.amount_try);
      }
      if (message.iban) {
        toast.error(message.iban);
      }
      toast.error(message);
    }
    if (isCreatePayoutSuccess) {
      toast.success("Talebiniz Alınmıştır.");
      navigate("/reseller/payouts");
    }
    return () => {
      dispatch(reset());
    };
  }, [isCreatePayoutError, isCreatePayoutSuccess, message, dispatch]);

  const handleSubmit = async (values) => {
    const amount_try_wfee = Math.ceil((values.amount_try || 0) / (1 - (profileData.payout_fee || 100) / 100));
    await dispatch(
      createPayouts({
        iban: values.iban,
        holder_name: values.holder_name,
        amount_try: amount_try_wfee,
        nonce: uuid,
      })
    );
    setTrigger((prev) => prev + 1);
  };


  return (
    <div className="p-4 ">
      <Heading className="text-white-100" level={5}>
        Para Çekme Talebi
      </Heading>
      <div className="p-4 bg-gray-900 my-4 rounded-xl min-h-screen">
        <div className="grid grid-cols-1  gap-4">
          <div className="p-4 bg-black md:h-screen">
            <Formik
              initialValues={{
                iban: "",
                holder_name: "",
                amount_try: "",
              }}
              validationSchema={createPayoutForm}
              onSubmit={handleSubmit}
            >
              {({ errors, touched, setFieldValue, values }) => {
                const amount_try_wfee = Math.ceil((values.amount_try || 0) / (1 - (profileData.payout_fee || 100) / 100));
                return (
                  <Form className="space-y-4 max-w-[350px]">
                    <div className="w-full">
                      <div className="text-white-100 font-medium mb-4">Hesabınızda Bulunan Bakiye: {profileData.balance_try} ₺</div>
                      <span className="text-white-100 font-medium">IBAN</span>
                      <Field
                        name="iban"
                        type="text"
                        className="w-full font-medium bg-white-100 rounded-lg appearance-none focus:outline-none  p-2 mt-2"
                      />
                      <ErrorMessage
                        name="iban"
                        component="div"
                        className="text-red-500"
                      />
                    </div>
                    <div className="w-full">
                      <span className="text-white-100 font-medium">
                        Hesap Sahibi Ad Soyad
                      </span>
                      <Field
                        name="holder_name"
                        type="text"
                        className="w-full font-medium bg-white-100 rounded-lg appearance-none focus:outline-none  p-2 mt-2"
                      />
                      <ErrorMessage
                        name="holder_name"
                        component="div"
                        className="text-red-500"
                      />
                    </div>
                    <div className="w-full">
                      <span className="text-white-100 font-medium">Tutar</span>
                      <Field
                        name="amount_try"
                        type="text"
                        className="w-full font-medium bg-white-100 rounded-lg appearance-none focus:outline-none  p-2 mt-2"
                      />
                      <ErrorMessage
                        name="amount_try"
                        component="div"
                        className="text-red-500"
                      />
                      <div className="space-y-4 flex flex-col mt-4">
                        <span className="text-primary font-medium">Komisyon: %{profileData.payout_fee} ({(amount_try_wfee - values.amount_try).toFixed(0)} ₺)</span>
                        <span className="text-primary font-medium">Komisyon Dahil Toplam: {amount_try_wfee} ₺</span>
                        <span className="text-primary font-medium">İşlem Sonu Bakiyeniz: {profileData.balance_try - amount_try_wfee} ₺</span>
                      </div>
                    </div>

                    <Button
                      type="submit"
                      size="small"
                      loading={isCreatePayoutLoading}
                      className="bg-primary hover:bg-primary-dark text-white-100 rounded-lg w-full py-2 mt-4"
                    >
                      Talep Oluştur
                    </Button>
                  </Form>
                );
              }}
            </Formik>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreatePayoutsContainer;
