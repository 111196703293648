import React,{useEffect} from "react";
import OrderDetail from "components/Panel/Customer/OrderDetail";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { orderDetail} from "redux/reseller/orders/ordersSlice";

const OrderDetailContainer = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const { orderDetail:data } = useSelector((state) => state.resellerOrders);

  useEffect(() => {
    dispatch(orderDetail({search:id}));
  }, [dispatch,id]);

  if (!data || Object.keys(data).length === 0) {
    return <div>Loading...</div>;
  }

  return <OrderDetail data={data} />;
};

export default OrderDetailContainer;

