import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { bankAccounts } from "redux/customer/bank-accounts/bankAccountsSlice";
import BankAccountCard from "components/BankAccountCard";
import { useEffect } from "react";
import Heading from "components/Typography/Header";



const BankAccountsPage = () => {
  const dispatch = useDispatch();
  const { data } = useSelector((state) => state.customerBankAccounts);
  useEffect(() => {
    dispatch(bankAccounts());
  }, [dispatch])
  return (
    <div className="green-container py-4">
      <Heading className="text-white-100 text-center" level={3}>Banka Hesaplarımız</Heading>
      <div className="grid md:grid-cols-3 grid-cols-1  gap-4 mt-5">
        {
          data.map(bankAccounts => (
            <BankAccountCard data={bankAccounts} />
          ))
        }
      </div>
    </div>
  )
}

export default BankAccountsPage;
