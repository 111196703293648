import Heading from "components/Typography/Header";

const KullaniciSozlesmesi = () => {
  return (
    <div className="green-container py-4">
      <Heading className="text-white-100 text-center" level={3}>
        Kullanıcı Sözleşmesi
      </Heading>

      <div className="text-white-100">
       <div className="space-y-4">
   
        <p className="font-semibold">
          Bu Kullanıcı Sözleşmesi, dijital ve elektronik oyun platformlarında
          bulunan dijital ürün ve hizmetlere erişim için sınırlı bir ödeme
          ağında ön ödemeli sanal cüzdan aracılığıyla faaliyet göstermekte olan
          SkyPay Elektronik Ticaret A.Ş. (SkyPay) ile sizin aranızda yapılan
          bir anlaşmadır ve SkyPay altyapısı üzerinden sunulan tüm hizmetler
          için geçerlidir.
        </p>
        <p className="font-semibold text-center">
          Onayınızla beraber, işbu Sözleşme ile düzenlenen tüm şart ve koşulları
          kabul etmiş, bunlara uyacağınızı beyan ve taahhüt etmiş sayılırsınız
        </p>
       </div>
        <ul className="list-decimal space-y-4 mt-4">
          <li className="space-y-2">
            <h2 className="font-semibold">TARAFLAR</h2>
            <p>
              İşbu Çerçeve Sözleşme ("Sözleşme"); aşağıda tanımlı SkyPay ile
              Müşteri arasında akdedilmiştir. İşbu Sözleşme, Müşteri tarafından
              çevrimiçi olarak kabul edilerek yürürlüğe girecek olup, Müşteri,
              SkyPay ödeme hizmetlerinin kullanımının bu Sözleşmede yer alan
              hüküm ve koşullara tabi olduğunu kabul, beyan ve taahhüt eder.
            </p>
            <p></p>
            <p></p>
            <p></p>
            <p>
              Bu Sözleşme kapsamında; 6493 sayılı Ödeme ve Menkul Kıymet
              Mutabakat Sistemleri, Ödeme Hizmetleri ve Elektronik Para
              Kuruluşları Hakkında Kanun uyarınca SkyPay ELEKTRONİK TİCARET
              A.Ş. (“SkyPay”) ile Ödeme Hizmetleri Kullanıcısı ("Müşteri”)
              aşağıda yer verilen hususlarda anlaşmışlardır.{" "}
            </p>
            <p></p>
            <p>
              İşbu Sözleşme kapsamında Müşteri ve SkyPay müştereken
              “Taraflar”, münferiden “Taraf” olarak anılacaktır.{" "}
            </p>
            <p></p>
            <div>
          <table className="mt-4">
            <tbody>
              <tr>
                <td>Unvanı</td>
                <td>:</td>
                <td>SkyPay ELEKTRONİK TİCARET ANONİM ŞİRKETİ</td>
              </tr>
              <tr>
                <td>Adresi</td>
                <td>:</td>
                <td>
                  Orta Mah. Ordu Sk. A Blok No:23A İç Kapı No:21 Kartal /
                  İstanbul
                </td>
              </tr>
              <tr>
                <td>Telefon Numarası</td>
                <td>:</td>
                <td>444 0 371</td>
              </tr>
              <tr>
                <td>İnternet Adresi</td>
                <td>:</td>
                <td></td>
              </tr>
              <tr>
                <td>KEP adresi</td>
                <td>:</td>
                <td></td>
              </tr>
              <tr>
                <td>E-Posta</td>
                <td>:</td>
                <td>info@SkyPay.com.tr</td>
              </tr>
              <tr>
                <td>Mersis / Sicil No</td>
                <td>:</td>
                <td>0411111105400001 / 452862-5</td>
              </tr>
              <tr>
                <td>Vergi Dairesi / No</td>
                <td>:</td>
                <td>Yakacık Vergi Dairesi / 4111111054</td>
              </tr>
            </tbody>
          </table>
        </div>
          </li>
          <li className="space-y-2">
            <h2 className="font-semibold">TANIMLAR</h2>
            <p></p>
            <p>İşbu sözleşmede geçen tanımlar:</p>
            <p>
              6493 sayılı Ödeme ve Menkul Kıymet Mutabakat Sistemleri, Ödeme
              Hizmetleri ve Elektronik Para Kuruluşları Hakkında Kanun
              (“Kanun”);{" "}
            </p>
            <p>
              Ödeme Hizmetleri ve Elektronik Para İhracı ile Ödeme Kuruluşları
              ve Elektronik Para Kuruluşları Hakkında Yönetmelik (“Yönetmelik”){" "}
            </p>
            <p>
              Ödeme Kuruluşları ve Elektronik Para Kuruluşlarının Bilgi
              Sistemlerinin Yönetimine ve Denetimine İlişkin Tebliği
              (“Tebliğ”)’de kullanılan anlamları ifade etmektedir.
            </p>
            <p></p>
            <p>
              Alıcı: Ödeme işlemine konu fonun ulaşması istenen gerçek veya
              tüzel kişiyi,{" "}
            </p>
            <p>
              Destek E-posta Adresi: SkyPay’e ait işbu Sözleşme kapsamında
              belirlenen hak ve yükümlülüklerin yerine getirilmesi esnasında
              iletişimde olmak amacıyla kullanılacak elektronik posta adresini,{" "}
            </p>
            <p>
              Fon: Banknot, madeni para, kaydi para veya Elektronik Para’yı,{" "}
            </p>
            <p>
              Gönderen: Gönderen Hesabı’ndan veya Gönderen Hesabı kullanmaksızın
              Ödeme Emri veren gerçek veya tüzel kişiyi,{" "}
            </p>
            <p>
              Hassas Müşteri Verisi: Ödeme Emri̇’nin verilmesinde veya Müşteri
              kimliğinin doğrulanmasında kullanılan, üçüncü kişiler tarafından
              ele geçirilmesi veya değiştirilmesi halinde dolandırıcılık ya da
              sahte işlem yapılmasına olanak sağlayabilecek kişisel veriler ile
              müşteri güvenlik bilgilerini,{" "}
            </p>
            <p>
              İnternet Sitesi: www. SkyPay.com.tr adresinden erişilebilen
              SkyPay tarafından Müşterilere sunulan hizmetlerin yer aldığı
              internet sitesini,
            </p>
            <p>
              İş Günü: Cumartesi ve Pazar hariç haftanın günlerini ifade
              etmektedir.
            </p>
            <p>
              Kimlik Tanımlayıcı: SkyPay’in her bir Müşteri’sine özgülediği
              sayı, harf veya sembollerden oluşan kombinasyon ile şifreleri,{" "}
            </p>
            <p>
              Müşteri: SkyPay Sitesine üye olarak veya üye olmaksızın erişim
              sağlayarak, Sitede sunulan herhangi bir hizmeti kısmen veya
              tamamen, geçici veya süreklilik arz eden bir şekilde, kendi adına
              veya başka kişi veya kurumların nam ve/veya hesaplarına her ne
              surette olursa olsun edinen veya yararlanan tüm gerçek kişiler,
            </p>
            <p>
              Mevzuat: Kanun, Yönetmelik ve ilgili ikincil düzenlemeler ile Mali
              Suçları Araştırma Kurulu (MASAK)’ın çıkardığı ilgili sair
              düzenlemeleri,{" "}
            </p>
            <p>
              Ödeme Aracı: Ödeme Emri için kullanılan kart, tablet, cep
              telefonu, şifre ve benzeri kişiye özel aracı,{" "}
            </p>
            <p>
              Ödeme Emri: Müşteri’nin Ödeme ’nin gerçekleştirilmesi amacıyla
              SkyPay’e veya Sistem Ortağına verdiği talimatı,{" "}
            </p>
            <p>
              Ödeme İşlemi: Gönderen veya Alıcı’nın talimatı üzerine
              gerçekleştirilen Fon yatırma, aktarma veya çekme faaliyetini,{" "}
            </p>
            <p>
              Ödeme Hizmetleri: 6493 sayılı Kanunun 12. maddesinde belirtilen
              hizmetleri
            </p>
            <p>
              SkyPay Hizmetleri: SkyPay tarafından verilen ödeme
              hizmetleridir.{" "}
            </p>
            <p>
              Sistem Ortağı: SkyPay’ in Ödeme Sistemi ile Ödeme Hizmetlerinin
              gerçekleştirilmesi adına iş birliği yaptığı banka veya diğer
              finansal kurumlar ile bu hizmetlerin tamamlanmasında ilgisi
              bulunan üçüncü kişileri,{" "}
            </p>
            <p>
              Uzaktan İletişim Aracı: Mektup, katalog, telefon, faks, radyo,
              televizyon, elektronik posta mesajı, internet, kısa mesaj
              hizmetleri gibi fiziksel olarak karşı karşıya gelinmeksizin
              sözleşme kurulmasına imkân veren her türlü araç veya ortamı,{" "}
            </p>
            <p>
              Ücret: Müşteri’nin, sunduğu hizmetler karşılığında SkyPay’e
              ödediği ve zaman zaman SkyPay’in önceden Müşteri’yi
              bilgilendirmek şartıyla değişiklik yapabileceği ücret ve
              komisyonları, ifade etmektedir.
            </p>
            <p></p>
            <p>SÖZLEŞMENİN KONUSU ve KAPSAMI</p>
            <p></p>
            <p>
              İşbu sözleşmenin konusu, SkyPay tarafından Müşteri’ye sunulacak
              ödeme hizmetlerinin kapsamının ve bu hizmetlere ilişkin
              Taraflar’ın hak ve yükümlülüklerinin belirlenmesidir.{" "}
            </p>
            <p>
              İşbu sözleşme SkyPay tarafından sunulan ödeme hizmetlerine
              ilişkin hazırlanan tüm sözleşmeler açısından belirleyici çerçeve
              (kullanıcı) sözleşme niteliğindedir.{" "}
            </p>
            <p>
              Bu kapsamda SkyPay, Sözleşme’nin onay/imza tarihini takiben
              ödeme hizmetlerini bizzat Müşteri’ye sunacaktır.
            </p>
            <p>
              SkyPay, Müşterilerin çeşitli ödeme seçenekleri ile para
              yatırarak karşılığında sahip oldukları sanal parayı yalnızca
              dijital ve elektronik oyun sektöründe hizmet sunan üye
              işyerlerinden dijital ürün/hizmetleri satın almak için
              kullandıkları bir sistem olup Müşteriler banka havalesi, EFT, ATM,
              kredi kartı, mobil ödeme veya hızlı ödeme seçenekleri ile bu
              işlemlerini gerçekleştirebilirler. SkyPay tarafından sunulan bu
              hizmetler dijital ortamlarla sınırlı olmak üzere SkyPay’in
              belirlediği herhangi bir ortamdan alınabilir.
            </p>
            <p>
              Müşteri, işbu sözleşe kapsamında sunulacak olan SkyPay ÖDEME
              HİZMETLERİNİ yalnızca dijital ve elektronik oyun sektöründe
              faaliyet gösteren üye işyerlerinden ürün ve/veya hizmet satın
              alınması amacıyla kullanabilir (Örn: oyunlarda ihtiyaç duyulan
              araç veya gereçlerin satın alınması). Bu alan dışında başka
              herhangi bir ürün ve/veya hizmetin alımı için kullanılması
              takas/transfer edilmesi yasaktır.
            </p>
            <p>
              SkyPay, faaliyetlerini Bankacılık Denetleme ve Düzenleme Kurumu
              (BDDK) ve Türkiye Cumhuriyet Merkez 'Bankası (TCMB) tarafından
              getirilen düzenlemeler kapsamında ve Bankalararası Kart Merkezi
              (BKM) ile anlaşmalı olarak yürütmektedir.
            </p>
            <p></p>
          </li>
          <li className="space-y-2">
            <h2 className="font-semibold">SÖZLEŞMENİN KURULMASI</h2>
            <p></p>
            <p>
              İşbu Sözleşme, Müşterinin Site'ye üye olarak veya üye olmaksızın,
              SkyPay’in sunduğu hizmetlerden herhangi birinde hesap bilgisi
              oluşturarak veya oluşturmaksızın, işlem, ziyaret, üyelik vb,
              sürelerine bakılmaksızın Sözleşme’nin dijital ortamda
              onaylanmasından itibaren hukuken Taraftar’ı, bağlayıcı şekilde
              kurulmuş sayılacaktır. SkyPay, Sözleşmenin yürürlüğe girdiği
              sırada sunmakta olduğu hizmeti sözleşmenin devamında değiştirme
              hakkını saklı tutar.
            </p>
            <p>
              İşbu Sözleşme’de kullanılan madde başlıklarına sadece okuma ve
              anlaşılma kolaylığı sağlanması amacıyla yer verilmiştir. Madde
              başlıkları Sözleşme'nin yorumlanmasında dikkate alınmaz ve başka
              herhangi bir hukuki sonuç doğurmaz.
            </p>
            <p></p>
            <p>MÜŞTERİ’NİN HAK ve YÜKÜMLÜLÜKLERİ</p>
            <p></p>
          </li>
          <li className="space-y-2">
            <h2 className="font-semibold">
              MÜŞTERİNİN SUNMASI GEREKEN BİLGİLER
            </h2>
            <p></p>
            <p>
              Müşteriler, işbu Sözleşmeyi kabul etmeye ehil olduklarını,
              SkyPay hizmetlerinden kendi nam ve hesaplarına faydalandıklarını
              ve başkası hesabına hareket etmediklerini kabul ve taahhüt eder.
            </p>
            <p>
              Müşterilerin kendi adlarına ancak başkası hesabına hareket etmesi
              halinde ve/veya ödeme hesabını üçüncü kişiye devretmesi ile
              kimlik, iletişim ve diğer kimlik tespitine konu bilgilerinin
              değişmesi halinde bu durumları 5549 sayılı Kanun dahil ilgili
              mevzuata uygun olarak SkyPay’e yazılı olarak bildireceğini ve
              SkyPay’in işbu bildirime istinaden, kimlik tespit ve/veya bilgi
              temin talebinde bulunulabileceğini kabul eder. Aksi halde SkyPay
              Müşteri nezdinde gerçekleşen yetkisiz, hatalı işlemler, üçüncü
              kişilerin haksız ve hukuka aykırı kullanımı, fraud vb. işlemler
              dahil hiçbir işlemden sorumlu bulunmayacaktır. Sayılı hususlar
              dahil ancak bunlarla sınırlı olmaksızın işbu Sözleşme ve özellikle
              işbu maddenin ihlali halinde SkyPay işbu Sözleşme’yi tazminatsız
              ve tek taraflı olarak fesih etme, Müşteri hesabını, ödeme
              hesabını, ödeme hizmetlerini geçici veya sürekli olarak askıya
              alma hakkına sahip olacaktır.
            </p>
            <p>
              Müşterilerin hizmetlerden faydalanmak için sunmaları gereken
              Kimlik Bilgilerinin ve iletişim bilgilerinin bizzat Müşteri
              tarafından doldurulmuş olması gerekir. Müşteri, yararlanmak
              istediği Hizmetlere ilgili alanlara manuel olarak veri girişi
              yaparak yalnızca kendisi tarafından bilinebilecek bilgileri
              belirtecek ve sunulması beklenilen belgeleri SkyPay’e
              iletecektir. Müşteri, bu bilgi ve belgelerin doğru olmasından
              sorumludur. SkyPay bu bilgi ve belgelerin doğruluğunu
              araştırmakla yükümlü değildir.{" "}
            </p>
            <p>
              Müşteri, Yönetmelik kapsamında Sözleşme’deki hak ve yükümlülükleri
              hakkında bilgilendirildiğini, SkyPay Sistemi üzerinden yapılacak
              hesap açılışı ve/veya Ödeme Aracı temini için SkyPay’e talepte
              bulunduğunu, talep ettiği hizmetlere ilişkin bilgi ve belgeleri
              SkyPay’in belirleyeceği iletişim kanalları ile ibraz etmekle
              yükümlü olduğunu, SkyPay’in kendisinin gerçek veya tüzel kişi
              olmasına her türlü bilgi ve belgeyi talep etme ve bunların nasıl
              iletileceğine karar verme hakkına sahip olduğunu anladığını kabul
              eder.
            </p>
            <p>
              Müşteiriler işbu sözleşme kapsamında bahsi geçen hizmetlerin
              kullanımı amacıyla SkyPay sanal cüzdan Kullanıcı hesabı
              oluştururken veya SkyPay'in sunduğu hizmetlerden dolaylı ve
              dolaysız yararlandıkları esnada kayıt olunan telefon numaralarına
              SkyPay tarafından SMS, Bildirim, Arama yapılacağını kabul etmiş
              sayılırlar. Söz konusu SMS veya aramalar işlem güvenliği,
              değişiklik ve güncellemeler vb. sebeplerle gerçekleştirilebilir.
            </p>
            <p></p>
            <p></p>
            <p></p>
            <p></p>
            <p></p>
            <p></p>
            <p></p>
            <p></p>
            <p></p>
            <p></p>
          </li>
          <li className="space-y-2">
            <h2 className="font-semibold">ÖDEME HİZMETİNE İLİŞKİN HÜKÜMLER</h2>
            <p></p>
            <p>
              GREENAPP, dijital ve elektronik oyun platformlarında bulunan
              dijital ürün ve hizmetlere erişim için sınırlı bir ödeme ağında ön
              ödemeli sanal cüzdan aracılığı ile hizmet sunar.
            </p>
            <p>
              Müşteri, Ödeme Hizmeti’nden işbu Sözleşme’nin akdiyle birlikte
              SkyPay nezdinde oluşturacağı SkyPay hesabı aracılığıyla veya
              hesap oluşturmadan faydalanabilecektir. Ödeme işlemleri ise
              Müşteri adı, şifre bilgileri ve diğer doğrulama yöntemleri ile
              gerçekleştirecektir.
            </p>
            <p>
              Ödeme Hizmetleri cep telefonu, bilgisayar gibi bir cihaz ve/veya
              Uygulama aracılığıyla gerçekleştirilecek olup, Müşteri
              cihaza/Uygulama’ya ait kullanım koşullarında yer alan teknik ve
              diğer özelliklere uyumun sağlanmasının kendi yükümlülüğü olduğunu
              kabul ve beyan eder.{" "}
            </p>
            <p>
              Müşteri, ödeme işlemine ilişkin ödeme emrini ve buna ilişkin
              onayını SkyPay Sistemi üzerinden, ödeme aracı ile veya
              SkyPay’ın kabul etmesi halinde sair kalıcı veri saklayıcıları
              (kısa mesaj, elektronik posta ve benzeri her türlü araç veya
              ortam) aracılığıyla da verebilir.{" "}
            </p>
            <p>
              Bu bağlamda, SkyPay hizmetlerinden yararlanmak için Müşterinin
              kendisine ait bir cep telefonu numarasına sahip olmalıdır.{" "}
            </p>
            <p>
              SkyPay tanımlı operasyonlar ve diğer işlemler için Müşterilerin
              birtakım bilgilerine ihtiyaç duyulabilir. SkyPay hizmetlerinin
              için kimlik doğrulaması yapılması gerektiğinde SkyPay tüm
              Müşteriler için bu doğrulamayı yapmak adına yetkilendirilmiş olup
              bu doğrulama kapsamında Müşterilere yöneltilen sorular ve zaman
              içinde bu soruların değişmesi tamamıyla SkyPay’in insiyatifinde
              bulunmaktadır.{" "}
            </p>
            <p>
              Müşteri, daha kolay bir ödeme yapısını kullanmak adına sahip
              olduğu diğer ödeme araçlarını SkyPay hesabına bağlayabilir. Bu
              hallerde, SkyPay tarafından bazı bilgilerin paylaşılması zorunlu
              kılınabilir.
            </p>
            <p>
              Müşteri, Ödeme Emri’nin SkyPay’e ulaştığı anda Ödeme Emrinin
              geri alınamayacağını kabul eder. Ödeme Emri, SkyPay’e herhangi
              bir İş Günü saat 17:00 sonrasında ulaştığı takdirde, takip eden
              ilk İş Günü ulaşmış sayılacaktır.
            </p>
            <p>
              Ödeme Hizmeti kapsamında SkyPay, Müşteri tarafından yapılan
              ödemelere ilişkin olarak kendisine iletilen bedelleri, aşağıda
              belirtilen onay süreçlerinin tamamlanmasını takiben Alıcı’ya
              aktarmayı taahhüt etmektedir.
            </p>
            <p>
              SkyPay, Ödeme Emrine esas meblağın; Müşteri tarafından Ödeme
              Emrinin verildiği tarihi takip eden azami 2 (iki) İş Günü
              içerisinde Alıcı’nın ödeme hizmeti sağlayıcısına aktarılmasından
              sorumludur. SkyPay bu sorumluluğunu yerine getirmediği takdirde
              ödeme işleminin gerçekleşmemiş veya hatalı gerçekleşmiş kısmını
              gecikmeden Müşterinin Ödeme Hesabına iade edecektir.
            </p>
            <p>
              Müşterinin verdiği Ödeme Emrini yalnızca, Müşteri ile Müşterinin
              Ödeme Hizmeti Sağlayıcısı arasında karşılıklı mutabakat sağlanması
              halinde geri alınabilecektir.
            </p>
            <p>
              SkyPay, Müşterinin geri ödeme talebini aldığı tarihten itibaren
              10 (on) iş günü içinde kabul ederek geri ödemeyi eksiksiz yapacak
              ya da gerekçeleri ile birlikte bu talebi reddettiğini
              bildirecektir. Bu süreç; yukarıda bildirilen geri ödeme talep
              koşullarının döviz kurundan kaynaklı oluştuğu durumlar için
              kanunen geçerli değildir. İleri tarihli ödemeler, talimatlı
              ödemeler gibi ancak bunlarla sınırlı olmamak kaydıyla, Ödeme
              İşlemi’ne ilişkin onayın doğrudan SkyPay’e verilmesi veya
              ödemenin gerçekleştirilmesi gereken tarihten en az 1 (bir) ay önce
              SkyPay’e bildirimde bulunulması hallerinde, Ödeme İşlemi için
              Müşterinin herhangi bir geri ödeme talep etme hakkı
              bulunmamaktadır.
            </p>
            <p>
              SkyPay kendi hizmetleri üzerinden gerçekleştirilecek işlemlere
              ilişkin olarak limit belirleme hakkını haizdir. Müşteri, SkyPay
              tarafından belirlenen işlem limitleri ile bağlı olduğunu, bu
              limitlerin üzerinde işlem gerçekleştiremeyeceğini kabul eder.
              Müşteri, herhangi bir zamanda Sözleşme’de belirlenen yöntem ve
              sürelere uygun şekilde bildirim yaparak işlem limitlerini
              güncelleyebilecektir. Kimlik teyidi yapılmamış Müşteriler için
              İşlem Limitleri 5549 sayılı Kanun ve ikincil düzenlemelerinde
              belirtildiği şekilde olacaktır.
            </p>
            <p>
              SkyPay’in Kanun uyarınca kredi verme faaliyetinde bulunması söz
              konusu olmayıp, Müşteri SkyPay’den bu kapsamda bir talebi
              olmayacağını kabul, beyan ve taahhüt eder.
            </p>
            <p>
              Müşteri, SkyPay hizmetlerini ve Ödeme aracını Yürürlükteki
              Mevzuat ve/veya ahlaka aykırı amaçlarla kullanması ve/veya Kart’ı
              yasa dışı bahis, kumar, internet üzerinden tütün ve tütün
              mamulleri ile alkol ve +18 hizmet, içerik veya diğer yetişkin
              içeriklerin internet üzerinden alımını sağlayan işyerlerinde
              kullanması durumlarında, bu kullanımlardan doğacak tüm hukuki,
              idari ve/veya cezai sorumluluğun kendisinde olduğunu kabul, beyan
              ve taahhüt eder
            </p>
            <p>
              Mal ve/veya hizmetlerin yasalara ve ahlaka uygun olması ve
              eksiksiz ve ayıptan ari bir şekilde, ilgili fatura, irsaliye,
              garanti belgesi, kullanım kılavuzu vb. zorunlu belgelerle birlikte
              teslimi Alıcı’nın sorumluluğundadır. Müşteri, SkyPay aracılığı
              ile satın alınan mal ve/veya hizmetlere ilişkin olarak SkyPay’in
              herhangi bir sorumluluğu bulunmadığını, kabul, beyan ve taahhüt
              eder. Müşteri, Alıcı ile arasında çıkabilecek her türlü yasal ve
              sözleşmesel taleplerini ve oluşursa ihtilaf halinde husumetini
              Alıcıya yöneltecek olup; SkyPay’in bu ilişkiye taraf olmadığını
              ve SkyPay’i işbu yöndeki tüm taleplerden ari kılacağını kabul,
              beyan ve taahhüt eder.{" "}
            </p>
            <p>
              Müşteri, SkyPay’in hizmetlerini yasalara veya ahlaka aykırı
              amaçlarla ve yasalara veya ahlaka aykırı olan mal veya hizmetlerin
              temini amacıyla doğrudan veya dolaylı olarak kullanamaz.
              Müşterinin hukuka veya ahlaka aykırı her türden üçüncü taraf
              program, yazılım veya unsurlarını kullanmamayı, SkyPay
              hizmetleri içeriğine ilişkin hack, hile, bot, hile dosyası, mod ve
              veya otomasyon programları dahil, ancak bunlarla sınırlı
              olmaksızın, içerikle veya içeriğin yazılım unsurlarından herhangi
              bir kısmıyla veya tamamıyla herhangi bir şekilde etkileşime giren
              yetkisiz üçüncü taraf programlarının veya yazılım ve SkyPay
              arasındaki herhangi bir iletişimi engelleyen, taklit eden veya
              yönünü değiştiren veya içerik hakkındaki bilgileri kaydetmek için
              içerik tarafından kullanılan bellek alanlarını okuyarak içerik
              hakkında bilgi toplayan üçüncü taraf programlarını
              kullanmayacağını; aksi durumda SkyPay tarafından yukarıda bahsi
              geçen yaptırımlara tabi tutulabileceğini ve söz konusu eylem
              hukuka aykırı olduğu için hakkında yasal işlem başlatılabileceğini
              bildiğini kabul eder.
            </p>
            <p>
              SkyPay, hizmetlerinin sunulması sırasında ortaya çıkabilecek
              teknik sebeplerin etkisi ile Müşteri hesaplarının ve/veya bu
              hesaplardaki herhangi bir unsurun geçici veya kalıcı şekilde
              kaybolması yahut kullanılmaz duruma gelmesi halinde söz konusu
              durum ve sonuçtan sorumlu tutulamaz.
            </p>
            <p>
              Müşteri, işbu Sözleme kapsamında gerçekleştirdiği her türlü işleme
              ilişkin bilgilerin SkyPay tarafından yasal yükümlülüklerinin
              yerine getirilmesi amacıyla ilgili kurum ve kuruluşlarla
              paylaşılabileceğini ve/veya anonim hale getirilerek SkyPay
              tarafından işlenip kullanılabileceğini kabul ve beyan eder.
              Müşteri, kurum ve kuruluşlar tarafından yürürlükte bulunan
              düzenlemeler ve mevzuat kapsamında talep edilebilecek bilgi ve
              belgeleri derhal SkyPay’e ve/veya ilgili kuruma teslim ve ibraz
              etmeyi kabul, beyan ve taahhüt eder.
            </p>
            <p>
              Müşteri, SkyPay kurumsal web sitesinde yer alan yöntemler ile
              ödeme hizmetlerini gerçekleştirebilmektedir. Müşteri, bu Sözleşme
              kapsamında gerçekleştirilen ödeme hizmetlerinin kapsam ve
              içeriğinin, bu yöntemlerle hizmet sunulmasına devam edilmesi, yeni
              yöntemler eklenmesi veya bazı yöntemlerin kullanılmamasına karar
              verilmesi yetkilerinin münhasıran SkyPay’de olduğunu kabul eder.
            </p>
            <p>
              Müşteri, SkyPay’in hizmetlerinin kullanılabileceği ortamların ve
              SkyPay ile temin edilebilecek mal ve hizmetleri belirlemeye
              yetkilinin münhasıran SkyPay olduğunu kabul eder. Müşteri
              SkyPay ’in bu ortamlar, mal ve hizmetler üzerinde dilediği zaman
              ve bildirimde bulunmasına gerek olmaksızın değişiklik yapma
              yetkisine sahip olduğunu bilir ve bu sebeple SkyPay’den bir
              talep hakkı olmayacağını kabul eder.
            </p>
            <p>
              SkyPay’ın hizmetlerinde erişim sağlanan reklamlar dahil olmak
              üzere üçüncü kişi içeriklerine bağlantı sağlanabilir. Bu
              içeriklere erişim sağlamak veya bunu kullanmamak tamamen
              Müşterinin tasarrufundadır. Bu nevi erişim sağlanan üçüncü kişiye
              ait içeriklerin tabi olduğu kullanım koşulları sahiplerince
              belirlenir. SkyPay, Hizmetleri aracılığıyla erişim sağlanan
              reklamlar dahil üçüncü kişilere ait içeriklerden şorumlu tutulamaz
              ve bu içeriklerin kullanım koşullarına ilişkin hiçbir talep veya
              uyuşmazlık SkyPay’e karşı ileri sürülemez.
            </p>
            <p></p>
          </li>
          <li className="space-y-2">
            <h2 className="font-semibold">
              HATALI VE YETKİSİZ İŞLEMLER İLE GERİ ÖDEME
            </h2>
            <p></p>
          </li>
          <li className="space-y-2">
            <h2 className="font-semibold">HATALI VE YETKİSİZ İŞLEMLER</h2>
            <p></p>
            <p>
              Müşteri; ödeme hesabını, ödeme aracını ve şifresini, hassas ödeme
              verilerini güvenli bir şekilde korumakla ve bu bilgilerin üçüncü
              kişiler tarafından kullanılmasına engel olacak tüm önlemleri
              almakla yükümlüdür. Müşteri bilgilerinin ve/veya Ödeme aracının
              hileli ve yetkisiz kullanım şüphesi doğuran bir olayda
              kullanılması, kaybolması, çalınması ve ödeme aracıyla iradesi
              dışında işlem gerçekleştiğini öğrenmesi halinde derhal SkyPay
              Çağrı Merkezi’ne veya destek elektronik posta adresine bildirmek
              suretiyle ödeme aracının kullanıma kapatılması için başvuru
              yapmakla yükümlüdür.{" "}
            </p>
            <p>
              Müşteri derhal ve her halükârda ödeme işleminin
              gerçekleştirilmesini takip eden 13 (on üç) ay içerisinde yetkisiz
              veya hatalı işlem bildiriminde bulunarak ilgili işlemin
              düzeltilmesini talep edebilir. Müşterinin bu talebinin SkyPay
              tarafından uygun bulunması halinde ilgili işlem bedeli Alıcı’ya
              aktarılmayarak doğrudan Müşteriye iade edilecek, bildirim anında
              işlem bedeli Alıcı’ya aktarılmış ise SkyPay ilgili tutarların
              Alıcı’dan iadesini talep ederek, ödeme işleminin Alıcı tarafından
              iade edilen tutarının Müşteriye aktarılmasını sağlayacaktır.
            </p>
            <p>
              Ödeme aracının ve/veya şifre bilgileri gibi kişisel güvenlik
              bilgilerinin kaybolması, çalınması veya üçüncü kişiler tarafından
              Müşteri’nin iradesi dışında kullanılması halinde Müşteri, Madde
              5.1.1.’de düzenlenen bildirim yükümlülüğünü yerine getirmezse,
              yetkilendirmediği ödeme işleminden doğan zararın 250 TL
              (ikiyüzelliTürk Lirası) tutarındaki kısmından sorumlu olacak,
              bildirimden sonra gerçekleşen yetkisiz Ödeme İşlemlerinden sorumlu
              olmayacaktır.
            </p>
            <p>
              Müşteri’nin Ödeme Aracı’nı hileli kullanması ve işbu sözleşmeden
              doğan yükümlülüklerini kasten ya da ağır ihmalle yerine
              getirmemesi hallerinde, herhangi bir süre ve ücret ile sınırlı
              olmaksızın yetkilendirilmemiş işlemden doğan zararın ve işbu
              yetkisiz işleme dair tazminat taleplerinin tamamından Müşteri
              sorumlu olacaktır.
            </p>
            <p>
              Alıcı ve/veya Alıcı’nın Ödeme Hizmeti Sağlayıcısı ve/veya SkyPay
              tarafından, Ödeme Aracı’nı kullanan kişinin yetkili kişi olmadığı
              veya ilgili kişinin işlemin gerçekleşmesi için rızasının
              bulunmadığı ancak bununla sınırlı olmamak üzere herhangi bir
              yetkisiz işlemin varlığına ilişkin şüphe/şüphelerinin oluşması
              halinde; Müşteri’nin yetkilendirmelerini inceleyerek işbu şüphenin
              varlığını teyit eder verilerin tespiti halinde ödemeyi kabul
              etmeme hakkını saklı tutar. Müşteri bu durumda herhangi bir talep
              hakkının söz konusu olmayacağını kabul, beyan ve taahhüt eder.
            </p>
            <p></p>
          </li>
          <li className="space-y-2">
            <h2 className="font-semibold">
              ÖDEME İŞLEMİNİN GERÇEKLEŞTİRİLMEMESİ VEYA HATALI
              GERÇEKLEŞTİRİLMESİ
            </h2>
            <p></p>
            <p>
              SkyPay, Müşterinin Ödeme Emrinin doğru bir şekilde
              gerçekleştirilmesinden Müşteriye karşı sorumludur. Alıcı’nın Ödeme
              Tutarını aldığı hususunun SkyPay tarafından ispatlanması
              halinde, SkyPay hiçbir surette ödeme işleminin doğru
              gerçekleştirilmemesinden sorumlu tutulamayacaktır.
            </p>
            <p>
              İade; Üye işyerleri tarafından herhangi bir sebep yüzünden iptal
              edilen veya reddedilen bir ödeme bakiyenize ya da kullandığınız
              ödeme yönteminize/aracınıza iade edilmesidir. İade, Üye İşyerinin
              iade politikalarına bağlıdır. İlgili tutar direkt olarak
              Müşterinin SkyPay hesabına yüklenir. SkyPay tarafından derhal
              Müşteriye aktarılır.
            </p>
            <p>
              SkyPay ödeme işleminin gerçekleşmemiş veya hatalı gerçekleşmiş
              kısmını gecikmeksizin Müşteriye iade eder ve tutarın Ödeme
              Hesabı’ndan düşülmüş olması halinde Ödeme Hesabı’nı eski durumuna
              getirir.
            </p>
            <p>
              Müşteri tarafından Ödeme İşlemi gerçekleştirilmesine rağmen ödeme
              tutarının Sistem Ortağı tarafından SkyPay’e iletilmemesi halinde
              Ödeme İşlemi “başarısız” kabul edilecektir.
            </p>
            <p>
              Herhangi bir Ödeme İşlemi’nin kart hamilinin bilgisi veya rızası
              haricinde yapıldığına yönelik Sistem Ortağı’ndan bilgi alınması
              veya şüphe bulunması durumunda SkyPay tarafından ilgili Ödeme
              İşlemi iptal edilebilecek, ödenen tutara blokaj konulabilecek veya
              ödenen tutar iade edilebilecektir.
            </p>
            <p>
              SkyPay, Müşterinin temin ettiği ürün/hizmetin arıza, hatalı ya
              da eksik olmasından kesinlikle sorumlu değildir. İşbu maddede
              belirtilen prosedür SkyPay hizmetleri ile ilgili olup SkyPay
              sistemlerini kullanmadığını iddia eden kişiler ve SkyPay
              sistemleri nedeni ile eksik ya da hatalı işlem olduğunu iddia eden
              Müşteriler için geçerlidir.
            </p>
            <p></p>
          </li>
          <li className="space-y-2">
            <h2 className="font-semibold">
              HARCAMA İTİRAZI HALİNDE SORUMLULUK
            </h2>
            <p></p>
            <p>
              Müşteri tarafından bir banka veya kredi kartı Ödeme İşlemi
              yapılması üzerine gerçekleşen işlemlere dair söz konusu kartların
              hamilleri tarafından ilgili kart Banka’sına Harcama İtirazı
              iletilebilir.
            </p>
            <p>
              Harcama İtirazı iletilmesi ile ilgili banka, kredi kartı veya ön
              ödemeli kart hamilinin geri ödeme talep etmesi nedeniyle
              SkyPay’in bir üçüncü şahsa veya kart hamiline geri ödeme veya
              zarar tazmininde bulunması veya ilgili idari kurumlar nezdinde
              idari yaptırıma tabi olması veya Sistem Ortağı nezdinde
              sözleşmesel yaptırıma tabi olması halinde uğrayacağı tüm zarar ve
              ziyan Müşteriye rücu edilebilecektir. Müşteri tarafından
              SkyPay’in ilk talebine istinaden tüm ferileri ile birlikte
              ödenecektir. SkyPay’in bahsi geçen durumlarda ilgili bedelleri
              Müşterinin Ödeme Hesabı’ndan mahsup etme hakkı saklıdır.
            </p>
            <p>
              Bu durumda SkyPay tarafından geri ödeme taleplerinin
              değerlendirilmesi 45 (kırk beş̧) ile 180 (yüzseksen) gün arası
              sürebilir. Belirtilen işbu süreler; Uluslararası kart
              kuruluşlarının kuralları ve prosedürleri ile işleme ilişkin ürün
              türü, işlemin gerçekleştirilme yeri ve şekli, ilgili banka kartı
              veya kredi kartının işlemde fiziksel olarak kullanılıp
              kullanılmadığına göre değişkenlik gösterebilecektir.{" "}
            </p>
            <p></p>
          </li>
          <li className="space-y-2">
            <h2 className="font-semibold">ŞİKAYETLERİN BİLDİRİMİ VE ÇÖZÜM</h2>
            <p></p>
            <p>
              Müşteri, SkyPay tarafından sağlanan hizmetlere ilişkin
              şikâyetlerini ad-soyad bilgisiyle SkyPay Hesabı’na ait Müşteri
              numarası ile aşağıda belirtilen şekilde SkyPay’e başvurabilir.
              Sözleşme’nin kapsamına giren konulara ilişkin tüm şikâyet ve
              itirazları SkyPay’in 444 0 371. numaralı Çağrı Merkezi’ne ulaşarak
              veya info@SkyPay.com.tr adresine e-posta yolu ile iletebilecektir.
              Müşteriler, SkyPay’in sunduğu hizmetlerden kaynaklı olarak
              Müşterinin yaşadığı, bireysel nitelik taşıyan, dolayısıyla ticari
              faaliyet kapsamına girmeyen ihtilaf konularında SkyPay,
              uyuşmazlığa konu işlem veya eylemin gerçekleştiği tarihten
              itibaren en geç 2 (iki) yıl içerisinde yazılı başvuru
              gerçekleştirebilirler. Başvurular, başvuru tarihinden itibaren 20
              (yirmi) gün içerisinde; yanıtlanmamışsa yanıt verilmesi gereken 20
              (yirmi) günlük sürenin bitiminden itibaren, Müşterinin başvurusu
              olumsuz yanıtlanmış veya verilen yanıt Müşteri tarafından yeterli
              bulunmaz ise, Müşterinin yanıt tarihinden itibaren 60 (altmış) gün
              içerisinde Türkiye Ödeme ve Elektronik Para Kuruluşları
              Birliği(“TÖDEB”) Bireysel Müşteri Hakem Heyetine İlişkin Esas ve
              Usulleri Hakkında Yönetmelik uyarınca konuyla ilgili TÖDEB
              Bireysel Müşteri Hakem Heyeti’ne internet sitesi başvurma hakkı
              bulunmaktadır. Ayrıca Müşterilerin SkyPay tarafından sağlanan
              hizmetlere ilişkin şikayetleri yönünden İl/İlçe Tüketici Hakem
              Heyetleri’ne başvuru hakkı saklıdır.
            </p>
            <p></p>
            <p>SkyPay’in HAK VE YÜKÜMLÜLÜKLERİ </p>
            <p></p>
            <p>
              SkyPay, işbu Sözleşme ve Hizmet koşullarını dilediği zaman
              herhangi bir ön bildirimde bulunmaksızın değiştirme ve güncelleme
              hakkına sahiptir. Söz konusu değişiklikler, SkyPay tarafından
              yapılmış olması veya mevzuat kaynaklı olması fark etmeksizin tüm
              değişiklikler www.SkyPay.com.tr adresinde yayınlanacak olup Müşteri
              değişiklik tarihinden itibaren 30 gün içerisinde sözleşmeyi
              feshedebilir. Müşterinin 30 (otuz) günlük süre içinde fesih
              hakkını yazılı bir bildirim ile kullanmaması halinde veya SkyPay
              hizmetlerini kullanmaya devam etmesi halinde değişiklik kabul
              edilmiş sayılacaktır
            </p>
            <p>
              SkyPay, SkyPay Sistemi’ni şifreleme algoritmaları dahil en
              güncel teknik bilgiye karşılık gelen yüksek güvenlik standartları
              ile donatmak için çaba gösterecektir.{" "}
            </p>
            <p>
              SkyPay, SkyPay Sistemi’nin düzgün olarak çalışması için
              sistemi geçici olarak durdurabilir veya sınırlandırabilir. Ancak
              SkyPay, üçüncü kişilerden kaynaklanan sorunlar nedeniyle
              SkyPay Hizmetleri’nin zamanında gerçekleşmemesinden sorumlu
              tutulamaz.{" "}
            </p>
            <p>
              SkyPay, işbu Sözleşme kapsamında Müşteriye yapacağı bildirimleri
              Müşterinin bildirdiği e-posta, cep telefonuna göndererek veya
              çağrı merkezi aracılığı ile Müşteriyi arayarak iletebilecektir.
              Müşteri, Sözleşme kapsamındaki bilgilendirmelere ek bilgilendirme
              talebinde bulunduğu takdirde, bu talep SkyPay tarafından kabul
              edildiği takdirde Ücret ile yerine getirilecektir. Müşteri,
              dilediği zamanda işbu Sözleşme’nin bir örneğine www.SkyPay.com.tr sitesinden
              ulaşabilir.{" "}
            </p>
            <p>
              SkyPay, mevzuata, regülasyona, Uluslararası Kart Kuruluşları
              kurallarına ve SkyPay politikalarına aykırılık ya da ulusal ve
              uluslararası yaptırım listelerinde eşleşme hallerinde veya bu
              hallerin varlığına ilişkin kuvvetli bir şüphenin bulunması halinde
              Müşteriyi SkyPay hizmetlerinin tümünden her koşulda, her zaman
              ve herhangi bir bildirim ve ihtarda bulunmaksızın men edebilir.
              Böyle bir durumda SkyPay Müşteriye ilişkin kişisel verileri
              yasal merciler ile paylaşabilir.
            </p>
            <p>
              SkyPay, Müşteri’nin ürün ve/veya hizmeti satın alması esnasında
              ödeme işlemine aracılık etmektedir. Bu nedenle SkyPay, satışa
              arz edilen ürün ve/veya hizmetlerin sahibi veya satıcısı değildir.
              SkyPay, Müşteri tarafından sağlanan içeriklerin ve satışa
              sunulan ürün ve hizmetlerin doğruluğu, kalitesi, güvenliği veya bu
              ürün ve/veya hizmetlerin yasal olup olmadığı hususunda herhangi
              bir beyan, garanti veya taahhütte bulunmamaktadır. Bu sebeple,
              SkyPay’in belirtilen hususlarda herhangi bir sorumluluğu,
              yükümlülüğü ve tazmin yükümlülüğü bulunmaktadır.
            </p>
            <p></p>
          </li>
          <li className="space-y-2">
            <h2 className="font-semibold">
              ÖDEMENİN GERÇEKŞTİRİLECEĞİ PARA BİRİMİ
            </h2>
            <p></p>
            <p>
              SkyPay tarafından Müşterilere sunulan ve işbu Sözleşme’de
              düzenlenen ödeme işlemlerine konu hizmetler, Ödeme Aracı’nın izin
              verdiği para birimleri ile gerçekleştirilebilir. Bu bağlamda,
              Müşterilerin ödeme hizmetlerini gerçekleştirmesinde kullandıkları
              para birimlerine ilişkin, Türk Parası Kıymetini Koruma Hakkında 32
              sayılı Karar’da belirlenen düzenlemeler esastır. Ödeme
              hizmetleriyle ilgili olarak uygulanacak döviz kuru veya referans
              döviz kuru ya da referans döviz kurunu hesaplama yöntemine ilişkin
              olarak bilgi SkyPay tarafından verilecektir. Referans döviz
              kurundaki değişiklikler derhal geçerli olacak olup, Müşteri lehine
              olan referans döviz kuru değişiklikleri bildirim yapılmaksızın
              derhal uygulanacaktır.
            </p>
            <p></p>
          </li>
          <li className="space-y-2">
            <h2 className="font-semibold">ÜCRETLER</h2>
            <p></p>
            <p>
              Müşteri, SkyPay’in hizmetleri ve bu hizmetlere bağlı olarak işbu
              Sözleşmede yer alan her türlü işlemi karşılığında detayları
              İnternet Sitesi’nde yer alan ücrete hak kazandığını, SkyPay’in,
              Sisteminde güncelleme yapmaya ve Müşteri’yi bilgilendirmek
              şartıyla, İnternet Sitesinde yer alan Ücret’e ilişkin değişiklik
              yapma hakkına sahip olduğunu kabul eder. Müşteri, söz konusu
              değişiklik nedeniyle Sözleşmeyi feshetmediği takdirde söz konusu
              hizmetlerden yararlanabilmek için ücret ödeme yükümlülüğü
              altındadır.
            </p>
            <p>
              SkyPay’ın mevzuat kapsamında yapması gereken bilgilendirmelere
              ek olarak müşterinin ek bilgi, daha sık bilgilendirme veya
              bilgilendirmenin işbu Sözleşme’de öngörülenden farklı yöntemlerle
              iletilmesini talep etmesi halinde, Web Sitesi’nde belirtilen
              ücretlendirme tarifesi uygulanır.{" "}
            </p>
            <p>
              SkyPay, ödeme emrinin haklı sebeple reddi durumunda Müşteri’ye
              yapacağı bildirim, ödeme emrinin geri alınmasının talep edilmesi
              hali, hatalı ödeme işlemine konu fonları geri alınması ve sair
              durumlar için ücret talep etme hakkını saklı tutar.
            </p>
            <p>
              Müşteri, SkyPay hizmetleri yoluyla Alıcı’dan satın aldığı mal ve
              hizmetlere ilişkin ücretler ve bu ücretlerin tahsilatına ilişkin
              koşulların Alıcı tarafından belirlendiğini ve bu hususta
              SkyPay’in herhangi bir yetki veya sorumluluğunun söz konusu
              olmadığını kabul, beyan ve taahhüt eder. İşbu Sözleşme’ye konu
              ücret ödemeleri, Türk Lirası cinsinden yapılacaktır.
            </p>
            <p></p>
          </li>
          <li className="space-y-2">
            <h2 className="font-semibold">FİKRİ MÜLKİYET</h2>
            <p></p>
            <p>
              SkyPay’e ait internet sitesi ve mobil uygulamalar başta olmak
              üzere SkyPay Hizmetlerine ilişkin diğer tüm URL’ler, logolar ve
              ticari markalar SkyPay’in tescilli ticari markalarıdır. Müşteri,
              SkyPay önceden yazılı izni olmadan bunları kopyalayamaz, taklit
              edemez veya kullanamaz. Ayrıca tüm sayfa üst bilgileri, özel
              grafikler, düğme simgeleri ve yazılar SkyPay’in hizmet
              markaları, ticari markaları ve/veya ticari sunuş tarzıdır.
              Müşteri, SkyPay’in önceden yazılı izni olmadan bunları
              kopyalayamaz, taklit edemez veya kullanamaz. SkyPay web
              sitesindeki ve SkyPay web sitesine ilişkin tüm haklar, unvanlar
              ve menfaatler, buradaki herhangi bir içerik, SkyPay Hizmetleri,
              SkyPay Hizmetlerine ilişkin teknoloji ve yukarıda
              bahsedilenlerden oluşturulan ya da türetilen herhangi bir
              teknoloji ya da tüm teknolojiler ve içerikler SkyPay’in özel
              mülkiyetindedir. SkyPay, bunlar üzerinde münhasıran hak
              sahibidir.
            </p>
            <p></p>
          </li>
          <li className="space-y-2">
            <h2 className="font-semibold">KİŞİSEL VERİLERİN KORUNMASI</h2>
            <p></p>
            <p>
              Müşteri, SkyPay’e ait internet sitesi, ödeme formu, işyeri, ofis
              dahil olmak üzere sözlü, yazılı, fiziki veya elektronik yollarla
              ve ortamlarda paylaştığı kişisel verilerin www.SkyPay.com.tr/kvkk adresinde
              yer alan Kişisel Verilerin Korunması ve İşlenmesi Politikası’na
              uygun olarak toplandığını, işlendiğini, muhafaza ve yok edildiğini
              kabul ve beyan eder.{" "}
            </p>
            <p></p>
          </li>
          <li className="space-y-2">
            <h2 className="font-semibold">
              HATA VE ZARAR NEDENİYLE SORUMLULUK
            </h2>
            <p></p>
            <p>
              SkyPay, sadece işbu Sözleşme’den kaynaklanan yükümlülüklerinin
              ihlali nedeniyle meydana gelen doğrudan zararlardan sorumlu olup,
              kendi kusurunun bulunmadığı üçüncü şahıs veya Müşteri tarafından
              neden olunan ancak bunlarla sınırlı olmayan sair zararlardan
              sorumlu olmayacaktır. SkyPay, kendisi tarafından öngörülemeyen
              veya engellenemeyen durumlar neticesinde meydana gelen
              gecikmelerden, ödeme işleminin gerçekleştirilememesinden veya
              ödeme işleminde kendi kusuru dışında meydana gelen
              sorunlardan/eksiklerden veya bunların sonucunda meydana gelen
              zararlardan sorumlu olmayacaktır.
            </p>
            <p></p>
          </li>
          <li className="space-y-2">
            <h2 className="font-semibold">SÖZLEŞMENİN SÜRESİ VE FESHİ</h2>
            <p></p>
            <p>
              Sözleşme Taraflarca imzalandığı/onaylandığı tarihte yürürlüğe
              girer ve feshedilmediği sürece yürürlükte kalır.
            </p>
            <p>
              İşbu Sözleşme, her zaman Taraflar’ın karşılıklı yazılı mutabakatı
              ile feshedilebilir.{" "}
            </p>
            <p>
              SkyPay, herhangi bir zamanda 2 (iki) ay öncesinde yapacağı
              yazılı bildirimle; Müşteri ise, 1 (bir) ay öncesinde yapacağı
              yazılı bildirimle ve aynı zamanda ödeme aracını iade edip
              borçlarını fer’ileriyle birlikte ödeyerek Sözleşme’yi herhangi bir
              tazminat ödemeksizin feshedebilir.{" "}
            </p>
            <p>
              Taraflardan birinin sözleşmesel yükümlülüklerini ihlal etmesi ve
              ihlalin düzeltilmesine ilişkin olarak karşı Tarafça iletilen
              yazılı talebe rağmen ilgili ihlalin 1 (bir) hafta içerisinde
              düzeltilmemesi halinde, işbu Sözleşme yazılı talep gönderen
              Tarafça yazılı olarak iletilecek bir ihbarla derhal tazminat
              ödemeksizin feshedilebilecektir.
            </p>
            <p>
              Ödeme hizmetlerinin Müşteri tarafından mevzuat ve ahlaka aykırı
              amaçlarla ve/veya mevzuat ve ahlaka aykırı mal ve hizmetlerin
              temini için kullanıldığının tespiti halinde SkyPay işbu
              Sözleşme’yi derhal feshedebilir. Ayrıca, Müşteri, işbu faaliyeti
              sebebiyle SkyPay’in karşı karşıya kalacağı tüm zararları ilk
              talepte nakden ve defaten karşılayacağını kabul, beyan ve taahhüt
              eder.
            </p>
            <p>
              SkyPay, hileli veya yetkisiz kullanım şüphesinin bulunması veya
              Müşterinin işbu Sözleşme’ye aykırı faaliyetleri sebebiyle
              Müşteri’ye sunduğu hizmetleri askıya alabilir, ödeme hesabını
              ve/veya ödeme aracını geçici veya süresiz olarak kullanıma
              kapatabilir veya Sözleşmeyi feshedebilir. Bu durumda SkyPay
              mevzuatta bilgi verilmesini engelleyici düzenlemeler bulunması
              veya güvenliği tehdit edici objektif nedenlerin varlığı dışında
              Müşteri’yi bilgilendirebilir ve askıya alınma sebebi ortadan
              kalktığında hizmetleri ve ödeme aracını yeniden Müşteri
              kullanımına sunar.{" "}
            </p>
            <p></p>
          </li>
          <li className="space-y-2">
            <h2 className="font-semibold">GİZLİLİK VE BİLGİ GÜVENLİĞİ</h2>
            <p></p>
            <p>
              Müşteri, işbu Sözleşme çerçevesinde herhangi bir şekilde
              SkyPay’den temin edeceği tüm bilgileri devamlı olarak gizli
              tutacağını, saklayacağını, Sözleşme’de belirtilenden başka bir
              amaç için kullanmayacağını ve bu bilgileri gizli bilgiler olarak
              değerlendirerek SkyPay’in yazılı rızası olmaksızın üçüncü
              şahıslara (yasal zorunluluklar dışında) ifşa etmeyeceğini kabul,
              beyan ve taahhüt etmektedir. İfşa edilmesi halinde SkyPay’in
              bilcümle maddi ve manevi tazminat hakkı saklıdır. Gizlilik hükmü
              Sözleşme’den bağımsız bir taahhüt olup, işbu Sözleşme’nin herhangi
              bir nedenle hitamından sonra da yürürlükte kalacaktır.
            </p>
            <p></p>
          </li>
          <li className="space-y-2">
            <h2 className="font-semibold">MÜCBİR SEBEP</h2>
            <p></p>
            <p>
              Tarafların, kendi iradeleri dışında gerçekleşen, müdahale
              imkanları bulunmayan ve makul bir şekilde önceden öngörülmesi
              mümkün olmayan nedenlerle yükümlülüklerini yerine getiremedikleri
              savaş, sıkı yönetim, seferberlik, terör eylemleri, pandemi, doğal
              afetler, yangın, grev ve lokavt da dahil istisnai olaylar mücbir
              sebep olarak kabul edilir. Mücbir sebepler ortadan kalkınca
              Sözleşme kaldığı yerden devam eder. Mücbir sebep halinin 1 (bir)
              aydan uzun sürmesi halinde, hakları ihlal edilen Taraf işbu
              Sözleşme’yi tazminatsız olarak feshedebilir.
            </p>
            <p></p>
          </li>
          <li className="space-y-2">
            <h2 className="font-semibold">MUHTELİF HÜKÜMLER</h2>
            <p></p>
            <p>
              İşbu Sözleşme’den kaynaklı uyuşmazlıklarda İstanbul Merkez
              (Çağlayan) Mahkemeleri ve İcra Müdürlükleri yetkili kılınmıştır.{" "}
            </p>
            <p>
              Müşteri’nin işbu Sözleşme kapsamındaki hizmetleri mesleki ve
              ticari amaçlarla satın aldığı hallerde, Müşteri Sözleşme’yi
              onaylamış olsa dahi işbu Sözleşme’nin tüketicilere ilişkin
              hükümleri Müşteri’ye uygulanmayacaktır.{" "}
            </p>
            <p>
              İşbu Sözleşme kapsamında belirtilen adresler Taraflar’ın yasal
              tebligat adresleri olup, herhangi bir adres değişikliği karşı
              tarafa yazılı olarak bildirilmediği sürece bu adreslere yapılan
              tebligatlar geçerli addedilecektir.{" "}
            </p>
            <p>
              Müşteri, SkyPay’ın ön yazılı onayı olmadan doğrudan veya dolaylı
              olarak tamamen veya kısmen Sözleşme’yi ve Sözleşme konusu hak ve
              yükümlülüklerini başkalarına devir ve temlik edemez. Müşteri, 6
              (altı) ay boyunca kullanılmayan hesabını dondurulabilir. Bu
              durumda hesabını tekrar açmak isteyen Müşteri, sunulan güvenlik
              adımını geçmesi durumunda hesabını kullanmaya devam edebilir.{" "}
            </p>
            <p>
              SkyPay, her zaman için Sözleşme’den doğan hak, yükümlülük,
              alacak ve sorumluluklarını üçüncü şahıslara devir ve temlik
              edebilir, alt yüklenici kullanabilir.
            </p>
            <p>
              İşbu Sözleşme kapsamındaki işlemler ve Hizmete ilişkin her türlü
              ihtilafta Müşterinin yazılı olarak veya görüşmelerin kayıt altına
              alındığı telefonla iletişim adresi olarak bildirmiş olduğu faks
              numarasından, e-posta adresinden, KEP (Kayıtlı Elektronik Posta)
              adresinden ve GSM numarasından göndermiş olduğu iletiler ile
              beraber SkyPay’in kendi veri tabanında, sunucularında tuttuğu
              elektronik ve sistem kayıtlarının, ticari kayıtlarının, defter
              kayıtlarının ve bilgisayar kayıtları delil teşkil edecek olup,
              Taraflar bu maddenin Hukuk Muhakemeleri Kanunu Madde 193 anlamında
              bir delil sözleşmesi teşkil ettiğini kabul ederler.
            </p>
            <p>
              İşbu Sözleşme’nin herhangi bir hükmünün bir Tarafça ihlaline
              karşın diğer Taraf’ın herhangi bir işlem yapmamış olması diğer
              Taraf’ın haklarından vazgeçtiği anlamına gelmez ve diğer Taraf
              müteakip ihlal hallerinde de Sözleşme’den doğan haklarını her
              zaman kullanmak hakkına sahiptir. İşbu Sözleşme’den doğan herhangi
              bir hakkın ilgili Tarafça süresinde kullanılmaması veya gecikmeli
              olarak kullanılması, hak sahibi Taraf’ın söz konusu haktan feragat
              ettiği anlamına gelmez.
            </p>
            <p>
              İşbu Sözleşme'nin hükümlerinden herhangi birinin herhangi bir
              nedenle geçersiz veya uygulanamaz bulunması halinde, söz konusu
              hüküm tarafların niyetini etkileyecek şekilde müsaade edilen en
              son dereceye kadar söz konusu hükmü uygulanabilir kılmak için
              gerektiği ölçüde yeniden düzenlenecektir ve işbu Sözleşme'nin
              kalan kısmı bundan etkilenmeden tam olarak yürürlükte kalmaya
              devam edecektir. Ancak ilgili hükmün yeniden düzenlenemeyeceğinin
              tespit edilmesi durumlarında ilgili hükmün bu şartlardan
              ayrılabilir olduğu kabul edilecek ve geri kalan hükümlerin
              geçerliliğini ve uygulanabilirliğini etkilemeyecektir.
            </p>
            <p></p>
          </li>
          <li className="space-y-2">
            <h2 className="font-semibold">SÖZLEŞMENİN YÜRÜRLÜĞÜ</h2>
            <p></p>
            <p>
              İşbu Sözleşme 19 maddeden ibaret olup, Taraflar arasında
              elektronik ortamda onaylandığı tarihte yürürlüğe girmiştir.
            </p>
            <p></p>
          </li>
        </ul>
       
      </div>
    </div>
  );
};
export default KullaniciSozlesmesi;
