import {createSlice, createAsyncThunk} from '@reduxjs/toolkit';
import ordersService from './ordersService';

//Admin Orders Thunk Action Creator
export const orders = createAsyncThunk('admin/orders', async (params, {rejectWithValue}) => {
    try {
        const response = await ordersService.orders(params);
        return response;
    } catch (error) {
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
        return rejectWithValue(message);
    }
});
//Admin Order Detail Thunk Action Creator
export const orderDetail = createAsyncThunk('admin/orders/detail', async (params, {rejectWithValue}) => {
    try {
        const response = await ordersService.ordersDetail(params);
        return response.data;
    } catch (error) {
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
        return rejectWithValue(message);
    }
});

export const orderFinalize = createAsyncThunk('admin/orders/finalize', async (data, {rejectWithValue}) => {
    try {
        const response = await ordersService.finalizeOrder(data);
        console.log(response)
        return response;
    } catch (error) {
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
        console.log(message.toString())
        return rejectWithValue(message);
    }
});


const initialState = {
    orders: {},
    orderDetail:{},
    isError: false,
    isLoading: false,
    isSuccess: false,
    isDetailSuccess: false,
    isDetailLoading: false,
    isDetailError: false,
    isFinalizeSuccess: false,
    isFinalizeLoading: false,
    isFinalizeError: false,
    message: '',    
};

export const ordersSlice = createSlice({
    name: 'adminOrders',
    initialState,
    reducers: {
        reset: (state) => {
            state.isError = false;
            state.isSuccess = false;
            state.isLoading = false;
            state.message = '';
            state.isFinalizeSuccess = false
            state.isFinalizeLoading=false
            state.isFinalizeError=false
        },
        resetOrders: (state) => {
            state.orderDetail = {};
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(orders.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(orders.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isSuccess = true;
                state.orders = action.payload;
            })
            .addCase(orders.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.message = action.payload;
            });
        builder
            .addCase(orderDetail.pending, (state) => {
                state.isDetailLoading = true;
            })
            .addCase(orderDetail.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isDetailSuccess = true;
                state.orderDetail = action.payload;
            })
            .addCase(orderDetail.rejected, (state, action) => {
                state.isDetailLoading = false;
                state.isDetailError = true;
                state.isDetailSuccess = false;
                state.message = action.payload;
            });
        builder
            .addCase(orderFinalize.pending, (state) => {
                state.isFinalizeLoading = true;
            })
            .addCase(orderFinalize.fulfilled, (state, action) => {
                state.isFinalizeLoading = false;
                state.isFinalizeSuccess = true;
                state.isFinalizeError = false;
               
            })
            .addCase(orderFinalize.rejected, (state, action) => {
                state.isFinalizeLoading = false;
                state.isFinalizeError = true;
                state.isFinalizeSuccess = false;
                state.message = action.payload;
            });
        
        
    }
})

export const {reset,resetOrders} = ordersSlice.actions;
export default ordersSlice.reducer;