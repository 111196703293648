import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import Resellers from "components/Panel/Admin/Resellers";
import Button from "components/Button";
import { Link } from "react-router-dom"
import Pagination from "components/Pagination";

import { getResellers } from "redux/admin/users/usersSlice";

const ResellersContainer = () => {
  const dispatch = useDispatch();
  const { data } = useSelector(state => state.adminUsers.resellers);
  const { total, pages, page, per_page } = useSelector(state => state.adminUsers.resellers);
  useEffect(() => {
    dispatch(getResellers({ page: 1, per_page: 15, show_inactive: true }))
  }, [dispatch])

  const handlePageChange = (newPage) => {
    dispatch(getResellers({ page: newPage, per_page: 15, show_inactive: true }))
  };

  return (
    <div className="bg-black min-h-screen">
      <div className="p-4 flex items-center justify-between">
        <h2 className="text-xl font-semibold text-white-100">Bayi Listesi</h2>
        <Link to="/admin/resellers/create"> <Button size="small">Bayi Ekle</Button></Link>
      </div>
      <Resellers data={data} />
      <Pagination currentPage={page} totalPages={pages} onPageChange={handlePageChange} />
    </div>
  );
};

export default ResellersContainer;
