import Hero from "components/Landing/Hero";
import FeatureOne from "components/Landing/FeatureOne";
import FeatureTwo from "components/Landing/FeatureTwo";
import Solutions from "components/Landing/Solutions";
import Financial from "components/Landing/Financial";
import FAQ from "components/Landing/FAQ";

const HomepageContainer = () => {
  return (
    <>
     
      <Hero />
      <FeatureOne />
      <FeatureTwo />
      <Solutions />
      <Financial />
      <FAQ />
    
    </>
  );
};

export default HomepageContainer;
