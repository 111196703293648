import {createSlice,createAsyncThunk} from '@reduxjs/toolkit';
import createOtpService from './createOtpService';


export const createOtpCode = createAsyncThunk("admin/createOtp", async (data, {rejectWithValue}) => {
    try {
        const response = await createOtpService.create(data);
        return response;
      } catch (error) {
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
        return rejectWithValue(message);
      }
})


const initialState = {
    data: {},
    isCreateOtpLoading:false,
    isCreateOtpError:false,
    isCreateOtpSuccess:false,
    message: {}
}

export const adminCreateOtpSlice = createSlice({
    name:"adminCreateOtp",
    initialState,
    reducers: {
        reset:(state) => {
            state.isCreateOtpError = false;
            state.isCreateOtpLoading = false;
            state.isCreateOtpSuccess = false;
            state.message = {}
        }
    },
    extraReducers: (builder) => {
        builder.addCase(createOtpCode.pending, (state) => {
            state.isCreateOtpLoading = true;
        })
        .addCase(createOtpCode.fulfilled, (state,action) => {
            state.isCreateOtpLoading = false;
            state.isCreateOtpSuccess = true;
            state.data = action.payload;
        })
        .addCase(createOtpCode.rejected, (state,action) => {
            state.isCreateOtpLoading = false;
            state.isCreateOtpError = true;
            state.message = action.payload;
        })
    }
})

export const {reset} = adminCreateOtpSlice.actions;

export default adminCreateOtpSlice.reducer;