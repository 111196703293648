import {createSlice, createAsyncThunk} from '@reduxjs/toolkit';
import downloadFileService from './downloadFileService';
import { saveAs } from 'file-saver';

export const downloadFile = createAsyncThunk('admin/download/file', async (params, {rejectWithValue}) => {
    try {
        const response = await downloadFileService.download(params);
        const blob = new Blob([response.data], { type: 'application/pdf' });
        saveAs(blob, 'invoice.pdf');
        return {};
    } catch (error) {
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
        return rejectWithValue(message);
    }
});




const initialState = {
    file: null,
    isLoading: false,
    isError: false,
    isSuccess: false,
    message:''
};

export const adminDownloadFileSlice = createSlice({
    name: 'adminDownloadFile',
    initialState,
    reducers: {
        reset: (state) => {
            state.isError = false;
            state.isSuccess = false;
            state.isLoading = false;
            state.message = '';
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(downloadFile.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(downloadFile.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isSuccess = true;
                state.file = action.payload;
            })
            .addCase(downloadFile.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.message = action.payload;
            });
      
    }
})

export const {reset} = adminDownloadFileSlice.actions;
export default adminDownloadFileSlice.reducer;