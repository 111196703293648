import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import {
  sendOtp,
  loginWithOtp,
  reset,
} from "redux/customer/register/registerSlice";
import { toast } from "react-toastify";
import Heading from "components/Typography/Header";
import Button from "components/Button";

import CircularCountdown from "components/CircularCountDown";

const CustomerRegisterContainer = () => {
  const navigate = useNavigate();
  const [step, setStep] = useState(1);
  const dispatch = useDispatch();
  const [formValues, setFormValues] = useState({
    phoneNumber: "",
    otp: "",
    first_name: "",
    last_name: "",
    kvkk: false,
    user_agreement: false,
  });
  const {
    isSendOTP,
    isError,
    isSuccess,
    message,
    isLoading,
    isErrorLoginWithOtp,
    isSuccessLoginWithOtp,
    isLoadingLoginWithOtp,
  } = useSelector((state) => state.customerRegister);

  useEffect(() => {
    if (isSendOTP) {
      setStep(2);
    } else {
      setStep(1);
    }
  }, [isSendOTP]);

  useEffect(() => {
    if (isError) {
      toast.error(message);
    }
    if (isSuccess) {
      toast.success(message);
    }

    if (isErrorLoginWithOtp) {
      toast.error(message);
    }

    if (isSuccessLoginWithOtp) {
      toast.success(message);
      setTimeout(() => {
        navigate("/login/customer");
      }, 1000);
    }
    return () => {
      dispatch(reset());
    };
  }, [
    isError,
    isSuccess,
    message,
    isErrorLoginWithOtp,
    isSuccessLoginWithOtp,
    dispatch,
  ]);

  const phoneRegex = /^(\+90|0)?[0-9]{10}$/;

  const registerForm = Yup.object().shape({
    first_name: Yup.string().required("Ad gereklidir."),
    last_name: Yup.string().required("Soyad gereklidir."),
    phoneNumber: Yup.string()
      .matches(phoneRegex, "Geçerli bir telefon numarası giriniz.")
      .required("Telefon numarası gereklidir."),
    kvkk: Yup.boolean().oneOf([true], "KVKK Politikasını onaylamalısınız."),
    user_agreement: Yup.boolean().oneOf(
      [true],
      "Kullanıcı sözleşmesini onaylamalısınız."
    ),
  });

  const otpForm = Yup.object().shape({
    otp: Yup.string().required("OTP kodu gereklidir."),
  });

  const validationSchema = step === 1 ? registerForm : otpForm;

  const handleSubmit = async (values) => {
    setFormValues(values);
    if (step === 1) {
      await dispatch(
        sendOtp({
          phone: values.phoneNumber,
          first_name: values.first_name,
          last_name: values.last_name,
        })
      );
    } else {
      await dispatch(
        loginWithOtp({
          phone: values.phoneNumber,
          otp: values.otp,
          first_name: values.first_name,
          last_name: values.last_name,
        })
      );
    }
  };

  return (
    <div className="flex flex-1 flex-col h-full min-h-screen justify-center items-center">
      <Heading level={4} className="text-primary">
        Kullanıcı Kayıt
      </Heading>
      <div className="space-y-10 mt-10 ">
        <Formik
          initialValues={formValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ errors, touched }) => (
            <Form className="space-y-4">
              {step === 1 ? (
                <>
                  <div className="w-[300px]">
                    <span className="text-white-100 font-medium ">Ad</span>
                    <Field
                      autoComplete="off"
                      name="first_name"
                      type="text"
                      className="focus:outline-none w-full h-10 bg-transparent border-b border-secondary text-white-100"
                    />
                    {errors.phoneNumber && touched.phoneNumber ? (
                      <ErrorMessage
                        component="span"
                        className="text-red-500 my-2"
                        name="first_name"
                      />
                    ) : null}
                  </div>
                  <div className="w-[300px]">
                    <span className="text-white-100 font-medium ">Soyad</span>
                    <Field
                      autoComplete="off"
                      name="last_name"
                      type="text"
                      className="focus:outline-none w-full h-10 bg-transparent border-b border-secondary text-white-100"
                    />
                    {errors.phoneNumber && touched.phoneNumber ? (
                      <ErrorMessage
                        component="span"
                        className="text-red-500 my-2"
                        name="last_name"
                      />
                    ) : null}
                  </div>
                  <div className="w-[300px]">
                    <span className="text-white-100 font-medium ">
                      Telefon Numarası
                    </span>
                    <Field
                      autoComplete="off"
                      name="phoneNumber"
                      type="text"
                      className="focus:outline-none w-full h-10 bg-transparent border-b border-secondary text-white-100"
                    />
                    {errors.phoneNumber && touched.phoneNumber ? (
                      <ErrorMessage
                        component="span"
                        className="text-red-500 my-2"
                        name="phoneNumber"
                      />
                    ) : null}
                  </div>
                  <div  className="flex items-center gap-4">
                   <div className="flex flex-col">
                   <Link
                      className="text-primary"
                      to="/kullanici-sozlesmesi"
                      target="_blank"
                    >
                      Kullanıcı Sözleşmesini Onaylıyorum
                    </Link>
                    {errors.user_agreement && touched.user_agreement ? (
                      <ErrorMessage
                        component="span"
                        className="text-red-500 my-2"
                        name="user_agreement"
                      />
                    ) : null}
                   </div>
                    <Field
                      name="user_agreement"
                      type="checkbox"
                    />
                    
                  </div>
                  <div className="flex items-center gap-4">
                   <div className="flex flex-col">
                   <Link className="text-primary" to="/kvkk" target="_blank">
                      KVKK Politikasını Onaylıyorum
                    </Link>
                    {errors.kvkk && touched.kvkk ? (
                      <ErrorMessage
                        component="span"
                        className="text-red-500 my-2"
                        name="kvkk"
                      />
                    ) : null}
                   </div>
                    <Field
                      name="kvkk"
                      type="checkbox"
                     
                    />
                    
                  </div>
                </>
              ) : (
                <>
                  <div className="w-[300px]">
                    <span className="text-white-100 font-medium ">
                      OTP Kodu
                    </span>
                    <Field
                      name="otp"
                      autoComplete="off"
                      type="text"
                      className="focus:outline-none w-full h-10 bg-transparent border-b border-secondary text-white-100"
                    />
                    {errors.otp && touched.otp ? (
                      <ErrorMessage
                        component="span"
                        className="text-red-500 my-2"
                        name="otp"
                      />
                    ) : null}
                  </div>
                  <div className="flex flex-col items-center">
                    <CircularCountdown />
                  </div>
                </>
              )}

              <Button loading={isLoading} size="small" className="w-full mt-4">
                {step === 1 ? "Kod Gönder" : "Kayıt Ol"}
              </Button>
            </Form>
          )}
        </Formik>
        <div>
          {step === 1 && (
            <>
              <span className="text-white-100">
                Hesabınız var mı?{" "}
                <span
                  onClick={() => navigate("/login/customer")}
                  className="text-primary cursor-pointer"
                >
                  Giriş Yap
                </span>
              </span>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default CustomerRegisterContainer;
