import {createSlice, createAsyncThunk} from '@reduxjs/toolkit';
import subresellersService from './subresellersService';


export const getSubresellers = createAsyncThunk('reseller/subresellers/list', async (params, {rejectWithValue}) => {
    try {
        const response = await subresellersService.subresellers(params);
        return response;
    } catch (error) {
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
        return rejectWithValue(message);
    }
});

export const createSubreseller = createAsyncThunk('reseller/subresellers/new', async (params, {rejectWithValue}) => {
    try {
        const response = await subresellersService.create(params);
        
        return response;

    } catch (error) {
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
        return rejectWithValue(message);
    }
});

export const updateSubreseller = createAsyncThunk('reseller/subresellers/update', async (data, {rejectWithValue}) => {
    try {
        const response = await subresellersService.updateSubreseller(data);
        return response;
    } catch (error) {
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
        return rejectWithValue(message);
    }
});

export const detailSubreseller = createAsyncThunk('reseller/subresellers/detail', async ({search}, {getState,dispatch,rejectWithValue}) => {
    try {
        
        const state = getState();
        let subresellers = state.resellerSubresellers.subresellers.data;
    
        // Eğer subresellers tanımlanmamışsa, listeyi alın
        if (!subresellers || subresellers.length === 0) {
          const response = await dispatch(getSubresellers({ page: 1, per_page: 30, show_inactive: true }));
          subresellers = response.payload.data;
        }
    
        const subreseller = subresellers.find((subreseller) => subreseller.id === search);
    
        if (!subreseller) {
          throw new Error(`Subreseller not found for id: ${search}`);
        }
    
        return subreseller;
      } catch (error) {
        const message = error.message || error.toString();
        return rejectWithValue(message);
      }
});

const initialState = {
    subresellers: [],
    subresellerDetail:{},
    message:'',
    isError: false,
    isLoading: false,
    isSuccess: false,
    isCreateSubresellerSuccess: false,
    isUpdateSubresellerSuccess: false,
    isDetailSubresellerSuccess: false,
    isCreateSubresellerError: false,
    isUpdateSubresellerError: false,
    isDetailSubresellerError: false,
    isLoadingSubreseller: false,
    isCreateSubresellerLoading: false,
    isUpdateSubresellerLoading: false,
    
};

export const subresellersSlice = createSlice({
    name: 'resellerSubresellers',
    initialState,
    reducers: {
        reset: (state) => {
            state.isCreateSubresellerSuccess = false;
            state.isUpdateSubresellerSuccess = false;
            state.isDetailSubresellerSuccess = false;
            state.isCreateSubresellerError = false;
            state.isUpdateSubresellerError = false;
            state.isDetailSubresellerError = false;
            state.isLoadingSubreseller = false;
            state.isCreateSubresellerLoading = false;
            state.isUpdateSubresellerLoading = false;
            state.message = '';
            state.isError = false;
            state.isLoading = false;
            state.isSuccess = false;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getSubresellers.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(getSubresellers.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isSuccess = true;
                state.subresellers = action.payload;
              
            })
            .addCase(getSubresellers.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.message = action.payload;
            });
        builder
            .addCase(createSubreseller.pending, (state) => {
                state.isCreateSubresellerLoading = true;
            })
            .addCase(createSubreseller.fulfilled, (state, action) => {
                state.isCreateSubresellerLoading = false;
                state.isCreateSubresellerSuccess = true;
                state.message = action.payload;
            })
            .addCase(createSubreseller.rejected, (state, action) => {
                state.isCreateSubresellerLoading = false;
                state.isCreateSubresellerSuccess = true;
                state.message = action.payload;
            });
        builder
            .addCase(updateSubreseller.pending, (state) => {
                state.isUpdateSubresellerLoading = true;
            })
            .addCase(updateSubreseller.fulfilled, (state, action) => {
                state.isUpdateSubresellerLoading = false;
                state.isUpdateSubresellerSuccess = true;
                state.message = action.payload;
            })
            .addCase(updateSubreseller.rejected, (state, action) => {
                state.isUpdateSubresellerLoading = false;
                state.isUpdateSubresellerError = true;
                state.message = action.payload;
            });
        builder
            .addCase(detailSubreseller.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(detailSubreseller.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isSuccess = true;
                state.subresellerDetail = action.payload;
            })
            .addCase(detailSubreseller.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.message = action.payload;
            });
    }
})

export const {reset} = subresellersSlice.actions;
export default subresellersSlice.reducer;