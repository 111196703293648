import React, { useEffect, useState } from "react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Outlet, Navigate, useLocation } from "react-router-dom";
import Sidebar from "components/Panel/Subreseller/Sidebar";
import { useSelector, useDispatch } from "react-redux";
import { profile } from "redux/subreseller/profile/profileSlice"
import { settings } from "redux/subreseller/settings/settingsSlice"
import { Helmet } from "react-helmet";

function DashboardLayout() {
  const dispatch = useDispatch();
  const { user, role } = useSelector((state) => state.subresellerAuth);
  const location = useLocation();
  const [isSidebarExpanded, setIsSidebarExpanded] = useState(false)

  const handleToggleSidebar = () => {
    setIsSidebarExpanded(!isSidebarExpanded);
  };

  useEffect(() => {
    setIsSidebarExpanded(false);
  }, [location])

  useEffect(() => {
    dispatch(profile());
    dispatch(settings());
  }, [dispatch])

  if (!user || role !== "subreseller") {
    return <Navigate to="/login/subreseller" />;
  }

  return (
    <div className="min-h-screen flex bg-black">
      <Helmet>
        <title>SkyPay | Alt Bayi Paneli</title>
        <meta name="description" content="SkyPay | Alt Bayi Paneli" />
      </Helmet>
      <Sidebar toggle={isSidebarExpanded} />
      <main className="md:ml-20 w-full">
        <div className="text-white-100 ml-4 my-2 inline-block md:hidden bg-primary px-4 py-1 font-semibold rounded-md " onClick={handleToggleSidebar}>Menü</div>
        <Outlet />
      </main>
      <ToastContainer />
    </div>
  );
}

export default DashboardLayout;
