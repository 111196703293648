import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import * as XLSX from "xlsx";
import { getResellerPayouts } from "redux/admin/stats/statsSlice";
import { useDispatch, useSelector } from "react-redux";
import { getResellers, getSubresellers } from "redux/admin/users/usersSlice";
import Heading from "components/Typography/Header";

const TotalPayoutsContainer = () => {
  const dispatch = useDispatch();
  const [sortedData, setSortedData] = useState([]);
  const [userType, setUserType] = useState("reseller");

  const [startDate, setStartDate] = useState(() => {
    const date = new Date();
    date.setUTCDate(date.getUTCDate() - 1);
    return date.toISOString().slice(0, 10);
  });

  const [endDate, setEndDate] = useState(() => {
    const date = new Date();
    date.setUTCDate(date.getUTCDate() + 1);
    return date.toISOString().slice(0, 10);
  });

  const { resellerPayouts } = useSelector((state) => state.adminStats);
  const { resellers, subresellers } = useSelector((state) => state.adminUsers);

  useEffect(() => {
    // 3 saatlik farkı milisaniye cinsinden hesaplayarak çıkarın.
    const utcMinusThreeHours = -3 * 60 * 60 * 1000;
    const startDateTime =
      new Date(`${startDate}T00:00:00.000Z`).getTime() + utcMinusThreeHours;
    const endDateTime =
      new Date(`${endDate}T23:59:00.000Z`).getTime() + utcMinusThreeHours;

    dispatch(
      getResellerPayouts({
        start: new Date(startDateTime).toISOString(),
        end: new Date(endDateTime).toISOString(),
        per_user: true,
      })
    );

    if (resellerPayouts) {
      dispatch(getResellers());
      dispatch(getSubresellers());
    }
  }, [dispatch, startDate, endDate]);

  useEffect(() => {
    let filteredData = resellerPayouts || [];
    const dataToSort = Array.isArray(filteredData) ? [...filteredData] : [];

    dataToSort.sort((a, b) => b.total_try_payouts - a.total_try_payouts);
    let x = dataToSort.map((dataItem) => {
      let newName = "";
      if (dataItem.user_type === "reseller") {
        const matchingReseller = resellers?.data?.find(
          (reseller) => reseller.id === dataItem.user_id
        );
        if (matchingReseller) {
          newName = matchingReseller.name;
        }
      } else if (dataItem.user_type === "subreseller") {
        const matchingSubreseller = subresellers?.data?.find(
          (subreseller) => subreseller.id === dataItem.user_id
        );
        if (matchingSubreseller) {
          newName = matchingSubreseller.name;
        }
      }
      return {
        ...dataItem,
        user_name: newName,
      };
    });

    setSortedData(x);
  }, [resellerPayouts, userType]);

  useEffect(() => {
    sortedData.forEach(async (user) => {
      if (user.user_type === "reseller") {
        dispatch(getResellers());
      }
      if (user.user_type === "subreseller") {
        dispatch(getSubresellers());
      }
    });
  }, [sortedData, dispatch]);

  const exportToExcel = async () => {
    const ws = XLSX.utils.json_to_sheet(sortedData);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Para Çekme Komisyonu");

    // Dosyayı kaydet
    XLSX.writeFile(wb, "SkyPay-para-cekme-komisyon.xlsx");
  };

  return (
    <div className="p-4">
      <div className="flex gap-4">
        <Link to="/admin/total-sales">
          <Heading
            className="text-white-100 px-2 rounded-md cursor-pointer"
            level={5}
          >
            Satışlar
          </Heading>
        </Link>

        <Heading
          className="text-white-100 hover:bg-primary bg-primary transition-all px-2 rounded-md cursor-pointer"
          level={5}
        >
          Para Çekme Komisyonu
        </Heading>

        <Link to="/admin/total-diamond-payouts">
          <Heading
            className="text-white-100 hover:bg-primary transition-all px-2 rounded-md cursor-pointer"
            level={5}
          >
            Elmas Talep Komisyonu
          </Heading>
        </Link>
      </div>
      <div className="p-4 bg-black my-4 rounded-xl min-h-screen">
        <div className="">
          <div className="">
            <div className="">
              <label className="text-white-100 font-semibold">
                Filtreleme:{" "}
              </label>
              <input
                type="date"
                value={startDate}
                onChange={(e) => setStartDate(e.target.value)}
                className="rounded-lg px-3 py-2 mx-2"
              />
              <span className="text-white-100 text-xl">-</span>
              <input
                type="date"
                value={endDate}
                onChange={(e) => setEndDate(e.target.value)}
                className="rounded-lg px-3 py-2 mx-2"
              />
              <button
                disabled={!sortedData?.length}
                onClick={exportToExcel}
                className="bg-primary text-white-100 px-3 py-2 rounded-md cursor-pointer"
              >
                {!sortedData?.length
                  ? "Excel'e Aktarılacak Veri Yok"
                  : "Excel'e Aktar"}
              </button>
            </div>
            <div className="overflow-x-auto mt-4">
              <table className="min-w-full divide-y-2 divide-gray-200 text-sm">
                <thead className="">
                  <tr>
                    <th className="whitespace-nowrap text-left px-4 py-2 font-medium text-white-100">
                      Adı
                    </th>
                    <th className="whitespace-nowrap text-left px-4 py-2 font-medium text-white-100">
                      Tipi
                    </th>
                    <th className="whitespace-nowrap text-left px-4 py-2 font-medium text-white-100">
                      Komisyon
                    </th>
                    <th className="whitespace-nowrap text-left px-4 py-2 font-medium text-white-100">
                      Çektiği Tutar
                    </th>
                  </tr>
                </thead>

                <tbody className="divide-y divide-gray-200">
                  {sortedData?.map((data) => (
                    <tr key={data.id}>
                      <td className="whitespace-nowrap px-4 py-2 font-medium text-white-100">
                        {data.user_name}
                      </td>
                      <td className="whitespace-nowrap px-4 py-2 text-white-100">
                        {data.user_type === "reseller"
                          ? "Bayi"
                          : data.user_type === "subreseller"
                          ? "Alt Bayi"
                          : "Kullanıcı"}
                      </td>

                      <td className="whitespace-nowrap px-4 py-2 text-white-100">
                        {data.total_fee}₺
                      </td>
                      <td className="whitespace-nowrap px-4 py-2 text-white-100">
                        {data.total_try_payouts}₺
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TotalPayoutsContainer;
