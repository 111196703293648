import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { motion, useAnimation } from "framer-motion";
import Logo from "components/Logo";
import { Link } from "react-router-dom";
import { BiExit } from "react-icons/bi";
import { RxDashboard } from "react-icons/rx";
import { HiOutlineShoppingBag } from "react-icons/hi";
import { CgProfile } from "react-icons/cg";
import { GiTakeMyMoney } from "react-icons/gi";
import { BsShop } from "react-icons/bs";
import { logout } from "redux/subreseller/auth/customerSlice";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

const sidebarVariants = {
  expanded: {
    width: 240,
  },
  collapsed: {
    width: 80,
  },
};

const textVariants = {
  expanded: {
    opacity: 1,
  },
  collapsed: {
    opacity: 0,
  },
};

const Sidebar = ({ toggle }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { isSuccessLogout, message } = useSelector(
    (state) => state.subresellerAuth
  );

  useEffect(() => {
    if (isSuccessLogout) {
      setTimeout(() => {
        navigate("/login/subreseller");
      }, 1000);
      toast.success(message);
    }
  }, [isSuccessLogout]);

  const handleLogout = async () => {
    await dispatch(logout());
    navigate("/login/subreseller");
  };

  const [isExpanded, setIsExpanded] = useState(false);
  const controls = useAnimation();

  useEffect(() => {
    if (toggle) {
      controls.start("expanded");
      setIsExpanded(true);
    } else {
      controls.start("collapsed");
      setIsExpanded(false);
    }
  }, [toggle]);


  const handleMouseEnter = () => {
    if (window.innerWidth > 768) { // assuming 768px as breakpoint for mobile devices
      controls.start("expanded");
      setIsExpanded(true);
    }
  };

  const handleMouseLeave = () => {
    if (window.innerWidth > 768) { // assuming 768px as breakpoint for mobile devices
      controls.start("collapsed");
      setIsExpanded(false);
    }
  };

  return (
    <motion.div
      animate={controls}
      initial="collapsed"
      variants={sidebarVariants}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      className={`block md:fixed ${toggle !== true ? 'hidden md:block ' : ''}  w-20 h-screen p-4 bg-black border-r-[1px] border-gray-700 flex flex-col justify-between z-[999999999]`}
    >
      <div className="flex flex-col items-center">
        <Logo href="/subreseller" isMobile={true} />
        <span className="border-b-[1px] border-gray-700 w-full p-2"></span>
        <Link to="/subreseller">
          <div className="flex items-center gap-4 justify-center my-4 p-3 text-white-100 hover:text-secondary transition-all">
            <RxDashboard size={30} />
            {isExpanded === true ? (
              <span className="font-semibold text-base">Anasayfa</span>
            ) : (
              ""
            )}
          </div>
        </Link>

        <Link to="/subreseller/orders">
          <div className="flex items-center gap-4 justify-center my-4 p-3 text-white-100 hover:text-secondary transition-all">
            <HiOutlineShoppingBag size={30} />
            {isExpanded === true ? (
              <span className=" font-semibold text-base">Girilen Dekontlar</span>
            ) : (
              ""
            )}
          </div>
        </Link>
        {
          /*
        <Link to="/reseller/payout">
            <div className="flex items-center gap-4 justify-center my-4 p-3 text-white-100 hover:text-secondary transition-all">
              <GiTakeMyMoney size={30} />
              {isExpanded === true ? (
                <span className=" font-semibold text-base">Para Çekme </span>
              ) : (
                ""
              )}
            </div>
          </Link>
        */
        }
        <Link to="/subreseller/profile">
          <div className="flex items-center gap-4 justify-center my-4 p-3 text-white-100 hover:text-secondary transition-all">
            <CgProfile size={30} />
            {isExpanded === true ? (
              <span className=" font-semibold text-base">Profilim</span>
            ) : (
              ""
            )}
          </div>
        </Link>
      </div>
      <div>
        <div className="cursor-pointer" onClick={handleLogout}>
          <div className="flex items-center gap-4 justify-center text-white-100 hover:text-secondary transition-all">
            <BiExit size={30} />
            {isExpanded === true ? (
              <span className="font-semibold text-base">Çıkış Yap</span>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    </motion.div>
  );
};

export default Sidebar;
