import React, { useEffect } from 'react'
import { RxSketchLogo } from 'react-icons/rx'
import { useCustomSettings } from 'hooks/useCustomSettings'
import { useSelector, useDispatch } from 'react-redux'
import { settings } from "redux/customer/settings/settingsSlice"

const keyTextMapping = {
  customer_diamond_price: "1 Adet Elmas Fiyatı",
  customer_minimum_diamond_amount: "Minimum Satın Alabileceğiniz Elmas Sayısı",
  customer_maximum_diamond_amount: "Maximum Satın Alabileceğiniz Elmas Sayısı",
}

const TopPanel = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(settings());
  }, [dispatch])

  const { data } = useSelector(state => state.customerSettings);
  const diamondPrice = useCustomSettings(data, keyTextMapping, 'customer_diamond_price');
  const minimumDiamondAmount = useCustomSettings(data, keyTextMapping, 'customer_minimum_diamond_amount');
  const maximumDiamondAmount = useCustomSettings(data, keyTextMapping, 'customer_maximum_diamond_amount');

  return (
    <div className='grid lg:grid-cols-3 gap-4 p-4'>
      <div className='lg:col-span-1 col-span-1 bg-gray-900 flex justify-between w-full p-4 rounded-lg'>
        <div
          className="flex items-center gap-4 rounded-lg p-6"
        >
          <span className="rounded-full bg-primary p-3 text-white-100">
            <RxSketchLogo size={30} />
          </span>

          <div>
            <p className="text-2xl font-medium text-white-100">₺{diamondPrice.value}</p>

            <p className="text-base text-white-100">{diamondPrice.text}</p>
          </div>
        </div>
      </div>
      <div className='lg:col-span-1 col-span-1 bg-gray-900 flex justify-between w-full p-4 rounded-lg'>
        <div
          className="flex items-center gap-4 rounded-lg p-6"
        >
          <span className="rounded-full bg-primary p-3 text-white-100">
            <RxSketchLogo size={30} />
          </span>

          <div>
            <p className="text-2xl font-medium text-white-100">{minimumDiamondAmount.value}</p>

            <p className="text-base text-white-100">{minimumDiamondAmount.text}</p>
          </div>
        </div>
      </div>
      <div className='lg:col-span-1 col-span-1 bg-gray-900 flex justify-between w-full p-4 rounded-lg'>
        <div
          className="flex items-center gap-4 rounded-lg p-6"
        >
          <span className="rounded-full bg-primary p-3 text-white-100">
            <RxSketchLogo size={30} />
          </span>

          <div>
            <p className="text-2xl font-medium text-white-100">{maximumDiamondAmount.value}</p>

            <p className="text-base text-white-100">{maximumDiamondAmount.text}</p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default TopPanel
