
import React from 'react'
import ResellerDetailContainer from 'containers/panel/admin/resellers-detail-container'

const ResellerDetailPage = () => {
  return (
    <ResellerDetailContainer/>
  )
}

export default ResellerDetailPage