import instance from "api/instance";

const totalSales = async (data) => {
  const response = await instance.get("/admin/stats/total-sales", {
    params: data
  });
  if (response.status === 200) {
    return response.data;
  }

  return Promise.reject(response.data);
};

const resellerPayouts = async (data) => {
  const response = await instance.get("/admin/stats/reseller-payouts", {
    params: data
  });
  if (response.status === 200) {
    return response.data;
  }

  return Promise.reject(response.data);
};

const resellerDiamondTransfers = async (data) => {
  const response = await instance.get("/admin/stats/reseller-transfers", {
    params: data
  });
  if (response.status === 200) {
    return response.data;
  }

  return Promise.reject(response.data);
};





const settingsService = {
  totalSales,
  resellerPayouts,
  resellerDiamondTransfers
};

export default settingsService;
