import React from "react";
import { useSelector } from "react-redux";
import OrderIndicator from "components/OrderIndicator";


const ProfileContainer = () => {

  const { profile } = useSelector((state) => state.subresellerProfile);

  return (
    <div className="shadow-md rounded px-8 pt-6 pb-8 mb-4">
      <h2 className="text-2xl font-semibold text-white-100 mb-4 flex items-center gap-4">
        Profilim{" "}
      </h2>
      <div className="grid md:grid-cols-2 grid-cols-1 gap-4">
        <div className="bg-gray-900 p-4 space-y-4 rounded shadow">
          <h2 className="text-xl font-semibold text-white-100">
            Kişisel Bilgilerim
          </h2>
          <p className="text-white-100">
            <span className="font-semibold">ID:</span>
            <span className="ml-2">{profile.id}</span>
          </p>

          <p className="text-white-100">
            <span className="font-semibold">Bayi Adı:</span>
            <span className="ml-2">{profile.name}</span>
          </p>
          <p className="text-white-100">
            <span className="font-semibold">Telefon Numarası:</span>
            <span className="ml-2">{profile.phone}</span>
          </p>
        </div>
        <div className="bg-gray-900 p-4 space-y-4 rounded shadow">
          <h2 className="text-xl font-semibold text-white-100">Bayilik</h2>
          <p className="text-white-100">
            <span className="font-semibold">Durum:</span>
            <span className="ml-2">
              {profile.is_active === true ? (
                <OrderIndicator color={"green"}>Aktif</OrderIndicator>
              ) : (
                <OrderIndicator color={"red"}>Pasif</OrderIndicator>
              )}
            </span>
          </p>
          <p className="text-white-100">
            <span className="font-semibold">Alt Bayi Key:</span>

            <span className="ml-2">
              {profile?.payment_key || "Tanımlanmamış"}
            </span>
            <span className="text-xs block text-primary font-medium">Dekont Açıklamasına Yazılacak</span>
          </p>
        </div>
      </div>
      <div className="grid md:grid-cols-2 grid-cols-1 gap-4 mt-4">

        <div className="bg-gray-900 p-4 space-y-4 rounded shadow">
          <h2 className="text-xl font-semibold text-white-100">Finans</h2>
          <p className="text-white-100">
            <span className="font-semibold">Hesap Bakiyesi:</span>
            <span className="ml-2">{profile.pbalance_try}₺</span>
          </p>
          <p className="text-white-100">
            <span className="font-semibold">1 Elmas Fiyatı:</span>
            <span className="ml-2">{profile.calculator_diamond_price}₺</span>
          </p>
        </div>
      </div>
    </div>
  );
};

export default ProfileContainer;
