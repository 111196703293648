"use client";
import React, { useState } from "react";
import OrderIndicator from "components/Panel/Admin/OrderIndicator";
import Popup from "components/Popup";
import Button from "components/Button";

const paymentDetails = {
  id: 0,
  name: "Ahmet Yılmaz",
  phone: "+90 123 456 78 90",
  email: "ahmet.yilmaz@example.com",
  diamonds: 1000,
  amount: 250,
  paymentMethod: "Havale/EFT",
  paymentStatus: 2,
  paymentDate: "12.12.2020",
  processDate: "12.12.2020",
};

const OrderDetail = () => {
  const [changeStatus, setChangeStatus] = useState(false);

  const changeStatusHandler = () => {
    setChangeStatus((prev) => !prev);
  };

  return (
    <div className="shadow-md rounded px-8 pt-6 pb-8 mb-4">
      <h2 className="text-2xl font-semibold text-white-100 mb-4 flex items-center gap-4">
        Ödeme Detayları #{paymentDetails.id} |{" "}
        {paymentDetails.paymentStatus === 0 ? (
          <OrderIndicator color="red">Ödemesi Yapıldı.</OrderIndicator>
        ) : paymentDetails.paymentStatus === 1 ? (
          <OrderIndicator color="green">Ödeme Bekliyor.</OrderIndicator>
        ) : paymentDetails.paymentStatus === 2 ? (
          <OrderIndicator color="yellow">Manual Ödeme Bekliyor.</OrderIndicator>
        ) : (
          <OrderIndicator color="green">No Data</OrderIndicator>
        )}
      </h2>
      <hr className="mb-4" />
      <div className="grid grid-cols-1 mb-4">
        <div className="bg-gray-900 p-4 rounded shadow">
          <h3 className="text-xl font-semibold text-white-100 mb-4 flex items-center gap-4">
            İşlem Bilgileri{" "}
            <span
              onClick={changeStatusHandler}
              className="bg-secondary text-white-100 px-2 py-1 text-xs rounded-lg cursor-pointer"
            >
              Durum Değiştir
            </span>
          </h3>
          <p className="text-white-100">
            <span className="font-semibold">İşlem Durumu:</span>{" "}
            {paymentDetails.paymentStatus === 0 ? (
              <OrderIndicator color="red">Ödemesi Yapıldı.</OrderIndicator>
            ) : paymentDetails.paymentStatus === 1 ? (
              <OrderIndicator color="green">Ödeme Bekliyor.</OrderIndicator>
            ) : paymentDetails.paymentStatus === 2 ? (
              <OrderIndicator color="yellow">
                Manual Ödeme Bekliyor.
              </OrderIndicator>
            ) : (
              <OrderIndicator color="green">No Data</OrderIndicator>
            )}
          </p>
          <p className="text-white-100">
            <span className="font-semibold">İşlem Tarihi:</span>{" "}
            {paymentDetails.processDate}
          </p>
        </div>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
        <div className="bg-gray-900 p-4 rounded shadow">
          <h3 className="text-xl font-semibold text-white-100 mb-2">
            Kullanıcı Bilgileri
          </h3>
          <p className="text-white-100">
            <span className="font-semibold">Ad Soyad:</span>{" "}
            {paymentDetails.name}
          </p>
          <p className="text-white-100">
            <span className="font-semibold">Telefon Numarası:</span>{" "}
            {paymentDetails.phone}
          </p>
          <p className="text-white-100">
            <span className="font-semibold">Email:</span> {paymentDetails.email}
          </p>
        </div>

        <div className="bg-gray-900 p-4 rounded shadow">
          <h3 className="text-xl font-semibold text-white-100 mb-2">
            Elmas Bilgileri
          </h3>
          <p className="text-white-100">
            <span className="font-semibold">Elmas Sayısı:</span>{" "}
            {paymentDetails.diamonds}
          </p>
          <p className="text-white-100">
            <span className="font-semibold">Türk Lirası Karşılığı:</span>{" "}
            {paymentDetails.amount} TL
          </p>
        </div>

        <div className="bg-gray-900 p-4 rounded shadow col-span-1 md:col-span-2">
          <h3 className="text-xl font-semibold text-white-100 mb-2">
            Ödeme Bilgileri
          </h3>
          <p className="text-white-100">
            <span className="font-semibold">Ödeme Yöntemi:</span>{" "}
            {paymentDetails.paymentMethod}
          </p>
          <p className="text-white-100">
            <span className="font-semibold">Ödeme Tarihi:</span>{" "}
            {paymentDetails.paymentDate}
          </p>
        </div>
      </div>
      <Popup isOpen={changeStatus} onClose={changeStatusHandler}>
        <div className="space-y-4 flex flex-col">
          <h2 className="text-white-100 font-semibold text-center text-2xl">
            Ödeme Durumunu Değiştir
          </h2>
          <select
            className="bg-gray-500 focus:outline-none border-gray-800 rounded-xl text-white-100 px-4 py-2"
            value={paymentDetails.paymentStatus}
            name=""
            id=""
          >
            <option value="0">Ödemesi Yapıldı</option>
            <option value="1">Ödeme Bekliyor</option>
            <option value="2">Manual Ödeme Bekliyor</option>
          </select>
          <Button size="small" onClick={changeStatusHandler}>
            Kaydet
          </Button>
        </div>
      </Popup>
    </div>
  );
};

export default OrderDetail;
