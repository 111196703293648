import React, { useEffect } from 'react'
import Orders from 'components/Panel/Customer/Orders'
import { useDispatch, useSelector } from 'react-redux'
import { orders } from 'redux/customer/orders/ordersSlice';
import Pagination from 'components/Pagination';


const OrdersContainer = () => {
  const dispatch = useDispatch();
  const { data } = useSelector(state => state.customerOrders.orders);
  const { total, pages, page, per_page } = useSelector(state => state.customerOrders.orders);
  useEffect(() => {
    dispatch(orders({ page: 1, per_page: 15, order_by: 'created_at', order_direction: 'desc' }))
  }, [dispatch])

  const handlePageChange = (newPage) => {
    dispatch(orders({ page: newPage, per_page: 15, order_by: 'created_at', order_direction: 'desc' }))
  };

  return (
    <div className='bg-black min-h-screen'>
      <div className='p-4'>
        <h2 className='text-xl font-semibold text-white-100'>Siparişler</h2>
      </div>
      <Orders data={data} />
      <Pagination currentPage={page} totalPages={pages} onPageChange={handlePageChange} />
    </div>
  )
}

export default OrdersContainer
