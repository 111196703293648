import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import statsService from './statsService';

export const getTotalSales = createAsyncThunk('admin/totalSales', async (data, { rejectWithValue }) => {
  try {
    const response = await statsService.totalSales(data);
    return response;
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return rejectWithValue(message);
  }
});

export const getTotalSalesDetail = createAsyncThunk('admin/totalSalesDetail', async (data, { rejectWithValue }) => {
  try {
    const response = await statsService.totalSales(data);
    return response;
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return rejectWithValue(message);
  }
});

export const getResellerPayouts = createAsyncThunk('admin/resellerPayouts', async (data, { rejectWithValue }) => {
  try {
    const response = await statsService.resellerPayouts(data);
    return response;
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return rejectWithValue(message);
  }
});

export const getResellerDiamondTransfers = createAsyncThunk('admin/resellerDiamondTransfers', async (data, { rejectWithValue }) => {
  try {
    const response = await statsService.resellerDiamondTransfers(data);
    return response;
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return rejectWithValue(message);
  }
});

export const getResellerDiamondTransfersDetail = createAsyncThunk('admin/resellerDiamondTransfersDetail', async (data, { rejectWithValue }) => {
  try {
    const response = await statsService.resellerDiamondTransfers(data);
    return response;
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return rejectWithValue(message);
  }
});

const initialState = {
  totalSales: [],
  totalSalesDetail:[],
  resellerPayouts: [],
  resellerDiamondTransfers: [],
  resellerDiamondTransfersDetail: [],
  isTotalSalesLoading: false,
  isTotalSalesSuccess: false,
  isTotalSalesError: false,
  isResellerPayoutsLoading: false,
  isResellerPayoutsSuccess: false,
  isResellerPayoutsError: false,
  isResellerDiamondTransfersLoading: false,
  isResellerDiamondTransfersSuccess: false,
  isResellerDiamondTransfersError: false,
  messageResellerPayouts: null,
  messageResellerDiamondTransfers: null,
  messageTotalSales: null,
  isTotalSalesDetailLoading:false,
  isTotalSalesDetailSuccess:false,
  isTotalSalesDetailError:false,
  messageTotalSalesDetail:null,
  isResellerDiamondTransfersDetailLoading: false,
  isResellerDiamondTransfersDetailSuccess: false,
  isResellerDiamondTransfersDetailError: false,
  messageResellerDiamondTransfersDetail: null,
};

export const adminStatsSlice = createSlice({
  name: 'adminStats',
  initialState,
  reducers: {
    reset: (state) => {
      state.isTotalSalesError = false;
      state.isTotalSalesSuccess = false;
      state.isTotalSalesLoading = false;
      state.isResellerPayoutsError = false;
      state.isResellerPayoutsSuccess = false;
      state.isResellerPayoutsLoading = false;
      state.isResellerDiamondTransfersError = false;
      state.isResellerDiamondTransfersSuccess = false;
      state.isResellerDiamondTransfersLoading = false;
      state.messageResellerPayouts = null;
      state.messageResellerDiamondTransfers = null;
      state.messageTotalSales = null;
      state.isTotalSalesDetailError = false;
      state.isTotalSalesDetailLoading = false;
      state.isTotalSalesDetailSuccess = false;
      state.messageTotalSalesDetail = null
    },
    resetTotalSales:(state) => {
      state.totalSales = []
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getTotalSales.pending, (state) => {
        state.isTotalSalesLoading = true;
      })
      .addCase(getTotalSales.fulfilled, (state, action) => {
        state.isTotalSalesLoading = false;
        state.isTotalSalesSuccess = true;
        state.totalSales = action.payload;
      })
      .addCase(getTotalSales.rejected, (state, action) => {
        state.isTotalSalesLoading = false;
        state.isTotalSalesError = true;
        state.isTotalSalesSuccess = false;
        state.messageTotalSales = action.payload;

      })
      builder
      .addCase(getTotalSalesDetail.pending, (state) => {
        state.isTotalSalesDetailLoading = true;
      })
      .addCase(getTotalSalesDetail.fulfilled, (state, action) => {
        state.isTotalSalesDetailLoading = false;
        state.isTotalSalesDetailSuccess = true;
        state.totalSalesDetail = action.payload;
      })
      .addCase(getTotalSalesDetail.rejected, (state, action) => {
        state.isTotalSalesDetailLoading = false;
        state.isTotalSalesDetailError = true;
        state.isTotalSalesDetailSuccess = false;
        state.messageTotalSalesDetail = action.payload;

      })
    builder
      .addCase(getResellerPayouts.pending, (state) => {
        state.isResellerPayoutsLoading = true;
      })
      .addCase(getResellerPayouts.fulfilled, (state, action) => {
        state.isResellerPayoutsLoading = false;
        state.isResellerPayoutsSuccess = true;
        state.resellerPayouts = action.payload;
      })
      .addCase(getResellerPayouts.rejected, (state, action) => {
        state.isResellerPayoutsLoading = false;
        state.isResellerPayoutsError = true;
        state.isResellerPayoutsSuccess = false;
        state.messageResellerPayouts = action.payload;

      })
    builder
      .addCase(getResellerDiamondTransfers.pending, (state) => {
        state.isResellerDiamondTransfersLoading = true;
      })
      .addCase(getResellerDiamondTransfers.fulfilled, (state, action) => {
        state.isResellerDiamondTransfersLoading = false;
        state.isResellerDiamondTransfersSuccess = true;
        state.resellerDiamondTransfers = action.payload;
      })
      .addCase(getResellerDiamondTransfers.rejected, (state, action) => {
        state.isResellerDiamondTransfersLoading = false;
        state.isResellerDiamondTransfersError = true;
        state.isResellerDiamondTransfersSuccess = false;
        state.messageResellerDiamondTransfers = action.payload;

      })
      builder
      .addCase(getResellerDiamondTransfersDetail.pending, (state) => {
        state.isResellerDiamondTransfersDetailLoading = true;
      })
      .addCase(getResellerDiamondTransfersDetail.fulfilled, (state, action) => {
        state.isResellerDiamondTransfersDetailLoading = false;
        state.isResellerDiamondTransfersDetailSuccess = true;
        state.resellerDiamondTransfersDetail = action.payload;
      })
      .addCase(getResellerDiamondTransfersDetail.rejected, (state, action) => {
        state.isResellerDiamondTransfersDetailLoading = false;
        state.isResellerDiamondTransfersDetailError = true;
        state.isResellerDiamondTransfersDetailSuccess = false;
        state.messageResellerDiamondDetailTransfers = action.payload;

      })
  }
});

export const { reset,resetTotalSales } = adminStatsSlice.actions;

export default adminStatsSlice.reducer;
