import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import profileService from './profileService';


//Admin Profile Thunk Action Creator
export const profile = createAsyncThunk('reseller/profile', async (_, {rejectWithValue}) => {
	try {
		const response = await profileService.profile();
		return response;
	} catch (error) {
		const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
		return rejectWithValue(message);
	}
});

export const updateProfile = createAsyncThunk('reseller/profile/update', async (data, {rejectWithValue}) => {
	try {
		const response = await profileService.update(data);
		return response;
	} catch (error) {
		const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
		return rejectWithValue(message);
	}
});



const initialState = {
	profile:{},
	isError: false,
	isLoading: false,
	isSuccess: false,
	message: '',
	isEditSuccess: false,
	isEditLoading: false,
	isEditError: false,
};

export const profileSlice = createSlice({
	name: 'resellerProfile',
	initialState,
	reducers: {
		reset: (state) => {
			state.isError = false;
			state.isSuccess = false;
			state.isLoading = false;
			state.message = '';
			state.isEditSuccess = false;
			state.isEditLoading = false;
			state.isEditError = false;
		},
		
	},
	extraReducers: (builder) => {
		builder
			.addCase(profile.pending, (state) => {
				state.isLoading = true;
			})
			.addCase(profile.fulfilled, (state, action) => {
				state.isLoading = false;
				state.isSuccess = true;
				state.profile = action.payload;
			})
			.addCase(profile.rejected, (state, action) => {
				state.isLoading = false;
				state.isError = true;
				state.message = action.payload;
				state.profile = null;
			})
			.addCase(updateProfile.pending, (state) => {
				state.isEditLoading = true;
			})
			.addCase(updateProfile.fulfilled, (state, action) => {
				state.isEditLoading = false;
				state.isEditSuccess = true;
				state.message = action.payload.message;
			})
			.addCase(updateProfile.rejected, (state, action) => {
				state.isEditLoading = false;
				state.isEditError = true;
				state.message = action.payload;
				state.profile = null;
			})
	}
});

export const { reset } = profileSlice.actions;

export default profileSlice.reducer;