import { Link } from "react-router-dom";
import Paragraph from "components/Typography/Paragraph"
import Button from "components/Button"
import Heading from "components/Typography/Header"
import Logo from "components/Logo"
import { FaFacebookF, FaInstagram, FaTwitter } from 'react-icons/fa'

const Footer = () => {
    return (
        <footer className='green-container fluid bg-black md:mt-[320px] mt-8'>
            <div className="grid grid-cols-12 gap-[30px] mt-8">
                <div className="md:col-start-1 md:col-end-4 col-start-1 col-end-13 space-y-8">
                    <Logo href="/"  />
                    <Paragraph>SkyPay; hızlı, kolay ve güvenilir bir çözüm olarak hizmet verir.</Paragraph>
                    <div className="flex gap-6">
                        <FaFacebookF className="text-primary text-xl" />
                        <FaInstagram className="text-primary text-xl" />
                        <FaTwitter className="text-primary text-xl" />
                    </div>
                </div>
                
                <div className="md:col-start-5 md:col-end-7 col-start-1 col-end-13">
                    <Heading level={5} className="text-gray-600">Yasal Bilgiler</Heading>
                    <div>
                        <Link to="/kullanici-sozlesmesi"><Paragraph>Kullanıcı Sözleşmesi</Paragraph></Link>
                        <Link to="/kvkk"><Paragraph>KVKK ve Gizlilik Politikası</Paragraph></Link>
                        
                    </div>
                </div>
                <div className="md:col-start-8 md:col-end-10 col-start-1 col-end-13">
                    <Heading level={5} className="text-gray-600">Destek</Heading>
                    <div>
                        <Link to="/iletisim"><Paragraph>İletişim</Paragraph></Link>
                        
                    </div>
                </div>
                <div className="md:col-start-10 md:col-end-13 col-start-1 col-end-13">
                    <Heading level={5} className="text-gray-600">SkyPay ile En Son Güncellemeler</Heading>
                    <div className="flex flex-col">
                        <input className="p-[15px] rounded-full border border-gray-500 bg-transparent mt-4" type="text" placeholder="E-Posta Adresinizi Yazın" />
                        <Button className="mt-4">Abone Ol</Button>
                    </div>
                    <Paragraph className="mt-4"></Paragraph>
                </div>
                <div className="col-start-1 col-end-13">
                    <hr className="mb-2" />
                    <div className="flex justify-between">
                        <Paragraph>Tüm Hakları Saklıdır.<span className="text-primary font-semibold"> © 2023 SkyPay </span></Paragraph>
                        
                    </div>
                </div>
            </div>

        </footer>
    )
}

export default Footer