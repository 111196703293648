import FAQItem from './item'
import Header from 'components/Typography/Header'
import Paragraph from 'components/Typography/Paragraph'

const faqs = [
    {
        question: 'SkyPay Nedir?',
        answer: "SkyPay, e-pinlerinizi hızlı ve güvenli bir şekilde satın almanızı sağlayan kullanıcı dostu bir işlem merkezidir. Banka havalesi kullanarak SkyPay hesabınıza para yatırarak, sevdiğiniz platformlar için ihtiyaç duyduğunuz ürünleri ve hizmetleri hızlı ve kolay bir şekilde temin edebilirsiniz. SkyPay, hedeflerinize ulaşmanıza destek olacak şekilde tasarlanmıştır."
    },
    {
        question: 'Bireysel Üye Olmak İçin Ne Yapmalıyım?',
        answer:
            <ul>
                <li>SkyPay web sitesini ziyaret edin.</li>
                <li>Anasayfada veya menüde bulunan "Üye Ol" veya seçeneğine tıklayın.</li>
                <li>Açılan kayıt formunda istenen bilgileri (isim, soyisim, e-posta adresi, telefon numarası ve şifre) doğru ve eksiksiz olarak doldurun.</li>
                <li>Telefon numaranıza gönderilen doğrulama kodunu girerek telefon numaranızı doğrulayın.</li>
                <li>Kullanıcı sözleşmesini ve gizlilik politikasını inceleyin ve kabul edin.</li>
                <li>"Üye Ol" butonuna tıklayarak üyelik işleminizi tamamlayın.</li>
            </ul>
    },
    {
        question: 'SkyPay hesabının güvenliği nasıl sağlanmaktadır?',
        answer:
            <ul>
                <li>Güvenli sunucular: SkyPay, kullanıcı verilerini güvende tutmak için güvenli sunucular kullanır ve hassas bilgileri şifreler. Bu, bilgilerinizin yetkisiz erişimden korunmasına yardımcı olur.</li>
                <li>SSL sertifikaları: SkyPay web sitesi, SSL (Secure Sockets Layer) sertifikaları kullanarak kullanıcıların bağlantılarını şifreler. Bu sayede, kullanıcıların hesaplarına giriş yaparken ve işlemleri sırasında veri aktarımı güvence altına alınır.</li>
                <li>Şüpheli etkinlik izleme: SkyPay, kullanıcı hesaplarında şüpheli etkinlikleri izlemek ve erken önlem almak için sistemlerini düzenli olarak gözden geçirir. Bu, hesabınıza yetkisiz erişimi önlemeye yardımcı olabilir.</li>
                <li>Müşteri hizmetleri: SkyPay, hesap güvenliği konusunda herhangi bir sorun yaşamanız durumunda size yardımcı olacak bir müşteri hizmetleri ekibine sahiptir. Ekip, hesabınızın güvenliğini sağlamak için size destek sağlayacaktır.</li>        </ul>
    },
    {
        question: "SkyPay'de hesabımı nasıl güvende tutabilirim?",
        answer:
            <ul>
                <li>Güçlü şifreler kullanın: Hesabınızın şifresi, harfler (büyük ve küçük harf), rakamlar ve özel karakterler içeren karmaşık bir kombinasyon olmalıdır. Şifrenizi düzenli aralıklarla değiştirin ve aynı şifreyi başka hesaplarınızda kullanmaktan kaçının.</li>
                <li>Güvenli internet bağlantısı kullanın: Hesabınıza erişirken güvenli ve şifreli bir Wi-Fi ağı kullanın. Açık veya güvensiz Wi-Fi ağları, hesabınızın bilgilerinin çalınma riskini artırabilir.</li>
                <li>Bilgisayarınızı ve mobil cihazlarınızı güncel tutun: İşletim sistemi ve güvenlik yazılımlarınızın en son sürümlerini kullanarak cihazlarınızın güvende olmasını sağlayın. Bu, bilgisayarınıza ve mobil cihazlarınıza zarar vermek isteyen kötü amaçlı yazılımlara karşı koruma sağlar.</li>
                <li>Şüpheli e-postalara ve bağlantılara dikkat edin: SkyPay hesabınızla ilgili olduğunu iddia eden şüpheli e-postalara dikkat edin. SkyPay veya başka bir şirketten gelen e-postaları açmadan önce kimliklerini doğrulayın ve asla şüpheli bağlantılara tıklamayın.</li>
                <li>SkyPay hesabınızın güvenliği ile ilgili herhangi bir sorun yaşarsanız, müşteri hizmetleri ekibine başvurun. Ekip, hesabınızın güvenliğini sağlamak için size yardımcı olacaktır.</li>
            </ul>
    },
    {
        question: 'SkyPay hesap bakiyesi ve işlem hareketleri nereden takip edilir?',
        answer: 'SkyPay hesabınızda bulunan bakiye ve işlem hareketlerini hesabım sayfasından takip edebilirsiniz.'
    },
    {
        question: 'Kimler SkyPay hesabı açamaz?',
        answer: 'SkyPay hesabı; 18 yaşından küçük olan kişiler, yasal yükümlülüklerle sınırlananlar (örneğin iflas etmiş veya yasal takibe uğramış kişiler), kısıtlanmış veya yaptırım uygulanan ülkelerde yaşayanlar ve hizmet koşullarını kabul etmemiş kişiler SkyPay hesabı açamaz. Kısıtlamalar, yasal düzenlemelere ve politikalarına bağlı olarak değişebilir.'
    },
    {
        question: 'Bir kişi birden fazla SkyPay hesabına sahip olabilir mi?',
        answer: 'Bir kişi birden fazla SkyPay hesabı açamaz. Her kişiye ait sadece bir tane SkyPay hesabı olabilir.'
    },
    {
        question: 'SkyPay hesap bilgilerimi nasıl değiştirebilirim?',
        answer: 'SkyPay hesap bilgilerinizi değiştirmek isterseniz, info@SkyPay.com.tr e-posta adresine bir e-posta göndererek SkyPay müşteri desteği ile iletişime geçebilirsiniz. Değişiklik talebinizi ve ilgili hesap bilgilerinizi içeren bir e-posta yazın, ardından müşteri hizmetleri ekibi sizinle iletişime geçerek süreci başlatmanıza ve bilgilerinizi güncellemenize yardımcı olacaktır.'
    },
    {
        question: 'SkyPay hesabımı nasıl kapatabilirim?',
        answer: 'SkyPay hesabınızı kapamak isterseniz, info@SkyPay.com.tr e-posta adresine bir e-posta göndererek SkyPay müşteri desteği ile iletişime geçebilirsiniz. Hesabınızı kapamak istediğinizi belirtin ve müşteri hizmetleri ekibi sizinle iletişime geçerek süreci başlatmanıza ve hesabınızı kapatmanıza yardımcı olacaktır.'
    },
    {
        question: 'SkyPay banka hesabı nasıl eklenir?',
        answer: 'SkyPay hesabınıza banka hesabı eklemek için, hesabım sayfasından banka hesabı ekleme seçeneğine tıklayın. Ardından, eklemek istediğiniz banka hesabını seçin ve sizden istenen bilgileri eksiksiz ve doğru bir şekilde doldurun. Banka hesabınızı ekledikten sonra, hesabım sayfasından banka hesabınızı seçerek işlem yapabilirsiniz.'
    },
    {
        question: 'SkyPay hesabına nereden, nasıl para yüklenir?',
        answer: 'SkyPay hesabına kendinize ait bir banka hesabından sadece havale ve EFT yöntemi ile para yüklenebilmektedir.'
    },
    {
        question: 'SkyPay hesabına kredi kartı ile nasıl para yüklenir?',
        answer: 'SkyPay hesabına kredi kartı ile para yükleme sistemi aktif değildir.'
    },
    {
        question: 'SkyPay ile ilgili öneri ve şikayetler nereye iletilmesi gerekiyor?',
        answer: 'SkyPay ile ilgili öneri ve şikayetleriniz için; info@SkyPay.com.tr e-posta adresine bir e-posta göndererek SkyPay müşteri desteği ile iletişime geçebilirsiniz.'
    },
]

const FAQ = () => {
    return (
        <section id="sss" className='grid grid-cols-12 gap-[30px] md:mt-60 mt-8 '>
            <div className='md:col-start-3 md:col-end-11 col-start-1 col-end-13'>
                <Header level={5} className="text-secondary text-center">Eğer sorunuz varsa, cevabımız var</Header>
                <Header level={2} className="text-gray-600 text-center">Sıkça Sorulan Sorular</Header>
                <Paragraph className="text-center">SkyPay hakkında en çok sorulan soruların cevaplarını aşağıda verdik. Eğer daha fazla sorunuz varsa bizimle iletişime geçebilirsiniz.<span className='text-primary font-medium'>  info@SkyPay.com.tr  </span></Paragraph>
            </div>
            <div className='md:col-start-3 md:col-end-11 col-start-1 col-end-13'>
                {faqs.map((faq, index) => (
                    <FAQItem key={index} question={faq.question} answer={faq.answer} />

                ))}
            </div>
        </section>
    )
}

export default FAQ