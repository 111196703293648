import React, { useEffect,useState } from 'react'
import Orders from 'components/Panel/Reseller/Orders'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router'
import { orders } from 'redux/reseller/orders/ordersSlice';
import Pagination from 'components/Pagination';


const OrdersContainer = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { data } = useSelector(state => state.resellerOrders.orders);
  const { total, pages, page, per_page } = useSelector(state => state.resellerOrders.orders);
  const [newP,setNewP] = useState()

  useEffect(() => {

    const fetchOrders = () => {
      dispatch(orders({ page: newP || page, per_page: 15,list_subreseller_orders:true }))
    };
    fetchOrders();
    const intervalId = setInterval(fetchOrders, 10000);
    
    return () => {
      clearInterval(intervalId);
    
    };
  }, [newP]);

  const handlePageChange = (newPage) => {
    setNewP(newPage)
    dispatch(orders({ page: newPage, per_page: 15,list_subreseller_orders:true }))
  
  };

  return (
    <div className='bg-black min-h-screen'>
      <div className='p-4 flex gap-4'>
        <h2 className='text-xl font-semibold text-white-100'>Gelen Dekontlar</h2>
        <span onClick={() => navigate(0)} className='bg-secondary text-white-100 px-2 py-1 rounded-md cursor-pointer'>Yenile</span>
      </div>
      <Orders data={data} />
      <Pagination currentPage={page} totalPages={pages} onPageChange={handlePageChange} />
    </div>
  )
}

export default OrdersContainer
