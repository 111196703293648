import BankAccountsCreateContainer from "containers/panel/admin/bank-accounts-create-container";


const BankAccountsCreatePage = () => {
  return (
    <BankAccountsCreateContainer />
  )
}

export default BankAccountsCreatePage;
