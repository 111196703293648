import React from 'react';

const Toggle = ({ isChecked, onChange }) => {
  const handleChangeActive = () => {
    if (onChange) {
      onChange(!isChecked);
    }
  };

  return (
    <label className="relative inline-flex items-center cursor-pointer">
      <input
        type="checkbox"
        value=""
        className="sr-only peer"
        checked={isChecked}
        onChange={handleChangeActive}
      />
      <div className="w-11 h-6 bg-white-100 peer-focus:ring-4 peer-focus:outline-none rounded-full peer peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white-100 after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-primary"></div>
    </label>
  );
};

export default Toggle;
