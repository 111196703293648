import { useState, useEffect } from 'react';
import { v4 as uuidv4 } from 'uuid';

const useUUID = (trigger) => {
  const [uuid, setUUID] = useState('');

  useEffect(() => {
    const newUUID = uuidv4();
    setUUID(newUUID);
  }, [trigger]);

  return uuid;
};

export {useUUID};
