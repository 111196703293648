import React from 'react';
import classNames from 'classnames';
import {BiLoaderCircle} from 'react-icons/bi';

const Button = ({ variant = "primary", size = "default", children, className, loading=false, ...props }) => {
    const buttonStyles = classNames(
        'font-medium text-[18px]',
        {
            'bg-primary text-white-100': variant === 'primary',
            'border border-gray-300 text-white-100': variant === 'secondary',
            'min-w-[103px] min-h-[48px] px-4 rounded-[24px]': size === 'small',
            'min-w-[117px] min-h-[52px] px-4 rounded-[26px]': size === 'default',
            'min-w-[112px] min-h-[54px] px-4 rounded-[27px]': size === 'large',
        },
        className
    );

    return (
        <button disabled={loading} type='submit' className={buttonStyles} {...props}>
            {
                loading ? <BiLoaderCircle className='animate-spin w-full text-center' /> : children
            }
        </button>
    );
};


export default Button;