import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { getBankAccounts } from 'redux/admin/bank-accounts/bankAccountsSlice';
import OrderIndicator from 'components/Panel/Admin/OrderIndicator';
import { Link } from 'react-router-dom';

const BankAccountsContainer = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getBankAccounts());
  }, [dispatch]);

  const { data } = useSelector((state) => state.adminBankAccounts);

  return (
    <div className="bg-black min-h-screen">
      <div className="p-4 flex justify-between">
        <h2 className="text-xl font-semibold text-white-100">
          Banka Hesapları
        </h2>
        <Link to='/admin/bank-accounts/create' className="bg-secondary px-2 py-1 text-white-100 rounded-md">
          Yeni Hesap Ekle
        </Link>
      </div>
      <div className="overflow-x-auto p-4">
        <table className="min-w-full divide-y-2 divide-gray-200 text-sm">
          <thead className="">
            <tr>
              <th className="whitespace-nowrap text-left px-4 py-2 font-medium text-white-100">
                Banka Adı
              </th>

              <th className="whitespace-nowrap text-left px-4 py-2 font-medium text-white-100">
                Hesap Adı
              </th>

              <th className="whitespace-nowrap text-left px-4 py-2 font-medium text-white-100">
                IBAN
              </th>
              <th className="whitespace-nowrap text-left px-4 py-2 font-medium text-white-100">
                Durum
              </th>
              <th className="whitespace-nowrap text-left px-4 py-2 font-medium text-white-100">

              </th>
            </tr>
          </thead>

          <tbody className="divide-y divide-gray-200">
            {data.length > 0 && data?.map((data) => (
              <tr key={data?.id}>
                <td className="whitespace-nowrap px-4 py-2 font-medium text-white-100">
                  {data?.name}
                </td>
                <td className="whitespace-nowrap px-4 py-2 font-medium text-white-100">
                  {data?.holder_name}
                </td>
                <td className="whitespace-nowrap px-4 py-2 font-medium text-white-100">
                  {data?.iban}
                </td>
                <td className="whitespace-nowrap px-4 py-2 font-medium text-white-100">
                  {data?.is_active ? <OrderIndicator color="green">Aktif</OrderIndicator> : <OrderIndicator color="red">Pasif</OrderIndicator>}
                </td>
                <td className="whitespace-nowrap px-4 py-2 font-medium text-white-100">
                  <Link to={`/admin/bank-accounts/detail/${data?.id}`} className='bg-secondary px-2 py-1 text-white-100 rounded-md'>
                    Düzenle
                  </Link>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  )
}

export default BankAccountsContainer;
