import { useState, useEffect } from "react";
import Heading from "components/Typography/Header";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { Link } from "react-router-dom";
import * as Yup from "yup";

const BayilikBasvurusu = () => {
  const [formValues, setFormValues] = useState({
    companyName: "",
    authorizedPerson: "",
    phone: "",
    email: "",
    volume: "",
    title: "",
    address: "",
    postalCode: "",
    city: "",
    country: "",
    taxOffice: "",
    taxNumber: "",
    fullName: "",
    gsm: "",
    email2: "",
    agreement: false,
  });
  const phoneRegex = /^(\+90|0)?[0-9]{10}$/;
  const validationSchema = Yup.object().shape({
    companyName: Yup.string().required("Firma adı gereklidir."),
    authorizedPerson: Yup.string().required("Yetkili kişi gereklidir."),
    phone: Yup.string()
      .matches(phoneRegex, "Geçerli bir telefon numarası giriniz.")
      .required("Telefon numarası gereklidir."),
    email: Yup.string()
      .email("Geçerli bir email adresi giriniz.")
      .required("Email gereklidir."),
    volume: Yup.string().required("İşlem hacmi gereklidir."),
    title: Yup.string().required("Ticaret ünvanı gereklidir."),
    address: Yup.string().required("Adres gereklidir."),
    postalCode: Yup.string().required("Posta kodu gereklidir."),
    city: Yup.string().required("İl gereklidir."),
    country: Yup.string().required("Ülke gereklidir."),
    taxOffice: Yup.string().required("Vergi dairesi gereklidir."),
    taxNumber: Yup.string().required("Vergi numarası gereklidir."),
    fullName: Yup.string().required("Ad soyad gereklidir."),
    gsm: Yup.string()
      .matches(phoneRegex, "Geçerli bir telefon numarası giriniz.")
      .required("Telefon numarası gereklidir."),
    email2: Yup.string()
      .email("Geçerli bir email adresi giriniz.")
      .required("Email gereklidir."),
    agreement: Yup.boolean().oneOf(
      [true],
      "KVKK ve Kullanıcı sözleşmesini onaylamalısınız."
    ),
  });
  const handleSubmit = (values) => {
    console.log(values);
  };
  return (
    <div className="green-container py-4">
      <Heading className="text-white-100 text-center" level={3}>
        Bayilik Başvurusu
      </Heading>

      <section className="">
        <div className="mx-auto max-w-screen-xl px-4 py-16 sm:px-6 lg:px-8">
          <div className="grid grid-cols-1 gap-x-16 gap-y-8 ">
            <div className="rounded-lg p-8  lg:col-span-3 lg:p-12">
              <Formik
                initialValues={formValues}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
              >
                {({ errors, touched }) => (
                  <Form>
                    <div className="space-y-4">
                      <Heading className="text-white-100" level={4}>
                        Genel Bilgiler
                      </Heading>
                      <div className="mt-8">
                        <label
                          className="block text-sm font-medium text-white-100"
                          htmlFor="companyName"
                        >
                          Firma Adı{" "}
                          {errors.companyName && touched.companyName ? (
                            <ErrorMessage
                              component="span"
                              className="text-red-500 my-2 text-sm"
                              name="companyName"
                            />
                          ) : null}
                        </label>
                        <Field
                          autoComplete="off"
                          className="w-full rounded-lg mt-1 border-gray-200 p-3"
                          type="text"
                          name="companyName"
                          id="companyName"
                        />
                      </div>
                      <div className="grid grid-cols-1 gap-4 sm:grid-cols-2">
                        <div>
                          <label
                            className="block text-sm font-medium text-white-100"
                            htmlFor="authorizedPerson"
                          >
                            Yetkili Kişi{" "}
                            {errors.authorizedPerson &&
                            touched.authorizedPerson ? (
                              <ErrorMessage
                                component="span"
                                className="text-red-500 my-2 text-sm"
                                name="authorizedPerson"
                              />
                            ) : null}
                          </label>
                          <Field
                            autoComplete="off"
                            className="w-full rounded-lg mt-1 border-gray-200 p-3 text-sm"
                            type="text"
                            name="authorizedPerson"
                            id="authorizedPerson"
                          />
                        </div>

                        <div>
                          <label
                            className="block text-sm font-medium text-white-100"
                            htmlFor="phone"
                          >
                            Telefon Numarası{" "}
                            {errors.phone && touched.phone ? (
                              <ErrorMessage
                                component="span"
                                className="text-red-500 my-2 text-sm"
                                name="phone"
                              />
                            ) : null}
                          </label>
                          <Field
                            className="w-full mt-1 rounded-lg border-gray-200 p-3 text-sm"
                            autoComplete="off"
                            type="tel"
                            name="phone"
                            id="phone"
                          />
                        </div>
                      </div>
                      <div className="grid grid-cols-1 gap-4 sm:grid-cols-2">
                        <div>
                          <label
                            className="block text-sm font-medium text-white-100"
                            htmlFor="email"
                          >
                            E-Posta
                            {" "}
                            {errors.email && touched.email ? (
                              <ErrorMessage
                                component="span"
                                className="text-red-500 my-2 text-sm"
                                name="email"
                              />
                            ) : null}
                          </label>
                          <Field
                            autoComplete="off"
                            className="w-full rounded-lg border-gray-200 p-3 mt-1 text-sm"
                            type="email"
                            name="email"
                            id="email"
                          />
                        </div>

                        <div>
                          <label className="block text-sm font-medium text-white-100">
                            Beklenen İşlem Hacmi(TL)
                            {" "}
                            {errors.volume && touched.volume ? (
                              <ErrorMessage
                                component="span"
                                className="text-red-500 my-2 text-sm"
                                name="volume"
                              />
                            ) : null}
                          </label>
                          <Field
                            className="w-full rounded-lg border-gray-200 p-3 mt-1 text-sm"
                            as="select"
                            name="volume"
                          >
                            <option value="">
                              Seçiniz
                            </option>
                            <option value="1">0 - 10.000</option>
                            <option value="2">10.001 - 25.000</option>
                            <option value="3">25.001 - 100.000</option>
                            <option value="4">100.000+</option>
                          </Field>
                        </div>
                      </div>
                    </div>
                    <div className="space-y-4 mt-8">
                      <Heading className="text-white-100" level={4}>
                        Detaylar
                      </Heading>
                      <div className="grid grid-cols-1 gap-4 sm:grid-cols-2">
                        <div>
                          <label
                            className="block text-sm font-medium text-white-100"
                            htmlFor="title"
                          >
                            Ticaret Ünvanı
                            {" "}
                            {errors.title && touched.title ? (
                              <ErrorMessage
                                component="span"
                                className="text-red-500 my-2 text-sm"
                                name="title"
                              />
                            ) : null}
                          </label>
                          <Field
                            autoComplete="off"
                            className="w-full rounded-lg mt-1 border-gray-200 p-3 text-sm"
                            type="text"
                            name="title"
                            id="title"
                          />
                        </div>

                        <div>
                          <label
                            className="block text-sm font-medium text-white-100"
                            htmlFor="address"
                          >
                            Adres
                            {" "}
                            {errors.address && touched.address ? (
                              <ErrorMessage
                                component="span"
                                className="text-red-500 my-2 text-sm"
                                name="address"
                              />
                            ) : null}
                          </label>
                          <Field
                            className="w-full mt-1 rounded-lg border-gray-200 p-3 text-sm"
                            autoComplete="off"
                            name="address"
                            id="address"
                          />
                        </div>
                      </div>
                      <div className="grid grid-cols-1 gap-4 sm:grid-cols-3">
                        <div>
                          <label
                            className="block text-sm font-medium text-white-100"
                            htmlFor="postalCode"
                          >
                            Posta Kodu
                            {" "}
                            {errors.postalCode && touched.postalCode ? (
                              <ErrorMessage
                                component="span"
                                className="text-red-500 my-2 text-sm"
                                name="postalCode"
                              />
                            ) : null}
                          </label>
                          <Field
                            autoComplete="off"
                            className="w-full rounded-lg border-gray-200 p-3 mt-1 text-sm"
                            type="text"
                            name="postalCode"
                            id="postalCode"
                          />
                        </div>
                        <div>
                          <label
                            className="block text-sm font-medium text-white-100"
                            htmlFor="city"
                          >
                            İl
                            {" "}
                            {errors.city && touched.city ? (
                              <ErrorMessage
                                component="span"
                                className="text-red-500 my-2 text-sm"
                                name="city"
                              />
                            ) : null}
                          </label>
                          <Field
                            autoComplete="off"
                            className="w-full rounded-lg border-gray-200 p-3 mt-1 text-sm"
                            type="text"
                            name="city"
                            id="city"
                          />
                        </div>
                        <div>
                          <label
                            className="block text-sm font-medium text-white-100"
                            htmlFor="country"
                          >
                            Ülke
                            {" "}
                            {errors.country && touched.country ? (
                              <ErrorMessage
                                component="span"
                                className="text-red-500 my-2 text-sm"
                                name="country"
                              />
                            ) : null}
                          </label>
                          <Field
                            autoComplete="off"
                            className="w-full rounded-lg border-gray-200 p-3 mt-1 text-sm"
                            type="text"
                            name="country"
                            id="country"
                          />
                        </div>
                      </div>
                      <div className="grid grid-cols-1 gap-4 sm:grid-cols-2">
                        <div>
                          <label
                            className="block text-sm font-medium text-white-100"
                            htmlFor="taxOffice"
                          >
                            Vergi Dairesi
                            {" "}
                            {errors.taxOffice && touched.taxOffice ? (
                              <ErrorMessage
                                component="span"
                                className="text-red-500 my-2 text-sm"
                                name="taxOffice"
                              />
                            ) : null}
                          </label>
                          <Field
                            autoComplete="off"
                            className="w-full rounded-lg border-gray-200 p-3 mt-1 text-sm"
                            type="text"
                            name="taxOffice"
                            id="taxOffice"
                          />
                        </div>
                        <div>
                          <label
                            className="block text-sm font-medium text-white-100"
                            htmlFor="taxNumber"
                          >
                            Vergi Numarası
                            {" "}
                            {errors.taxNumber && touched.taxNumber ? (
                              <ErrorMessage
                                component="span"
                                className="text-red-500 my-2 text-sm"
                                name="taxNumber"
                              />
                            ) : null}
                          </label>
                          <Field
                            autoComplete="off"
                            className="w-full rounded-lg border-gray-200 p-3 mt-1 text-sm"
                            type="text"
                            name="taxNumber"
                            id="taxNumber"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="space-y-4 mt-8">
                      <Heading className="text-white-100" level={4}>
                        İletişime Geçilecek Kişi
                      </Heading>
                      <div className="grid grid-cols-1 gap-4 sm:grid-cols-2">
                        <div>
                          <label
                            className="block text-sm font-medium text-white-100"
                            htmlFor="fullName"
                          >
                            Ad Soyad
                            {" "}
                            {errors.fullName && touched.fullName ? (
                              <ErrorMessage
                                component="span"
                                className="text-red-500 my-2 text-sm"
                                name="fullName"
                              />
                            ) : null}
                          </label>
                          <Field
                            autoComplete="off"
                            className="w-full rounded-lg mt-1 border-gray-200 p-3 text-sm"
                            type="text"
                            name="fullName"
                            id="fullName"
                          />
                        </div>

                        <div>
                          <label
                            className="block text-sm font-medium text-white-100"
                            htmlFor="gsm"
                          >
                            GSM
                            {" "}
                            {errors.gsm && touched.gsm ? (
                              <ErrorMessage
                                component="span"
                                className="text-red-500 my-2 text-sm"
                                name="gsm"
                              />
                            ) : null}
                          </label>
                          <Field
                            className="w-full mt-1 rounded-lg border-gray-200 p-3 text-sm"
                            autoComplete="off"
                            name="gsm"
                            id="gsm"
                          />
                        </div>
                      </div>
                      <div>
                        <label
                          className="block text-sm font-medium text-white-100"
                          htmlFor="email2"
                        >
                          E-Posta
                          {" "}
                            {errors.email2 && touched.email2 ? (
                              <ErrorMessage
                                component="span"
                                className="text-red-500 my-2 text-sm"
                                name="email2"
                              />
                            ) : null}
                        </label>
                        <Field
                          autoComplete="off"
                          className="w-full rounded-lg border-gray-200 p-3 mt-1 text-sm"
                          type="email"
                          name="email2"
                          id="email2"
                        />
                      </div>
                    </div>
                    <div className="mt-4 flex items-center gap-4 text-white">
                      <Field name="agreement" type="checkbox" />
                      <label className="text-white-100">
                        <Link to="/kvkk" className="text-primary">
                          KVKK
                        </Link>{" "}
                        ve{" "}
                        <Link
                          className="text-primary"
                          to="/kullanici-sozlesmei"
                        >
                          Kullanıcı Sözleşmesini
                        </Link>{" "}
                        Kabul Ediyorum
                      </label>
                      {" "}
                            {errors.agreement && touched.agreement ? (
                              <ErrorMessage
                                component="span"
                                className="text-red-500 my-2 text-sm"
                                name="agreement"
                              />
                            ) : null}
                    </div>
                    <div className="mt-4">
                      <button
                        type="submit"
                        className="inline-block w-full rounded-lg bg-primary px-5 py-3 font-medium text-white "
                      >
                        Gönder
                      </button>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};
export default BayilikBasvurusu;
