import instance from "api/instance";

const payouts = async (params) => {
  const response = await instance.get("/admin/payout-requests/list", {
    params: params
  });
  if (response.status === 200) {
    return response.data;
  }

  return Promise.reject(response.data);
};

const finalize = async (data) => {
  const response = await instance.post("/admin/payout-requests/finalize", data);

  if (response.status === 200) {
    return response.data;
  }

  return Promise.reject(response.data);
};

const payoutService = {
  payouts,
  finalize,
};

export default payoutService;
