import instance from "api/instance";

const settings = async () => {
	const response = await instance.get("/subreseller/settings");
	if (response.status === 200) {
		return response.data;
	}
	
	return Promise.reject(response.data);
};



const settingsService = {
	settings,
};

export default settingsService;