const Top5 = ({ data, subreseller, reseller }) => {

  const sortedData = [...data].sort((a, b) => b.total_try_sales - a.total_try_sales).slice(0, 5);

  const podiumOrder = [3, 1, 0, 2, 4]; // Bu sıralama dinamik olabilir

  const podiumHeight = ['h-32', 'h-52', 'h-60', 'h-40', 'h-20']

  return (
    <div className="flex justify-center items-end h-full">
      <div className="flex gap-4">
        {podiumOrder?.map((order, index) => {
          const item = sortedData[order];
          const hasData = item !== undefined;
          const placeholderText = hasData ? '' : 'Veri Yok';
          return (
            <div className="flex flex-col justify-end hover:scale-105 transition-all cursor-pointer">
              <div>
                <h3 className="md:text-lg text-sm font-semibold text-white-100 text-center">{hasData ? (item.user_name) : placeholderText}</h3>
                <p className="md:text-sm text-xs text-white-100 text-center">{hasData ? item.total_try_sales + "₺  " : placeholderText}</p>
              </div>
              <div
                key={index}
                className={`flex items-center flex-col rounded p-3 md:max-w-40 md:w-40 max-w-14 w-full ${podiumHeight[index]} self-end ${hasData ? 'bg-primary' : 'bg-gray-700'}`}
              >
                <h2 className="text-white-100 text-xl font-semibold">
                  {order + 1}
                </h2>
              </div>
            </div>
          )
        })}
      </div>
    </div>
  )
};


export default Top5;
