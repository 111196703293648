import React from "react";

import { Outlet } from "react-router-dom";
import Footer from "components/Landing/Footer";
import Navbar from "components/Landing/Navbar";
import { Helmet } from "react-helmet";

function MainLayout() {
  return (
    <div className="green-container">
      <Helmet>
        <title>SkyPay | Anasayfa</title>
        <meta name="description" content="SkyPay | Anasayfa" />
      </Helmet>
      <Navbar />
      <main>
        <Outlet />
      </main>
      <Footer />
    </div>
  );
}

export default MainLayout;
