import React, { useState } from "react";
import OrderIndicator from "components/OrderIndicator";

import moment from 'moment'
import 'moment/locale/tr'  

moment.locale("tr");

const OrderDetail = ({ data }) => {
  return (
    <div className="shadow-md rounded px-8 pt-6 pb-8 mb-4">
      <h2 className="text-2xl font-semibold text-white-100 mb-4 flex items-center gap-4">
        Ödeme Detayları #{data?.id} |{" "}
      </h2>
      <hr className="mb-4" />
      <div className="grid grid-cols-1 mb-4">
        <div className="bg-gray-900 p-4 rounded-lg shadow space-y-2">
          <h3 className="text-xl font-semibold text-white-100 mb-4 flex items-center gap-4">
            İşlem Bilgileri{" "}
          </h3>
          <p className="text-white-100 space-x-1">
            <span className="font-semibold">İşlem Durumu:</span>
            {data?.status === "completed" ? (
              <OrderIndicator color="green">Aktarım Yapıldı.</OrderIndicator>
            ) : data?.status === "pending_transfer" ? (
              <OrderIndicator color="yellow">Aktarım Bekliyor.</OrderIndicator>
            ) : data?.status === "failed" ? (
              <OrderIndicator color="red">Aktarım Reddedildi.</OrderIndicator>
            ) : (
              <OrderIndicator color="red">No Data</OrderIndicator>
            )}
          </p>
          <p className="text-white-100">
            <span className="font-semibold">İşlem Oluşturma Tarihi:</span>{" "}
            {moment(data?.created_at).format('Do MMMM YYYY, h:mm:ss')}
          </p>
          <p className="text-white-100">
            <span className="font-semibold">İşlem Güncellenme Tarihi:</span>{" "}
            {moment(data?.updated_at).format('Do MMMM YYYY, h:mm:ss')}
          </p>
        </div>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
        <div className="bg-gray-900 p-4 rounded-lg shadow space-y-2">
          <h3 className="text-xl font-semibold text-white-100 mb-4">
            Kullanıcı Bilgileri
          </h3>
          <p className="text-white-100">
            <span className="font-semibold">Kullanıcı ID:</span>{" "}
            {data?.bigo_username}
          </p>
          <p className="text-white-100">
            <span className="font-semibold">Ad Soyad:</span>{" "}
            {data?.customer.first_name} {data?.customer.last_name}
          </p>
          <p className="text-white-100">
            <span className="font-semibold">Telefon Numarası:</span>{" "}
            {data?.customer.phone}
          </p>
          <p className="text-white-100">
            <span className="font-semibold">Email:</span> {data?.customer.email || "Bilgi Yok"}
          </p>
        </div>

        <div className="bg-gray-900 p-4 rounded-lg shadow space-y-2">
          <h3 className="text-xl font-semibold text-white-100 mb-4">
            Ödeme Bilgileri
          </h3>
          <p className="text-white-100">
            <span className="font-semibold">Ödeme ID:</span>{" "}
            {data?.payment_id}
          </p>
          <p className="text-white-100">
            <span className="font-semibold">Elmas Sayısı:</span>{" "}
            {data?.amount_diamonds}
          </p>
          <p className="text-white-100">
            <span className="font-semibold">Yatırılan Tutar:</span>{" "}
            {data?.payment.amount_try} TL
          </p>
          <p className="text-white-100">
            <span className="font-semibold">Ödeme Yapılma Tarihi:</span>{" "}
            {moment(data?.payment.created_at).format('Do MMMM YYYY, h:mm:ss')}
          </p>
          <p className="text-white-100">
            <span className="font-semibold">Ödeme Ulaşma Tarihi:</span>{" "}
            {moment(data?.payment.received_at).format('Do MMMM YYYY, h:mm:ss')}
          </p>
         
        </div>

      
      </div>
      
    </div>
  );
};

export default OrderDetail;
