import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { login, reset } from "redux/admin/auth/authSlice";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import Heading from "components/Typography/Header";
import Button from "components/Button";


const AdminLoginSchema = Yup.object().shape({
  username: Yup.string().required("Kullanıcı adı boş bırakılamaz"),
  password: Yup.string().required("Şifre boş bırakılamaz"),
});

const AdminLoginComponent = () => {

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { isError, isSuccess, message, isLoading } = useSelector(
    (state) => state.adminAuth
  );

  useEffect(() => {
    if (isError) {
      toast.error(message);
    }
    if (isSuccess) {
      toast.success(message);
      setTimeout(() => {
        navigate("/admin");
      }, 1000);
    }
    return () => {
      dispatch(reset());
    };
  }, [isError, isSuccess, message, dispatch]);

  return (
    <div className="flex flex-1 flex-col h-full min-h-screen md:justify-center mt-12 items-center">
      <Heading level={4} className="text-primary">
        Admin Giriş
      </Heading>
      <div className="space-y-10 mt-10 ">
        <Formik
          initialValues={{
            username: "",
            password: "",
          }}
          validationSchema={AdminLoginSchema}
          onSubmit={(values) => {
            dispatch(
              login({ username: values.username, password: values.password })
            );
          }}
        >
          {({ errors, touched }) => (
            <Form className="space-y-4">
              <div className="w-[300px]">
                <span className="text-white-100 font-medium ">
                  Kullanıcı Adı
                </span>
                <Field
                  name="username"
                  type="text"
                  className="focus:outline-none w-full h-10 bg-transparent border-b border-secondary text-white-100"
                />
                {errors.username && touched.username ? (
                  <ErrorMessage
                    component="span"
                    className="text-red-500 my-2"
                    name="username"
                  />
                ) : null}
              </div>
              <div className="w-[300px] mb-4">
                <span className="text-white-100 font-medium ">Şifre</span>
                <Field
                  name="password"
                  type="password"
                  className="focus:outline-none w-full h-10 bg-transparent border-b border-secondary text-white-100"
                />
                {errors.password && touched.password ? (
                  <ErrorMessage
                    component="span"
                    className="text-red-500 my-2"
                    name="password"
                  />
                ) : null}
              </div>
              <Button loading={isLoading} size="small" className="w-full mt-4">
                Giriş Yap
              </Button>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default AdminLoginComponent;
