import instance from "api/instance";

const download = async (params) => {
  const response = await instance.get("/admin/download-file", {
    params: params,
    responseType: 'arraybuffer',  // Axios'a dönen veriyi bir arraybuffer olarak işlemesi gerektiğini belirtiriz.
    headers: {
        'Accept': 'application/pdf'  // Server'dan PDF formatında veri döndürmesini isteyiz.
    }
  });
  if (response.status === 200) {
    return response;
  }

  return Promise.reject(response);
};

const downloadService = {
  download,
};

export default downloadService;
