import instance from "api/instance";

const subresellers = async (params) => {
	const response = await instance.get("/reseller/subresellers/list",{
		params:params
	});
	if (response.status === 200) {
		return response.data;
	}
	
	return Promise.reject(response.data);
};

const create = async (data) => {
	const response = await instance.post("/reseller/subresellers/new",data);
	if (response.status === 201) {
		return response.data;
	}
	
	return Promise.reject(response.data);
};

const updateSubreseller = async (data) => {
	const response = await instance.patch("/reseller/subresellers/update",data);

	if (response.status === 200) {
		return response.data;
	}
	
	return Promise.reject(response.data);
};

const detail = async (params) => {
	const response = await instance.get("/reseller/subresellers/list",{
		params:params
	});

	if (response.status === 200) {
		return response.data;
	}
	
	return Promise.reject(response.data);
};






const subresellersService = {
	subresellers,
	create,
    updateSubreseller,
	detail
};

export default subresellersService;