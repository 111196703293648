import React, { useEffect } from "react";

import Payouts from "components/Panel/Admin/Payouts";
import { useDispatch, useSelector } from "react-redux";
import { getPayouts } from "redux/admin/payout/payoutSlice";
import Pagination from "components/Pagination";


const PayoutsContainer = () => {
  const dispatch = useDispatch();
  const { data } = useSelector((state) => state.adminPayouts.payouts);
  const { total, pages, page, per_page } = useSelector(
    (state) => state.adminPayouts.payouts
  );
  useEffect(() => {
    dispatch(getPayouts({ page: 1, per_page: 15 }));
  }, [dispatch,]);

  const handlePageChange = (newPage) => {
    dispatch(getPayouts({ page: newPage, per_page: 15 }));
  };

  return (
    <div className="bg-black min-h-screen">
      <div className="p-4 flex justify-between">
        <h2 className="text-xl font-semibold text-white-100">
          Para Çekme Talepleri
        </h2>
      </div>
      <Payouts data={data} />
      <Pagination
        currentPage={page}
        totalPages={pages}
        onPageChange={handlePageChange}
      />
    </div>
  );
};

export default PayoutsContainer;
