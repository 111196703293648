import React from 'react'
import { useSelector } from 'react-redux'

const Header = () => {
  const { profile } = useSelector((state) => state.adminProfile);
  return (
    <header>
      <div className="mx-auto px-4 sm:px-6 sm:py-8 lg:px-8">
        <div className="sm:flex sm:items-center sm:justify-between">
          <div className="text-center sm:text-left">
            <h1 className="text-2xl font-bold text-white-100 sm:text-3xl">
              Hoşgeldin, {profile?.display_name}
            </h1>

            {
              /*
               <p class="mt-1.5 text-sm text-gray-500">
                  Let's write a new blog post! 🎉
              </p>
              */
            }
          </div>

          <div className="mt-4 flex flex-col gap-4 sm:mt-0 sm:flex-row sm:items-center">


          </div>
        </div>
      </div>
    </header>

  )
}

export default Header
