import {createSlice, createAsyncThunk} from '@reduxjs/toolkit';
import diamondTransferService from './diamondTransferService.js';


export const getTransfers = createAsyncThunk('reseller/diamond-transfer/list', async (params, {rejectWithValue}) => {
    try {
        const response = await diamondTransferService.get(params);
        return response;
    } catch (error) {
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
        return rejectWithValue(message);
    }
});

export const createTransfer = createAsyncThunk('reseller/diamond-transfer/create', async (data, {rejectWithValue}) => {
    try {
        const response = await diamondTransferService.create(data);
        return response;
    } catch (error) {
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
        return rejectWithValue(message);
    }
});



const initialState = {
    transferList: [],
    isLoading: false,
    isError: false,
    isSuccess: false,
    isCreateTransferSuccess: false,
    isCreateTransferLoading: false,
    isCreateTransferError: false,
    message:''
};

export const diamondTransferSlice = createSlice({
    name: 'resellerDiamondTransfer',
    initialState,
    reducers: {
        reset: (state) => {
            state.isError = false;
            state.isSuccess = false;
            state.isLoading = false;
            state.message = '';
            state.isCreateTransferError = false;
            state.isCreateTransferLoading = false;
            state.isCreateTransferSuccess = false;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getTransfers.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(getTransfers.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isSuccess = true;
                state.transferList = action.payload;
            })
            .addCase(getTransfers.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.message = action.payload;
            });
        builder
            .addCase(createTransfer.pending, (state) => {
                state.isCreateTransferLoading = true;
            })
            .addCase(createTransfer.fulfilled, (state, action) => {
                state.isCreateTransferLoading = false;
                state.isCreateTransferSuccess = true;
                state.orderSummary = action.payload;
            })
            .addCase(createTransfer.rejected, (state, action) => {
                state.isCreateTransferLoading = false;
                state.isCreateTransferError = true;
                state.isCreateTransferSuccess = false;
                state.message = action.payload;
            });
  
    }
})

export const {reset} = diamondTransferSlice.actions;
export default diamondTransferSlice.reducer;