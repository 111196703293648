import React from "react";
import TotalPayoutsContainer from "containers/panel/admin/total-payouts-container";

const TotalPayoutsPage = () => {
  return (
    <TotalPayoutsContainer />
  )
}

export default TotalPayoutsPage
