import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import Heading from "components/Typography/Header";
import Button from "components/Button";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { createReseller, reset } from "redux/admin/users/usersSlice";

const CreateResellersContainer = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const {
    isSuccessCreateReseller,
    isErrorCreateReseller,
    isLoadingCreateReseller,
    messageResellers,
  } = useSelector((state) => state.adminUsers);

  const createResellersForm = Yup.object().shape({
    phone: Yup.string().required("Telefon numarası gereklidir."),
    name: Yup.string().required("Ad gereklidir."),
    balance_try: Yup.string().required("Bakiye gereklidir."),
    allowance_try: Yup.string().required("Eksiye düşme bakiyesi gereklidir."),
    balance_diamonds: Yup.string().required("Elmas bakiye gereklidir."),
    commission_rate: Yup.string().required("Komisyon oranı gereklidir."),
  });

  useEffect(() => {
    if (isErrorCreateReseller) {
      toast.error(messageResellers);
    }
    if (isSuccessCreateReseller) {
      toast.success("Bayi başarıyla oluşturuldu.");
      setTimeout(() => {
        navigate("/admin/resellers");
      }, 1500);
    }
    return () => {
      dispatch(reset());
    };
  }, [
    isSuccessCreateReseller,
    isErrorCreateReseller,
    messageResellers,
    dispatch,
  ]);

  return (
    <div className="p-4 ">
      <Heading className="text-white-100" level={5}>
        Bayi Ekle
      </Heading>
      <div className="p-4 bg-gray-900 my-4 rounded-xl min-h-screen">
        <div className="gap-4">
          <div className="p-4 bg-black md:h-screen">
            <Formik
              initialValues={{
                phone: "",
                name: "",
                balance_try: "",
                allowance_try: "",
                balance_diamonds: "",
                commission_rate: "",
              }}
              validationSchema={createResellersForm}
              onSubmit={(values) => {
                dispatch(createReseller({
                    phone: values.phone,
                    name: values.name,
                    balance_try: values.balance_try,
                    allowance_try: values.allowance_try,
                    balance_diamonds: values.balance_diamonds,
                    commission_rate: values.commission_rate,

                }));
              }}
            >
              {({ errors, touched, setFieldValue, values }) => {
                return (
                  <Form className="space-y-4 max-w-[500px]">
                    <div className="">
                      <span className="text-white-100 font-medium">
                        Bayi Adı
                      </span>
                      <Field
                        name="name"
                        type="text"
                        className="w-full font-medium bg-white-100 rounded-lg appearance-none focus:outline-none  p-2 mt-2"
                      />
                      <ErrorMessage
                        name="name"
                        component="div"
                        className="text-red-500"
                      />
                    </div>
                    <div className="">
                      <span className="text-white-100 font-medium">
                        Telefon Numarası
                      </span>
                      <Field
                        name="phone"
                        type="text"
                        className="w-full font-medium bg-white-100 rounded-lg appearance-none focus:outline-none  p-2 mt-2"
                      />
                      <ErrorMessage
                        name="phone"
                        component="div"
                        className="text-red-500"
                      />
                    </div>
                    <div className="">
                      <span className="text-white-100 font-medium">
                        Bakiye (₺)
                      </span>
                      <Field
                        name="balance_try"
                        type="number"
                        className="w-full font-medium bg-white-100 rounded-lg appearance-none focus:outline-none  p-2 mt-2"
                      />
                      <ErrorMessage
                        name="balance_try"
                        component="div"
                        className="text-red-500"
                      />
                    </div>
                    <div className="">
                      <span className="text-white-100 font-medium">
                        Eksiye Düşme Bakiyesi (₺)
                      </span>
                      <Field
                        name="allowance_try"
                        type="number"
                        className="w-full font-medium bg-white-100 rounded-lg appearance-none focus:outline-none  p-2 mt-2"
                      />
                      <ErrorMessage
                        name="allowance_try"
                        component="div"
                        className="text-red-500"
                      />
                    </div>
                    <div className="">
                      <span className="text-white-100 font-medium">
                        Bakiye (Elmas)
                      </span>
                      <Field
                        name="balance_diamonds"
                        type="number"
                        className="w-full font-medium bg-white-100 rounded-lg appearance-none focus:outline-none  p-2 mt-2"
                      />
                      <ErrorMessage
                        name="balance_diamonds"
                        component="div"
                        className="text-red-500"
                      />
                    </div>
                    <div className="">
                      <span className="text-white-100 font-medium">
                        Komisyon Oranı (%)
                      </span>
                      <Field
                        name="commission_rate"
                        type="number"
                        className="w-full font-medium bg-white-100 rounded-lg appearance-none focus:outline-none  p-2 mt-2"
                      />
                      <ErrorMessage
                        name="commission_rate"
                        component="div"
                        className="text-red-500"
                      />
                    </div>
                    <Button
                      type="submit"
                      size="small"
                      loading={isLoadingCreateReseller}
                      className="bg-primary"
                    >
                      Bayi Oluştur
                    </Button>
                  </Form>
                );
              }}
            </Formik>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateResellersContainer;
