import React, { useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import Calculator from "components/Panel/Customer/Calculator";
import BankAccounts from "components/Panel/Customer/BankAccounts";
import OrderCreate from "components/Panel/Customer/OrderCreate";


const steps = [
  {
    name: "Tutarı Girin",
    content: (amount, setAmount, next) => (
      <Calculator next={next} amount={amount} setAmount={setAmount} />
    )
  },
  {
    name: "Banka Bilgisi",
    content: (amount, setAmount, next, prev) => (
      <BankAccounts amount={amount} next={next} prev={prev} />
    )
  },
  {
    name: "Satın Al",
    content: (amount, setAmount, next, prev) => (
      <OrderCreate amount={amount} prev={prev} />
    )
  }
];

export default function App() {
  const [step, setStep] = useState(0);
  const [amount, setAmount] = useState("");


  function nextStep() {
    setStep((prevStep) => prevStep + 1);
  }

  function prevStep() {
    setStep((prevStep) => prevStep - 1);
  }

  return (
    <div className="flex items-center md:min-h-screen">
      <div className="grid grid-cols-1 md:grid-cols-3 gap-4 md:overflow-x-auto p-4">
        {steps.map((stepData, index) => (
          <AnimatePresence mode="wait" key={index}>
            {step >= index && (
              <motion.div
                initial={{ opacity: 0, scale: 0.5 }}
                animate={{ opacity: 1, scale: 1 }}
                exit={{ opacity: 0, scale: 0.5 }}
                transition={{ duration: 0.3 }}
              >

                {stepData.content(amount, setAmount, nextStep, prevStep)}
              </motion.div>
            )}
          </AnimatePresence>
        ))}
      </div>
    </div>
  );
}
