import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { detailSubreseller} from "redux/admin/users/usersSlice";
import SubresellersDetail from "components/Panel/Admin/SubresellerDetail";

const SubresellerDetailContainer = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const { subreseller:data } = useSelector(
    (state) => state.adminUsers
  );

  useEffect(() => {
    dispatch(detailSubreseller({ search: id }));
  
  }, [dispatch, id]);



  return <SubresellersDetail data={data} />;
};

export default SubresellerDetailContainer;
