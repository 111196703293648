import {createSlice, createAsyncThunk} from '@reduxjs/toolkit';
import retailsService from './retailsService';




export const retailCreate = createAsyncThunk('reseller/orders/create/newRetail', async (data, {rejectWithValue}) => {
    try {
        const response = await retailsService.createRetailOrder(data);
        return response;
    } catch (error) {
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
        return rejectWithValue(message);
    }
});




const initialState = {
    isError:false,
    isLoading:false,
    isSuccess:false,
    message: '',
    retail:{}
};

export const retailsSlice = createSlice({
    name: 'resellerRetails',
    initialState,
    reducers: {
        reset: (state) => {
            state.isError = false;
            state.isSuccess = false;
            state.isLoading = false;
            state.message = '';
            state.retail = {};
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(retailCreate.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(retailCreate.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isSuccess = true;
                state.retail = action.payload;
            })
            .addCase(retailCreate.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.message = action.payload;
            });
      
    }
})

export const {reset} = retailsSlice.actions;
export default retailsSlice.reducer;