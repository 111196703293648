import instance from "api/instance";


const createRetailOrder = async (data) => {
	const response = await instance.post("/reseller/orders/newretail",data);

	if (response.status === 201 || (response.status == 200 && response.data.need_verify)) {
		return response.data;
	}
	
	return Promise.reject(response.data);
};

const checkRetailBalance = async (data) => {
	const response = await instance.post("/reseller/orders/retailbalancecheck",data);

	if (response.status == 200) {
		return response.data;
	}
	
	return Promise.reject(response.data);
};






const retailsService = {
	createRetailOrder,
	checkRetailBalance
};

export default retailsService;