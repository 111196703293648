import instance from "api/instance";

const create = async (data) => {
	const response = await instance.post("/reseller/subreseller-pricing",data);
	if (response.status === 201) {
		return response.data;
	}
	
	return Promise.reject(response.data);
};

const update = async (data) => {
    const response = await instance.put("/reseller/subreseller-pricing", data);
    if (response.status === 200) {
        return response.data;
    }

    return Promise.reject(response.data);
};

const remove = async (data) => {
    const response = await instance.delete(`/reseller/subreseller-pricing/${data}`);
    if (response.status === 200) {
        return response.data;
    }

    return Promise.reject(response.data);
};

const pricingService = {
	create,
    update,
    remove
};

export default pricingService;