import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { detailBankAccount, updateBankAccount, reset } from "redux/admin/bank-accounts/bankAccountsSlice";
import { toast } from "react-toastify";
import moment from "moment";
import "moment/locale/tr";


moment.locale("tr");

const BankAccountsDetailContainer = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const [name, setName] = useState("");
  const [isActive, setIsActive] = useState(false);
  const [code, setCode] = useState("");
  const [iban, setIban] = useState("");
  const [holderName, setHolderName] = useState("");

  const { detail, isUpdateLoading, isUpdateError, isUpdateSuccess } = useSelector((state) => state.adminBankAccounts);

  useEffect(() => {
    dispatch(detailBankAccount({ search: id }));
  }, [dispatch, id]);

  useEffect(() => {
    setName(detail?.name || "");
    setIsActive(detail?.is_active || false);
    setCode(detail?.code || "");
    setIban(detail?.iban || "");
    setHolderName(detail?.holder_name || "");
  }, [detail]);

  const handleUpdate = () => {
    dispatch(
      updateBankAccount({
        name: name,
        is_active: isActive,
        code: code,
        iban: iban,
        holder_name: holderName,
        bank_account_id: detail.id,
      })
    )
  };

  useEffect(() => {
    if (isUpdateSuccess) {
      toast.success("Güncelleme işlemi başarılı.");
    }
    if (isUpdateError) {
      toast.error("Güncelleme işlemi başarısız.");
    }
    return () => dispatch(reset());
  }, [isUpdateSuccess, isUpdateError]);

  const handleChangeActive = () => {
    setIsActive(!isActive);
    dispatch(
      updateBankAccount({
        bank_account_id: detail.id,
        is_active: !isActive,
      })
    )
      .then((response) => {
        toast.success("Güncelleme işlemi başarılı.");
      })
      .catch((error) => {
        updateBankAccount(isActive); // rollback
        toast.error("Kullanıcı güncellenirken bir hata oluştu.");
      });
  };

  return (
    <div className="shadow-md rounded px-8 pt-6 pb-8 mb-4">
      <h2 className="text-2xl font-semibold text-white-100 mb-4 flex items-center gap-4">
        Banka Hesabı Detay #{detail.id}
      </h2>
      <hr className="mb-4" />
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4">
        <div className="bg-gray-900 p-4 rounded shadow space-y-2">
          <h3 className="text-xl font-semibold text-white-100 mb-4 flex items-center gap-4">
            Hesap Detayları{" "}
          </h3>
          <p className="text-white-100">
            <span className="font-semibold">Durum:</span>{" "}
            <label className="relative inline-flex items-center cursor-pointer">
              <input
                type="checkbox"
                value=""
                className="sr-only peer"
                checked={isActive}
                onChange={handleChangeActive}
              />
              <div className="w-11 h-6 bg-white-100 peer-focus:ring-4 peer-focus:outline-none rounded-full peer  peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white-100 after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-primary"></div>
            </label>
          </p>
          <p className="text-white-100">
            <span className="font-semibold">Banka Adı:</span>{" "}
            <input className="rounded-md text-black px-2 focus:outline-none" type="text" value={name} onChange={(e) => setName(e.target.value)} />
          </p>

          <p className="text-white-100">
            <span className="font-semibold">Banka Kodu:</span>{" "}
            <input disabled="true" className="rounded-md text-black px-2 focus:outline-none" type="text" value={code} onChange={(e) => setCode(e.target.value)} />
          </p>
          <p className="text-white-100">
            <span className="font-semibold">IBAN:</span>{" "}
            <input className="rounded-md text-black px-2 focus:outline-none" type="text" value={iban} onChange={(e) => setIban(e.target.value)} />
          </p>
          <p className="text-white-100">
            <span className="font-semibold">Holder Name:</span>{" "}
            <input className="rounded-md text-black px-2 focus:outline-none " type="text" value={holderName} onChange={(e) => setHolderName(e.target.value)} />
          </p>
          <button onClick={handleUpdate} disabled={isUpdateLoading} className="bg-secondary text-white-100 px-4 py-1 rounded-md">
            {isUpdateLoading ? "Güncelleniyor..." : "Güncelle"}
          </button>
        </div>

      </div>



    </div>
  );
};

export default BankAccountsDetailContainer;
