import classNames from "classnames";
import PropTypes from "prop-types";

const Paragraph = ({ children, size = "medium", bold="false", className, ...props }) => {
    const baseClasses = "text-[18px] leading-[30px]";
    const largeClasses = "text-[24px] leading-[34px]";
    const mediumClasses = baseClasses;
    const boldClasses = "text-[18px] leading-[30px] font-bold";

    const classes = classNames(
        {
            [baseClasses]: !size,
            [largeClasses]: size === "large",
            [mediumClasses]: size === "medium",
            [boldClasses]: bold
        },
        className
    )

    return (
        <p className={`${classes} text-white-100`} {...props}>
            {children}
        </p>
    )
}



export default Paragraph;