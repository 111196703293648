import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import bankAccountsService from './bankAccountsService';

export const bankAccounts = createAsyncThunk('customer/bankAccounts', async (_, {rejectWithValue}) => {
	try {
		const response = await bankAccountsService.accounts();
		return response;
	} catch (error) {
		const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
		return rejectWithValue(message);
	}
});

const initialState = {
	data:[],
    isLoading: false,
    isError: false,
    isSuccess: false,
    message: null,
};

export const bankAccountsSlice = createSlice({
	name: 'customerBankAccounts',
	initialState,
	reducers: {
		reset: (state) => {
			state.isError = false;
			state.isSuccess = false;
			state.isLoading = false;
			
		},
		
	},
	extraReducers: (builder) => {
		builder
			.addCase(bankAccounts.pending, (state) => {
				state.isLoading = true;
			})
			.addCase(bankAccounts.fulfilled, (state, action) => {
				state.isLoading = false;
				state.isSuccess = true;
				state.data = action.payload;
			})
			.addCase(bankAccounts.rejected, (state, action) => {
				state.isLoading = false;
				state.isError = true;
                state.isSuccess = false;
				state.message = action.payload;
				
			})
	}
});

export const { reset } = bankAccountsSlice.actions;

export default bankAccountsSlice.reducer;