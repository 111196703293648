import {createSlice, createAsyncThunk} from '@reduxjs/toolkit';
import payoutService from './payoutService';


export const getPayouts = createAsyncThunk('reseller/payouts', async (params, {rejectWithValue}) => {
    try {
        const response = await payoutService.payouts(params);
        return response;
    } catch (error) {
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
        return rejectWithValue(message);
    }
});

export const createPayouts = createAsyncThunk('reseller/payouts/create', async (data, {rejectWithValue}) => {
    try {
        const response = await payoutService.createPayout(data);
        return response;
    } catch (error) {
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
        return rejectWithValue(message);
    }
});



const initialState = {
    payouts: [],
    isLoading: false,
    isError: false,
    isSuccess: false,
    isCreatePayoutSuccess: false,
    isCreatePayoutLoading: false,
    isCreatePayoutError: false,
    message:''
};

export const payoutsSlice = createSlice({
    name: 'resellerPayouts',
    initialState,
    reducers: {
        reset: (state) => {
            state.isError = false;
            state.isSuccess = false;
            state.isLoading = false;
            state.message = '';
            state.isCreatePayoutError = false;
            state.isCreatePayoutLoading = false;
            state.isCreatePayoutSuccess = false;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getPayouts.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(getPayouts.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isSuccess = true;
                state.payouts = action.payload;
            })
            .addCase(getPayouts.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.message = action.payload;
            });
        builder
            .addCase(createPayouts.pending, (state) => {
                state.isCreatePayoutLoading = true;
            })
            .addCase(createPayouts.fulfilled, (state, action) => {
                state.isCreatePayoutLoading = false;
                state.isCreatePayoutSuccess = true;
                state.orderSummary = action.payload;
            })
            .addCase(createPayouts.rejected, (state, action) => {
                state.isCreatePayoutLoading = false;
                state.isCreatePayoutError = true;
                state.isCreatePayoutSuccess = false;
                state.message = action.payload;
            });
  
    }
})

export const {reset} = payoutsSlice.actions;
export default payoutsSlice.reducer;