import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import OrderIndicator from "components/OrderIndicator";
import { updateProfile, reset, profile as getProfile } from "redux/reseller/profile/profileSlice";
import { BiLoaderCircle } from 'react-icons/bi';
import { toast } from "react-toastify";


const ProfileContainer = () => {
  const dispatch = useDispatch();

  const { profile, message, isEditSuccess, isEditError, isEditLoading } = useSelector((state) => state.resellerProfile);

  const [diamondPrice, setDiamondPrice] = useState("");

  useEffect(() => {
    setDiamondPrice(profile.calculator_diamond_price);
  }, [profile]);

  useEffect(() => {
    if (isEditError) {
      toast.error(message);
    }
    if (isEditSuccess) {
      toast.success(message);
      dispatch(getProfile());

    }
    return () => {
      dispatch(reset());

    };
  }, [isEditSuccess, isEditError, message, dispatch]);


  const handleChangeDiamondPrice = () => {
    if (diamondPrice === "") {
      toast.error("Lütfen bir fiyat giriniz");
      return;
    }
    dispatch(updateProfile({
      calculator_diamond_price: diamondPrice
    }))
  }

  return (
    <div className="shadow-md rounded px-8 pt-6 pb-8 mb-4">
      <h2 className="text-2xl font-semibold text-white-100 mb-4 flex items-center gap-4">
        Profilim{" "}
      </h2>
      <div className="grid md:grid-cols-2 grid-cols-1 gap-4">
        <div className="bg-gray-900 p-4 space-y-4 rounded shadow">
          <h2 className="text-xl font-semibold text-white-100">
            Kişisel Bilgilerim
          </h2>
          <p className="text-white-100">
            <span className="font-semibold">ID:</span>
            <span className="ml-2">{profile.id}</span>
          </p>
          <p className="text-white-100">
            <span className="font-semibold">Bayi Adı:</span>
            <span className="ml-2">{profile.name}</span>
          </p>
          <p className="text-white-100">
            <span className="font-semibold">Telefon Numarası:</span>
            <span className="ml-2">{profile.phone}</span>
          </p>
        </div>
        <div className="bg-gray-900 p-4 space-y-4 rounded shadow">
          <h2 className="text-xl font-semibold text-white-100">Bayilik</h2>
          <p className="text-white-100">
            <span className="font-semibold">Durum:</span>
            <span className="ml-2">
              {profile.is_active === true ? (
                <OrderIndicator color={"green"}>Aktif</OrderIndicator>
              ) : (
                <OrderIndicator color={"red"}>Pasif</OrderIndicator>
              )}
            </span>
          </p>
          <p className="text-white-100">
            <span className="font-semibold">Alt Bayi Key:</span>

            <span className="ml-2">
              {profile?.payment_key || "Tanımlanmamış"}
            </span>
            <span className="text-xs block text-primary font-medium">Dekont Açıklamasına Yazılacak Yazı</span>
          </p>
        </div>
      </div>
      <div className="grid md:grid-cols-2 grid-cols-1 gap-4 mt-4">
        <div className="bg-gray-900 p-4 space-y-4 rounded shadow">
          <h2 className="text-xl font-semibold text-white-100">
            Elmas Fiyatı{" "}
            <span className="text-white-100 text-sm">1 Adet Elmas Fiyatı</span>
          </h2>
          <div className="flex gap-4">
            <input
              className="rounded-md pl-2 focus:outline-none"
              type="text"
              value={diamondPrice}
              onChange={(e) => setDiamondPrice(e.target.value)}
            />
            <button onClick={handleChangeDiamondPrice} disabled={isEditLoading} className="bg-secondary rounded-md px-4 py-1 text-sm text-white-100 font-medium inline-block">
              {
                isEditLoading ? <BiLoaderCircle className='animate-spin w-full text-center' /> : 'Kaydet'
              }
            </button>
          </div>
          <p className="font-semibold text-white-100">
            Not:{" "}
            <span className="text-sm font-normal">
              1 adet elmas fiyatını TL cinsinden giriniz. Bu hesaplama dekont
              girerken elmas adetinin daha kolay hesaplanmasını sağlar
            </span>
          </p>
        </div>
        <div className="bg-gray-900 p-4 space-y-4 rounded shadow">
          <h2 className="text-xl font-semibold text-white-100">Finans</h2>
          <p className="text-white-100">
            <span className="font-semibold">Hesap Bakiyesi:</span>
            <span className="ml-2">{profile.balance_try}₺</span>
          </p>
          <p className="text-white-100">
            <span className="font-semibold">Borç (eksi) limiti:</span>
            <span className="ml-2">{profile.allowance_try}₺</span>
          </p>
          <p className="text-white-100">
            <span className="font-semibold">Komisyon Oranı:</span>
            <span className="ml-2">{profile.commission_rate}%</span>
          </p>
        </div>
      </div>
    </div>
  );
};

export default ProfileContainer;
