import instance from "api/instance";

const accounts = async () => {
	const response = await instance.get("/customer/bank-accounts");
	if (response.status === 200) {
		return response.data;
	}
	
	return Promise.reject(response.data);
};



const bankAccountsService = {
	accounts,
};

export default bankAccountsService;