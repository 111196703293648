import React from 'react'
import {clsx} from 'clsx'

const OrderIndicator = ({color, children}) => {
  return (
    <span className={clsx(color === 'red' ? 'bg-red-50 text-red-700 ring-red-600/10' : color === 'green' ? 'bg-green-50 text-green-700 ring-green-600/20' : color === 'yellow' ? 'bg-yellow-50 text-yellow-800 ring-yellow-600/20' : null, 'inline-flex items-center rounded-md px-2 py-1 text-xs font-medium ring-1 ring-inset' )}>
        {children}
    </span>
  )
}

export default OrderIndicator