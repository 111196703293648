import instance from "api/instance";

const accounts = async () => {
  const response = await instance.get("/admin/bank-accounts");
  if (response.status === 200) {
    return response.data;
  }

  return Promise.reject(response.data);
};

const create = async (data) => {
  const response = await instance.post("/admin/bank-accounts", data);
  if (response.status === 201) {
    return response.data;
  }

  return Promise.reject(response.data);
};

const update = async (data) => {
  const response = await instance.patch("/admin/bank-accounts", data);
  if (response.status === 200) {
    return response.data;
  }

  return Promise.reject(response.data);
};



const bankAccountsService = {
  accounts,
  create,
  update
};

export default bankAccountsService;
