import {Link} from 'react-router-dom'
import logo from 'images/logo.svg'
import logoMobile from 'images/logoMobile.svg'
const Logo = ({ isMobile, href="/" }) => {
    return (
        isMobile ? (
            <Link to={href}>
                <img
                    src={logoMobile}
                    alt="Logo"
                    width={32}
                    height={61}
                />
            </Link>
        ) : (
            <Link to={href}>
                <img
                    src={logo}
                    alt="Logo"
                    width={205}
                    height={61}
                />
            </Link>
        )
    )
}

export default Logo