import axios from 'axios';
import jwt_decode from "jwt-decode";


const instance = axios.create({
  baseURL: 'https://skypay.com.tr/api',
  headers: { 'Content-Type': 'application/json' },
});


const token = localStorage.getItem('token');
const role = token ? jwt_decode(token).role : null;
localStorage.setItem('role', role);

instance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('token');
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    localStorage.removeItem('token');
    return Promise.reject(error);
  }
);


/*
instance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response.status === 401) {
        
        window.location.href = '/';
    }
    return Promise.reject(error);
  }
);
*/


 instance.interceptors.response.use(
   (response) => {
  return response;
  },
   async (error) => {
     const originalRequest = error.config;
     if (error.response.status === 401 && !originalRequest._retry) {
       originalRequest._retry = true;
       const refreshToken = localStorage.getItem('refresh_token');
       const response = await axios.post(`https://skypay.com.tr/api/${localStorage.getItem("role")}/auth/refresh-token`, {},{
          headers: {
            'Authorization': `Bearer ${refreshToken}`,
          },
       });
       if (response.status === 200) {
          localStorage.setItem('token', response.data.access_token);
          localStorage.setItem('refresh_token', response.data.refresh_token);
          originalRequest.headers['Authorization'] = `Bearer ${response.data.access_token}`;
          window.location.reload();
         return instance(originalRequest);
       }
     }
     return Promise.reject(error);
   }
 );



  instance.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (error.response.status === 403) {
        localStorage.removeItem('token');
        localStorage.removeItem('refresh_token');
        window.location.href = '/';
      }
      return Promise.reject(error);
    }
  );










export default instance;
