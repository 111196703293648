import { useState, useEffect } from "react";
import Heading from "components/Typography/Header";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";

const Iletisim = () => {
  const [formValues, setFormValues] = useState({
    fullName: "",
    email: "",
    phone: "",
    subject: "",
    message: "",
  });

  const phoneRegex = /^(\+90|0)?[0-9]{10}$/;
  const validationSchema = Yup.object().shape({
    fullName: Yup.string().required("Ad Soyad gereklidir."),
    email: Yup.string()
      .email("Geçerli bir email adresi giriniz.")
      .required("Email gereklidir."),
    phone: Yup.string()
      .matches(phoneRegex, "Geçerli bir telefon numarası giriniz.")
      .required("Telefon numarası gereklidir."),
    subject: Yup.string().required("Konu gereklidir."),
    message: Yup.string().required("Mesaj gereklidir."),
  });

  const handleSubmit = (values) => {
    console.log(values);
  };

  return (
    <div className="green-container py-4">
      <Heading className="text-white-100 text-center" level={3}>
        İletişim
      </Heading>

      <section className="">
        <div className="mx-auto max-w-screen-xl px-4 py-16 sm:px-6 lg:px-8">
          <div className="grid grid-cols-1 gap-x-16 gap-y-8 lg:grid-cols-5">
            <div className="lg:col-span-2 lg:py-12">
              <p className="max-w-xl text-lg text-white-100">
                Size her türlü soru, görüş veya öneriniz için buradayız. İhtiyaç
                duyduğunuz her an bize aşağıdaki iletişim bilgilerinden
                ulaşabilirsiniz. Müşteri memnuniyeti bizim için ön planda
                olduğundan, sizden gelen her geri bildirim değerlidir. Size en
                kısa sürede yanıt vermek için sabırsızlıkla bekliyoruz.
              </p>

              <div className="mt-8">
                <p href="" className="text-2xl font-bold text-primary">
                  444 0 371
                </p>

                <address className="mt-2 not-italic text-white-100">
                  Orta Mah. Ordu Sk. A Blok No:23A İç Kapı No:21 Kartal /
                  İstanbul
                </address>
              </div>
            </div>

            <div className="rounded-lg p-8  lg:col-span-3 lg:p-12">
              <Formik
                initialValues={formValues}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
              >
                {({ errors, touched }) => (
                  <Form className="space-y-4">
                    <div>
                      <label className="sr-only" htmlFor="name">
                        Ad Soyad
                      </label>
                      <Field
                        autoComplete="off"
                        className="w-full rounded-lg border-gray-200 p-3 text-sm"
                        placeholder="Ad Soyad"
                        name="fullName"
                        type="text"
                        id="name"
                      />
                      {errors.fullName && touched.fullName ? (
                        <ErrorMessage
                          component="span"
                          className="text-red-500 text-xs my-2"
                          name="fullName"
                        />
                      ) : null}
                    </div>

                    <div className="grid grid-cols-1 gap-4 sm:grid-cols-2">
                      <div>
                        <label className="sr-only" htmlFor="email">
                          Email
                        </label>
                        <Field
                          autoComplete="off"
                          className="w-full rounded-lg border-gray-200 p-3 text-sm"
                          placeholder="Email"
                          name="email"
                          type="email"
                          id="email"
                        />
                        {errors.email && touched.email ? (
                          <ErrorMessage
                            component="span"
                            className="text-red-500 text-xs my-2"
                            name="email"
                          />
                        ) : null}
                      </div>

                      <div>
                        <label className="sr-only" htmlFor="phone">
                          Telefon Numarası
                        </label>
                        <Field
                          autoComplete="off"
                          name="phone"
                          className="w-full rounded-lg border-gray-200 p-3 text-sm"
                          placeholder="Telefon Numarası"
                          type="tel"
                          id="phone"
                        />
                        {errors.phone && touched.phone ? (
                          <ErrorMessage
                            component="span"
                            className="text-red-500 text-xs my-2"
                            name="phone"
                          />
                        ) : null}
                      </div>
                    </div>
                    <div>
                      <label className="sr-only" htmlFor="subject">
                        Konu
                      </label>
                      <Field
                        autoComplete="off"
                        name="subject"
                        className="w-full rounded-lg border-gray-200 p-3 text-sm"
                        placeholder="Konu"
                        type="text"
                        id="subject"
                      />
                      {errors.subject && touched.subject ? (
                        <ErrorMessage
                          component="span"
                          className="text-red-500 text-xs my-2"
                          name="subject"
                        />
                      ) : null}
                    </div>
                    <div>
                      <label className="sr-only" htmlFor="message">
                        Mesajınız
                      </label>

                      <Field
                        autoComplete="off"
                        name="message"
                        as="textarea"
                        className="w-full rounded-lg border-gray-200 p-3 text-sm"
                        placeholder="Mesajınız"
                        rows="8"
                        id="message"
                      />
                      {errors.message && touched.message ? (
                        <ErrorMessage
                          component="span"
                          className="text-red-500 text-xs my-2"
                          name="message"
                        />
                      ) : null}
                    </div>

                    <div className="mt-4">
                      <button
                        type="submit"
                        className="inline-block w-full rounded-lg bg-primary px-5 py-3 font-medium text-white sm:w-auto"
                      >
                        Gönder
                      </button>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};
export default Iletisim;
